<template>
  <div class="dash-top">
    <router-link class="" to="/"><img :src="imageUrl()" /></router-link>
  </div>
  <div v-if="projects" class="container">
    <div class="header--progress">
      <Header title="Fremgang" />
    </div>
    <div v-for="project in projects" :key="project.id">

      <div @click="toggleExpand(project.id)" class="card--progress">
        <h4>{{ project.project_name }}</h4>
        <div class=" prog-bar-container">
          <span v-if="project.progress">
            {{ calculatePercentage(project.progress.totalProgress) }}
          </span>
          <div v-if="project.progress" :style="'width:' + calculatePercentage(project.progress.totalProgress, 'css')"
            class="prog-bar-bar">
          </div>
        </div>
      </div>

      <Transition name="fade">
        <div v-if="show.includes(project.id)" class="card--progress-details table-overflowx">
          <table>
            <thead>
              <tr>
                <th class="th-md">Task</th>
                <th class="progress-text-center">Fremgang</th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="task in usedTasks(project.progress)">
                <td>{{ task.label }}</td>
                <td v-if="project.progress">
                  <div class="prog-bar-container">
                    <span>{{ calculatePercentage(project.progress[task.dataName]) }}</span>
                    <div :style="'width:' + calculatePercentage(project.progress[task.dataName], 'css')"
                      class="prog-bar-bar" v-if="project.progress">

                    </div>
                  </div>
                </td>
              </tr>
            </tbody>

          </table>

        </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import ProjectService from '@/services/Project';
export default {
  created() {
    this.$loader(true)
    this.projectService = new ProjectService();
    this.getProjects()
  },
  data() {
    return {
      fetchData: null, // Initialize with null or an initial value

      projects: null,
      show: [],
      tasks: [
        { dataName: 'linework', label: 'Linjearbeid' },
        { dataName: 'installationwork', label: 'Montørarbeid' },
        { dataName: 'documentation', label: 'Dokumentasjon' },
        // { dataName: 'checklist', label: 'Sjekkliste' },

      ]
    }
  },

  mounted() {
    // this.fetchDataFromServer();
    let tid = 30 //minutter
    setInterval(this.getProjects, tid * 60 * 1000);
    // Fetch data every minute (adjust the interval as needed) 
  },


  methods: {

    fetchDataFromServer() {
      // Fetch data from the server or any source
      // Update the fetchData property
      this.fetchData = fetchDataFromServer();
    },
    usedTasks(progress) {

      let tasks = JSON.parse(JSON.stringify(this.tasks))
      if (progress.checklist) {
        tasks.push({ dataName: 'checklist', label: 'Sjekkliste' })
      }
      return tasks
    },
    toggleAll() {
      if (this.show.length == this.projects.length) {
        this.show = []
      } else {
        for (let i = 0; i < this.projects.length; i++) {
          this.show.push(this.projects[i].id)
        }

      }
    },
    toggleExpand(projectId) {
      if (this.show.includes(projectId)) {
        let i = this.show.findIndex(x => x == projectId)
        this.show.splice(i, 1)
      } else {
        this.show.push(projectId)
      }
    },
    calculatePercentage(progress, type) {
      if (!progress) {
        return ''
      }
      const sum = progress.yes + progress.no + progress.na
      const finished = progress.yes + progress.na
      if (isNaN(Math.floor(finished / sum * 100))) {
        if (type == 'css') {
          return '0%'
        }
        return "Ingen oppføringer"
      }
      return Math.floor(finished / sum * 100) + "%"
    },
    projectProgress(id) {
      this.projectService
        .getProjectProgress(id)
        .then((res) => {
          const projectIndex = this.projects.findIndex(project => project.id == id)
          this.projects[projectIndex].progress = res
        })
    },
    getProjects() {
      this.projectService
        .getProjects('')
        .then((res) => {
          this.projects = res
        })
        .then((res) => {
          for (let i = 0; i < this.projects.length; i++) {
            this.projectProgress(this.projects[i].id)
          }
          this.$loader(false)
        })
    },
    imageUrl() {
      let images = require.context('../assets/', false, /\.png$/)
      let darkmode = window.matchMedia('(prefers-color-scheme: dark)').matches
      if (darkmode) {
        return images('./Darkmode500.png')
      } else {
        return images('./Lightmode500.png')
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 170rem;
  padding-top: 10rem;
}

.dash-top {
  position: fixed;
  width: 100%;
  background-color: var(--main-color);
  border-radius: 0 0 6px 6px;
  z-index: 777;
}

img {
  max-height: 7rem;
  padding: 1rem 3rem;
}

.card--progress {
  grid-template-columns: 1fr 2fr;

}
</style>
