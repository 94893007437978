<template>
  <div class="container">
    <Header title="Nytt Prosjekt" />

    <div class="container--proj m-top">
      <div class="col-proj">
        <div class="card card--proj">
          <h3>Prosjektdetaljer</h3>
          <div class="input">
            <label for="projectname">Prosjektnavn<span class="req">*</span></label>
            <input required v-model="newProject.project_name" type="text" id="projectname" />
          </div>
          <div class="input">
            <label for="project-area">Område</label>
            <input v-model="newProject.area" type="text" id="project-area" />
          </div>
          <div v-if="newProject && users.length > 0" class="input">
            <label for="projectleader">Prosjektleder<span class="req">*</span></label>
            <DropdownMultiple id="projectleader" @input="newProject.project_leader = $event" v-model="newProject.project_leader"
              :data="newProject.project_leader" :options="users" label="full_name" />
          </div>
          <div class="input">
            <label for="employer">Oppdragsgiver</label>
            <input required v-model="newProject.employer" type="text" id="employer" />
          </div>
          <p class="log">Felter med<span class="req">*</span> må fylles ut</p>
          <div class="password-error" v-if="invalidForm.length > 0">
            <p v-for="error in invalidForm">
              {{ error }}
            </p>
          </div>
        </div>

        <div class="card card--proj">
          <h4>Datoer</h4>
          <div class="input">
            <label for="duedate"> Tjeneste levering</label>
            <input v-model="newProject.service_delivery" type="date" id="duedate" />
          </div>
          <div class="input">
            <label for="duedate">Tekninsk ferdig</label>
            <input v-model="newProject.technically_finished" type="date" id="duedate" />
          </div>
          <div class="input">
            <label for="duedate">Ferdig dato</label>
            <input v-model="newProject.deadline" type="date" id="duedate" />
          </div>
        </div>

        <div class="card card--proj">
          <h3>Templates</h3>
          <div v-for="template in templates" class="input--checkbox">
            <input v-model="newProject.templates" :value="template.id" type="checkbox" :id="'template' + template.id" />
            <label :for="'template' + template.id">{{ template.name }}</label>
          </div>
        </div>

        <div class="card card--proj">
          <h3>Sjekkliste</h3>
          <div v-for="checklist in checklists" class="input--checkbox">
            <input v-model="newProject.checklists" :value="checklist.id" type="checkbox"
              :id="'checklist' + checklist.id" />
            <label :for="'checklist' + checklist.id">{{ checklist.name }}</label>
          </div>
        </div>

      </div>

      <div class="col-proj col--proj-usr">
        <div class="card card--proj card--proj-usr">
          <h3>Brukertilgang</h3>
          <div class="input--checkbox">
            <input v-model="addAll" @change="addAllUsers()" id="choose-all" type="checkbox">
            <label for="">Velg alle brukere</label>
          </div>
          <div class="proj-usr-header">
            <p>T</p>
            <p>B</p>
            <p>Bruker</p>
          </div>
          <div class="proj-usr">
            <div v-for="user in sortedUsers()" class="proj-usr-input">
              <input :id="'usert' + user.id" :key="user.id" :value="Number(user.id)" type="checkbox"
                v-model="newProject.team_leaders" />
              <input :id="'user' + user.id" :key="user.id" :value="Number(user.id)" type="checkbox"
                v-model="newProject.assigned_users" />
              <div>{{ user.full_name }}</div>
            </div>
          </div>
          <p class="log">Velg brukere med <strong> 'B'</strong>, og teamledere med <strong> 'T'</strong></p>
        </div>
      </div>
    </div>

    <div class="m-top">
      <button @click="createProject()" class="btn btn--full btn--icon">
        <IconCheck />
        Opprett prosjekt
      </button>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/User.js';
import ProjectService from '@/services/Project.js';

export default {
  created() {
    this.userService = new UserService();
    this.projectService = new ProjectService();
    this.fetchData();

  },
  data() {
    return {
      invalidForm: [],
      addAll: false,
      projectService: null,
      userService: null,
      newProject: {
        project_name: '',
        project_leader: '',
        employer: '',
        assigned_users: [],
        team_leaders: [],
        templates: [],
        area: '',
        deadline: '',
        technically_finished: '',
        service_delivery: '',
        checklists: [],
        checklistsObjects: '',
      },
      published: false,
      users: [],
    };
  },
  beforeRouteLeave(to, from, next) {
    const length = JSON.stringify(this.newProject).length
    let changes = false
    if (length > 217 && this.published == false) {
      changes = true
    }

    if (changes) {
      const answer = window.confirm(
        "Vil du virkelig forlate siden? Du har ulagrede endringer!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  computed: {
    checklists() {
      return this.$store.getters.checklists;
    },
    checklistsObjects() {
      return this.$store.getters.checklists;
    },
    templates() {
      return this.$store.getters.templates;
    },

  },
  methods: {
    sortedUsers() {
      let direction = 1
      return this.users.sort((a, b) => (a.full_name > b.full_name) ? direction : direction * -1)
    },
    addAllUsers(e) {
      if (this.addAll == true) {
        this.newProject.assigned_users = []
        for (let i = 0; i < this.users.length; i++) {
          this.newProject.assigned_users.push(this.users[i].id)
        }
      }
      if (this.addAll == false) {
        this.newProject.assigned_users = []
      }
    },
    createProject() {
      this.invalidForm = []
      if (!this.$validateText(this.newProject.project_name)) {
        this.$toast('error', 'Fyllt ut Prosjektnavn')
        this.invalidForm.push('Fyll ut Prosjektnavn')
      }
      if (!this.$validateArray(this.newProject.project_leader)) {
        this.$toast('error', 'Fyllt ut Prosjektleder')
        this.invalidForm.push('Fyll ut Prosjektleder')
      }
      if (this.invalidForm.length > 0) {
        return
      }
      this.published = true
      this.$loader(true)
      this.projectService.createProject(this.newProject)
        .then((res) => {
          this.$toast('success', 'Opprettet prosjekt')
          this.$loader(false)
          this.$router.push("/project/" + res)
        })
    },
    async fetchData() {
      this.userService.getAllUsers().then((res) => (this.users = res));
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 140rem;
}
</style>
