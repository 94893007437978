<template>
  <div v-if="(entries && project)" class="container">
    <Header :title="headerTitle" :button="headerButton" />


    <div class="entry-table-top">
      <div class="filter-tabs">
        <button :class="{ 'tabs-active': currentSort == 0 }" class=" btn--tabs"
          @click="chooseCurrentSort(0)">Standard</button>
        <button :class="{ 'tabs-active': currentSort == 1 }" class=" btn--tabs" @click="chooseCurrentSort(1)">Filter
          1</button>
        <button :class="{ 'tabs-active': currentSort == 2 }" class=" btn--tabs" @click="chooseCurrentSort(2)">Filter
          2</button>
      </div>

      <div class="filter-btns">
        <button v-if="!showCustom" @click="showCustom = !showCustom" class="btn btn--text-btn btn--icon">
          <IconFilter />Filter
        </button>
        <button v-if="showCustom" @click="showCustom = !showCustom" class="btn btn--red btn--icon">
          <IconClose />
        </button>
      </div>
    </div>



    <Transition name="fade">
      <div v-if="showCustom">

        <div class="filter-top-txt">
          <h4>
            {{ whichSort() }}
          </h4>
          <p>Velg hvilke felter du vil se i tabellen</p>
        </div>


        <div class="filter-list">
          <div class="filter-check" v-for="field in allColumns">
            <input v-if="isDisabled()" :disabled="isDisabled(field.is_hidden)" type="checkbox" true-value="0"
              v-model="field.is_hidden" name="" id="">
            <input v-else="isDisabled()" :disabled="isDisabled(field.is_hidden)" type="checkbox" :value="field.id"
              false-value="0" v-model="currentSortArray" name="" :id="field.id">
            <label :for="field.id"> {{ field.header }}</label>
          </div>
        </div>


        <p class="log">
          Klikk først på "Filter 1" eller "Filter 2", og så velger du hvilke felter du vil tabellen skal vise.
          Standard
          viser alle.
        </p>

        <button @click="saveCustomFields()" class="btn btn--full btn--save-filter btn--icon">
          <IconSave />Lagre
        </button>
      </div>
    </Transition>

    <div class="table-overflowx" v-if="(entries.length > 0)">
      <table>
        <thead>
          <tr>
            <th @click="changeSort(column.field)" v-for="column in columns" :key="column">
              {{ column.header }}
              <span v-html="arrow(column.field)"></span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr @click="clickR(entry)" v-for="entry in  customSort()" :key="entry.id">
            <td :class="setupStyle(column, entry, index)" v-for="(column, index) in columns" :key="column.id">
              <div class="priority">

                <div v-if="index == 0 && entry.ticket && entry.ticket != 4">
                  <IconExMark class="icon-ticket" />
                </div>

                <div v-if="column.type == 'material'">
                  <p v-if="entry[column.field]">
                    {{
                      this.$store.getters.materialName(entry[column.field])
                        .material_friendly_name
                    }}
                  </p>
                </div>

                <div v-else-if="column.type == 'check'">
                  {{ formatCheck(entry[column.field]) }}
                </div>

                <div class="prog-bar-container" v-else-if="column.type == 'progress'">
                  <span>{{ calculateProgress(entry) }}</span>
                  <div class="prog-bar-bar" :style="'width:' + calculateProgress(entry)">
                  </div>
                </div>

                <div v-else-if="column.type == 'status'">

                  <div v-if="parseInt(entry[column.field]) > -1">
                    {{
                      this.$store.getters.customerStatus.find((template) => template.id == entry[column.field]).name
                    }}
                  </div>
                </div>

                <div v-else>
                  {{ entry[column.field] }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else>
      <h3>Ingen oppføringer!</h3>
    </div>
  </div>
</template>

<script>
import EntryService from '@/services/Entry';
import ProjectService from '@/services/Project';
export default {
  components: {
  },
  created() {
    this.$loader(true)
    this.entryService = new EntryService();
    this.projectService = new ProjectService();
    this.fetchData();
    this.getColumns();
  },
  data() {
    return {
      allColumns: null,
      showCustom: false,
      type: 0,
      type2: 1,
      sort: null,
      currentSort: null,
      currentSortArray: [],
      sortDir: true,
      entries: null,
      project: null,
      entryService: null,
      userService: null,
      projectService: null,
      columns: []
    };
  },
  computed: {
    columnsNewOld() {
      return this.$store.getters.templateColumns(
        Number(this.$route.params.template)
      );
    },

    columnsOld() {
      return this.$store.getters.findTemplateColumns(
        Number(this.$route.params.template)
      ).columns;
    },
    headerTitle() {
      return `${this.project.project_name} / ${this.templateName}`;
    },
    headerButton() {
      return {
        label: 'Databehandling ',
        icon: 'IconDocument',
        link: `/project/${this.project.id}/template/${this.$route.params.template}/datamanagement`,
      };
    },
    templateName() {
      return this.$store.getters.templates.find(
        (template) => template.id == this.$route.params.template
      ).name;
    },
  },
  methods: {
    isDisabled(hidden) {
      if (this.$store.getters.currentSort(this.$route.params.template) == 0) {
        return true
      }
    },
    whichSort() {
      let sort = this.$store.getters.currentSort(this.$route.params.template)
      switch (sort) {
        case 0:
          return 'Standard'
        case 1:
          return 'Filter 1'
        case 2:
          return 'Filter 2'
        default:
          break;
      }
    },

    saveCustomFields() {
      let currentSort = this.$store.getters.currentSort(this.$route.params.template)
      let template = this.$route.params.template
      //Hvis current sort er 0, avbryt (default sort, ikke nødvendig å lagre)
      if (currentSort == 0) { return }
      //Hent objekt først (store?)

      let sort = this.$store.getters.customSort

      let obj = {
        template: template,
        sort: currentSort,
        columns: this.currentSortArray
      }

      let i = -1

      for (let j = 0; j < sort.length; j++) {
        if (sort[j].template == template && sort[j].sort == currentSort) {
          i = j
        }
      }

      if (i > -1) {
        sort[i] = obj
      } else {
        sort.push(obj)
      }

      this.$store.dispatch('setCustomSort', sort)
      this.getColumns()
      this.showCustom = false
    },
    chooseCurrentSort(sortId) {
      this.currentSort = sortId
      // return
      //0 = Default
      //1 = Custom 1
      //2 = Custom 2 
      let obj = { template: Number(this.$route.params.template), sort: sortId } //Lag objekt for Localstorage med hvilken sortering du ønsker
      let currentSort = this.$store.getters.currentSort(this.$route.params.template) //??
      let currentSortArr = this.$store.getters.currentSortArray //Array med nåværende sorteringer 
      // return
      if (!currentSortArr) { currentSortArr = [] } //Hvis det ikke finnes noen array enda, lag en.
      let i = currentSortArr.findIndex(x => x.template == this.$route.params.template) //Finn template i eksisterende valg
      if (i > -1) {
        currentSortArr[i].sort = sortId
        //Localstorage har allerede denne templaten, så alt i orden
      } else {
        currentSortArr.push(obj) //Denne templaten har ikke lagring, så putt inn i localstorage
      }
      this.$store.dispatch('setCurrentSort', currentSortArr) //Oppdater nåværende sortering
      // this.getCustomSortedColumns(sortId) //Kjører funksjon for å oppdatere kolonner...
      // this.currentSort=sort //Unødvendig?
      this.getColumns()
      return


    },
    chooseColumns(type) {
      if (type == 0) {
        this.getColumns()
      }
      if (type == 1) {
        this.columns = []
      }
    },

    getColumns() {
      this.allColumns = this.$store.getters.templateColumns(this.$route.params.template);
      let sort = this.$store.getters.currentSort(this.$route.params.template)
      this.currentSort = sort
      // return
      // sort = 0
      // sort = 2
      if (sort == 0) {
        this.$store.getters.templateColumns(this.$route.params.template);
        this.columns = this.$store.getters.templateColumnsCustom(this.$route.params.template, 0)
      } else {
        //Jeg har SORT ID, hent SORT ARRAY....
        // this.$store.getters.templateColumns(this.$route.params.template);
        sort = this.$store.getters.columnsByTemplateAndSort(this.$route.params.template, sort)
        this.currentSortArray = sort
        this.columns = this.$store.getters.templateColumnsCustom(this.$route.params.template, sort)
        return
        // this.customColumnsArr=sort
        return
      }

      // this.columns = this.$store.getters.templateColumns(
      //   Number(this.$route.params.template)
      // );
    },
    sorted(entries) {
      return entries
      for (let i = 0; i < entries.length; i++) {
        if (entries[i].completed == 1) {
          entries.push(entries.splice(i, 1)[0]);
        }
        if (entries[i].utgaar == 1) {
          entries.push(entries.splice(i, 1)[0]);
        }
        if (entries[i].prioritert == 1) {
          entries.unshift(entries.splice(i, 1)[0]);
        }
      }

      return entries;
    },
    arrow(field) {
      if (field == this.sort && this.sortDir == true) {
        return '&darr;'
      }
      if (field == this.sort && this.sortDir == false) {
        return '&uarr;'
      }
    },
    changeSort(field) {
      // return
      this.sort = field
      if (field == this.sort) {
        this.sortDir = !this.sortDir
      }
      this.customSort()
    },
    quickSort() {
      let entries = JSON.parse(JSON.stringify(this.entries))
      let pri = entries.filter((x) => x.prioritert == 1)
      let utgaar = entries.filter((x) => x.utgaar == 1)
      let completed = entries.filter((x) => x.completed == 1)
      entries = entries.filter((x) => x.prioritert != 1 && x.utgaar != 1 && x.completed != 1)
      return [...pri, ...entries, ...completed, ...utgaar]
    },
    customSort() {
      if (!this.sort) { this.quickSort() }
      let entries = JSON.parse(JSON.stringify(this.entries))
      let direction = 1
      if (this.sortDir) { direction = -1 }
      for (let i = 0; i < entries.length; i++) {
        if (!entries[i][this.sort]) {
          // entries[i][this.sort] = ''
        }
      }
      let pri = entries.filter((x) => x.prioritert == 1)
      let utgaar = entries.filter((x) => x.utgaar == 1)
      let completed = entries.filter((x) => x.completed == 1)
      entries = entries.filter((x) => x.prioritert != 1 && x.utgaar != 1 && x.completed != 1)
      pri = pri.sort((a, b) => (a[this.sort] > b[this.sort]) ? direction : direction * -1)
      utgaar = utgaar.sort((a, b) => (a[this.sort] > b[this.sort]) ? direction : direction * -1)
      completed = completed.sort((a, b) => (a[this.sort] > b[this.sort]) ? direction : direction * -1)
      entries = entries.sort((a, b) => (a[this.sort] > b[this.sort]) ? direction : direction * -1)
      let sum = [...pri, ...entries, ...completed, ...utgaar]

      return sum
    },
    setupStyle(column, entry, index) {
      if (entry.completed == 1) {
        return 'completed';
      }
      if (entry.utgaar == 1) {
        return 'utgaar';
      }
      if (entry.prioritert == 1) {
        return 'priorityColour';
      }

      if (column.type == 'check') {
        switch (entry[column.field]) {
          case 1:
            return 'ok'; //Klasse hvis check er "Ja"
          case 0:
            return ''; //Klasse hvis check er "Nei"
          case 2:
            return 'ok'; //Klasse hvis check er "Utgår"
            break;
        }
      }
    },

    calculateProgress(entry, column) {
      //Finn template ID,
      const template = this.$route.params.template;

      let fields = this.$store.getters.templateFields(Number(template));
      let checkbox = fields.filter((x) => x.component === 'CheckboxField');
      let simpleCheckbox = fields.filter((x) => x.component === 'SimpleCheckboxField');
      checkbox = [...checkbox, ...simpleCheckbox]
      let ok = 0;
      if (checkbox.length == 0) {
        return
      }

      let nei = 0;
      for (let i = 0; i < checkbox.length; i++) {
        // if (entry[checkbox[i].dataName] == 0 ) {
        if (entry[checkbox[i].dataName] == 0 || entry[checkbox[i].dataName] == null) {
          nei++;
        } else {
          ok++;
        }
      }
      let string = `${ok} / ${nei + ok} Punkter utført`;
      if (ok == nei + ok) {
        // return entry

        // return this.entries.find(x=>x.id==entry.id)

        this.entries.find((x) => x.id == entry.id).completed = 1;
        return Math.floor((100 / (nei + ok)) * ok) + '%';
        return 'Skap fullført';
      }
      string = Math.floor((100 / (nei + ok)) * ok) + '%';
      return string;
    },
    statusClass(data) {
      switch (data) {
        case 1:
          return 'ok';
        case 0:
          return 'red';
        case 2:
          return 'yellow';
      }
    },
    formatCheck(data) {
      if (data === 1) {
        return 'Ja';
      }
      if (data === 0) {
        return 'Nei';
      }
      if (data === 2) {
        return 'Utgår';
      }
      return data;
    },
    clickR(data) {
      const template = this.$route.params.template;
      const entryId = data.id;
      this.$router.push(`/template/${template}/entry/${entryId}`);
    },
    clickRow(data) {
      const template = this.$route.params.template;
      const entryId = data.data.id;
      this.$router.push(`/template/${template}/entry/${entryId}`);
    },
    goBack() {
      const project = this.$route.params.project;
      this.$router.push(`/project/${project}`);
    },
    async fetchData() {
      const project = this.$route.params.project;
      const template = this.$route.params.template;
      await this.entryService
        .getEntriesForTable(project, template)
        .then((data) => (this.entries = data));
      await this.projectService
        .getProject(project)
        .then((data) => {
          this.project = data[0]
          this.$loader(false)
        });
    },
  },
};
</script>

<style scoped>
html,
body {
  overflow-y: hidden;
}

.container {
  max-width: 180rem;
  height: 100%;
  padding-bottom: 0;
  margin-bottom: 0;
}

.entry-table-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap-reverse;
  margin: 2rem 0 1rem 0;
}

.filter-btns button {
  padding: .7rem 1rem;
}

.icon-close {
  height: 1.6rem;
}

.filter-top-txt {
  margin-bottom: 2rem;
}

.filter-list {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  margin-bottom: 1rem;
}

.filter-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .6rem;
}

.btn--save-filter {
  margin: 2rem 0 2rem 0;
}

.priority {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.utgaar {
  background-color: rgba(64, 59, 59, 0.326);
  color: var(--text-disabled-color);
}

.ok {
  background: var(--green-progress);
}

.yellow {
  background-color: hsla(60, 100%, 50%, 30%);
}

.priorityColour {
  background: var(--priority-color);
}

.completed {
  background: var(--green-progress);
}

.icon-ticket {
  height: 2.4rem;
  margin-top: .4rem;
  color: var(--red-color);
}

.prog-bar-container {
  min-width: 10rem;
  height: 3rem;
}

.prog-bar-container span {
  top: 20%;
}


.table-overflowx {
  height: 80vh;
}

table thead {
  position: sticky;
  inset-block-start: 0;
  z-index: 2;
  background: var(--bg-light-color);
  cursor: pointer;
}

table tr th:first-child,
table tbody tr td:first-child {
  position: sticky;
  inset-inline-start: 0;
  z-index: 1;
  /* min-width: 15rem;
  max-width: 20rem; */
  background: var(--bg-light-color);
  /* white-space: nowrap; */
}

table tr th:first-child:hover,
table tbody tr td:first-child:hover {
  background: var(--table-border-color);
}

th {
  white-space: nowrap;
}


@media only screen and (max-width:800px) {
  .table-overflowx {
    height: 65vh;
  }
}
</style>
