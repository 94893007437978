<template>
  <div class="rad-btn-layout">
    <div class="rad-btn-label">{{ label }}</div>
    <div class="rad-group">
      <div class="rad-btn-container">
        <label :for="label+1" :class="classColor(data, '1')" class="rad-btn rad-btn-r">
          <input @click="$emit('update:modelValue', 1)" :value="modelValue" type="radio" value="1" name="" :id=label+1 />
          <IconCheck />
          <span>Ja</span>
        </label>
      </div>

      <div class="rad-btn-container">
        <label :for="label+0" :class="classColor(data, '0')" class="rad-btn rad-btn-l">
          <input @click="$emit('update:modelValue', 0)" :value="modelValue" type="radio" value="0" name="" :id=label+0 />
          <IconClose />
          <span>Nei</span>
        </label>
      </div>
    </div>
    <div v-if="log" class="log">{{ log.name }} {{ log.date }}</div>
  </div>
</template>

<script>
export default {
  methods: {
    classColor(val, data) {
      if (Number(val) != Number(data)) {
        return;
      }
      switch (Number(val)) {
        case 1:
          return 'rad-green';
        case 0:
          return 'rad-red';
        case 2:
          return 'rad-yellow';
        default:
          break;
      }
    },
  },
  emits: ['update:modelValue'],
  props: ['modelValue', 'value', 'label', 'data', 'log', 'type', 'edit'],
};
</script>

<style scoped>
.svg-icon {
  /* height: 2.4rem; */
  stroke-width: 2;
}
</style>
