// plugins/i18n.js
import { EventBus } from '@/assets/event-bus.js'
export default {
    install: (app, options) => {
        // inject a globally available $translate() method
        app.config.globalProperties.$validateText = (state) => {
            if(state){
                if(state.length>0){
                    return true
                }
                if(state.length==0){
                    return false
                }
            } else {
                return false
            }
        }
        app.config.globalProperties.$validateNumber = (state) => {
            if(state){
                if(isNaN(state)){
                    return false
                } else {
                    return true
                }
                if(state.length>0){
                    return true
                }
                if(state.length==0){
                    return false
                }
            } else {
                return false
            }
        }
        app.config.globalProperties.$validateArray = (state) => {
            if(state){
                if(Array.isArray(state)){
      
                    if(state.length>0){
                        return true
                    } else{
                        return false
                    } 
                    
                } else {
                    return false
                }
            } else {
                return false
            }
        }

        app.config.globalProperties.$validateEmail = (state) => {
            if(state){
                if(state.length==0){return false}
                let a = state.toLowerCase().match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
                if(a&&a.length){
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
    }
        app.config.globalProperties.$validatePassword = (state) => {
            
            if(state){
                state=state.toString()
                if(state.length==0){return false}
                let a = state.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/);
                if(validatePassword){
                    return true
                } else {
                    return false
                }
                if(a&&a.length){
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }

        }


        function validatePassword(pw) {

            return /[A-Z]/       .test(pw) &&
                   /[a-z]/       .test(pw) &&
                   /[0-9]/       .test(pw) &&
                   /[^A-Za-z0-9]/.test(pw) &&
                   pw.length > 7;
        
        }
        
}


}


// if (contactForm.name == "") {
//     invalid.push('Fyll ut navn')
// }
// if (contactForm.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).length == 0) {
//     invalid.push('Fyll ut epost')
// }
// if (contactForm.telefon == "") {
//     invalid.push('Fyll ut telefon')
// }

// let invalid =  this.$validate({
//       type:mail,
//       value:contactForm.name,
//       label:'Fyll ut navn'
//   },
//   {
//     value:contactForm.email,
//     label:'Fyll ut epost'
//   }
//   )

// if(invalid.length>0){
//     return
// }



//   if(this.$validate.isEmpty(contactForm.name)){
//     this.invalid.push('Fyll ut epost?')
//   }


//   if(invalid)

