<template>
  <div class="container">
    <div v-if="ticket">
      <Header v-if="!newTicket" title="Oppgave" />
      <Header v-else title="Ny oppgave" />
      <div>

        <div class="card card--ticket m-top">
          <div class="input">
            <label for="task">Oppgave<span class="req">*</span></label>
            <select required id="task" v-model="ticket.type">
              <option disabled selected value="-1">Velg oppgave</option>
              <option :value="option.id" v-for="option in ticketTypes">
                {{ option.name }}
              </option>
            </select>
          </div>

          <div v-if="!newTicket" class="input">
            <label for="project">Prosjekt</label>
            <input disabled v-model="ticket.project_name" type="text" id="project" />
          </div>

          <div v-if="newTicket" class="input">
            <label for="project">Prosjekt</label>
            <input disabled v-model="ticket.project_name" type="text" id="project" />
          </div>


          <div class="input">
            <label for="lead">Lead/Ansvarlig</label>

            <DropdownMultiple :id="'lead'" v-if="users" @input="ticket.lead = $event" v-model="ticket.lead"
              :data="ticket.lead" :options="sortedUsers(users)" label="full_name" />
          </div>


          <div class="input">
            <label for="priority">Prioritert<span class="req">*</span></label>
            <select id="priority" required v-model="ticket.priority">
              <option disabled selected value="-1">Velg Prioritet</option>
              <option value="1">Lav</option>
              <option value="2">Middels</option>
              <option value="3">Høy</option>
            </select>
          </div>
          <div class="input">
            <label for="status-2">Status<span class="req">*</span></label>
            <select required v-model="ticket.status" id="status-2">
              <option disabled selected value="-1">Velg Status</option>
              <optgroup label="Vanlig">
                <option v-for="option in ticketStatusType(1)" :value="option.id">
                  {{ option.name }}
                </option>
              </optgroup>
              <optgroup label="Telenor">
                <option v-for="option in ticketStatusType(2)" :value="option.id">
                  {{ option.name }}
                </option>
              </optgroup>
            </select>
          </div>

          <div class="input" v-if="!newTicket">
            <label for="date-created">Dato opprettet</label>
            <input disabled :value="$dayjs(ticket.date_created).format('DD.MM.YY HH:mm')" type="text" id="date-created" />
          </div>

          <div class="input">
            <label for="created_by">Opprettet av</label>
            <input disabled v-model="ticket.author" type="text" id="created_by" />
          </div>

          <div class="input" v-if="ticket.template_id">
            <label for="template">Template</label>
            <input disabled :value="templateName(ticket.template_id)" type="text" id="template" />
          </div>

          <div class="input" v-if="ticket.entry_id && ticket.template_id && !ticket.entry_name">
            <label for="date-created">Entry</label>
            <a class="entry-link" :href="`#/template/${ticket.template_id}/entry/${ticket.entry_id}`">Link til
              oppføring</a>
          </div>

          <div class="input" v-if="$route.query.entry_name">
            <label for="description">Entry</label>
            <input disabled :value="$route.query.entry_name" type="text" id="entry-name" />
          </div>

          <div class="input">
            <label for="description">Beskrivelse<span class="req">*</span></label>
            <textarea required maxlength="1000" rows="10" v-model="ticket.description" type="textarea"
              id="description"></textarea>
          </div>
          <p v-if="!newTicket" class="log">Felter med<span class="req">*</span> må fylles ut</p>
        </div>


        <div class="ticket-btns">
          <button v-if="!newTicket" @click="patchTicket" class="btn btn--full btn--icon">
            <IconSave />
            Lagre
          </button>
          <button v-if="newTicket" @click="postTicket" class="btn btn--full btn--icon">
            <IconPlus />
            Opprett
          </button>
        </div>

        <div v-if="!newTicket" class="card card--ticket-doc">
          <Header title="Dokumenter" />
          <Files @loaded="(loaded = true)" type="ticket" />
        </div>

        <!-- Kommentarfelt -->
        <div v-if="!newTicket" class="card card--comment">
          <div v-if="!parentComment" class="flex-col-1 input-comment">
            <label for="new-comment">Ny kommentar</label>
            <textarea maxlength="1000" ref="comment" required v-model="newComment" type="text"
              id="new-comment"></textarea>
            <div class="m-btm">
              <button class="btn btn--outline btn--icon" @click="createComment">
                <IconMail />
                Post
              </button>
            </div>
          </div>

          <div v-for="comment in comments">
            <div class="comment-box" v-if="comment.parent_id == null">
              <IconBin v-if="this.$canDelete(comment.user_id)" @click="deleteComment(comment.id)" class="big-bin" />


              <div class="comment">
                <p class="parent-comment"> {{ comment.comment }}</p>
                <p class="auth-comment">
                  <span class="log">{{ comment.user }}</span>
                  <span class="log">&nbsp;{{ $dayjs(comment.created_at).format('DD.MM.YY HH:mm') }}</span>
                </p>
                <span class="reply-btn btn--icon" @click="parentComment = comment.id;">
                  <IconReply />Svar
                </span>
              </div>

              <div v-if="parentComment == comment.id" class="flex-col-1 input-reply">

                <textarea maxlength="1000" class="new-reply-area" autofocus ref="reply" required v-model="newComment"
                  type="text" id="new-comment" placeholder="Svar.."></textarea>
                <div class="m-btm">
                  <button class="btn btn--outline btn--icon" @click="createComment">
                    <IconSend />
                    Post svar
                  </button>
                </div>

              </div>

              <div class="comment-reply" v-if="checkParentComment(comment.id).length > 0">
                <div class="sub-comment" v-for="subComment in checkParentComment(comment.id)">
                  <!-- <div v-if="this.$canDelete(subComment.user_id)"> -->
                  <IconBin v-if="this.$canDelete(subComment.user_id)" @click="deleteComment(subComment.id)"
                    class="small-bin" />
                  <!-- </div> -->
                  <p> {{ subComment.comment }} </p>
                  <p>
                    <span class="log">{{ subComment.user }}</span>
                    <span class="log">&nbsp;{{ $dayjs(subComment.created_at).format('DD.MM.YY HH:mm') }}</span>
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
        <button v-if="!newTicket" @click="showDelete = true" class="btn btn--red m-top btn--icon">
          <IconBin />
          Slett Oppgave
        </button>
      </div>

    </div>

    <Modal title="Er du sikker du vil slette oppgave?" confirmLabel="Slett oppgave" cancelLabel="Nei, Avbryt"
      @confirm="confirmation" v-if="showDelete" />

    <!-- {{this.$toast()}} -->
  </div>
</template>

<script>

// import SearchResults from '@/components/Search/SearchResults.vue';
import TicketService from '@/services/Ticket';
import ProjectService from '@/services/Project'
import Files from '../Template/Tabs/Files'
import UserService from '@/services/User';
export default {

  mounted: function () {
    // this.$loader(true) 
    this.ticketService = new TicketService();
    this.projectService = new ProjectService();
    this.userService = new UserService();
    this.checkTicket();
    this.fetchData();
  },

  methods: {
    deleteComment(postId) {
      if (confirm('Er du sikker?')) {
        this.$loader(true)
        this.ticketService
          .deleteComment(postId)
          .then((res) => {
            this.$toast(res.status, res.message)
            this.getComments()
            this.$loader(false)
          })
      }
    },
    sortedUsers(users) {
      let direction = 1
      return users.sort((a, b) => (a.full_name > b.full_name) ? direction : direction * -1)
    },
    async fetchData() {
      await this.userService
        .getAllUsers()
        .then((data) => (this.users = data))
    },
    ticketStatusType(type) {
      return this.ticketStatus2.filter((x) => x.type == type)
    },
    confirmation(t) {
      if (t == 'cancel') {
        this.showDelete = false
      }
      if (t == 'confirm') {
        this.deleteTicket()
      }
    },
    templateName(id) {
      if (!id) {
        return '';
      }
      return this.$store.getters.templates.find((template) => template.id == id)
        .name;
    },
    entryName() {
      return 'Link';
    },
    checkParentComment(id) {
      return this.comments.filter((obj) => {
        return obj.parent_id == id;
      });
    },
    deleteTicket() {
      //???
      this.ticketService
        .deleteTicket(this.$route.params.id)
        .then((res) => {
          if (res.status == 'success') {
            this.$loader(false)
            this.$toast('success', res.message)
            this.showDelete = false
            this.$router.push('/tickets')
          }
        })
    },
    checkTicket() {
      if (isNaN(this.$route.params.id)) {
        this.ticket = { type: -1, priority: -1, status: -1, }
        this.checkExisting()
        this.getProjects()
      } else {
        this.$loader(true)
        this.newTicket = false;
        this.ticketService
          .getTicket(this.$route.params.id)
          .then((res) => {
            this.ticket = res[0]
            if(this.ticket.lead){
              this.ticket.lead = this.ticket.lead.split(",").map(Number)

            } else{
              this.ticket.lead=[]
            }
            this.$loader(false)
          })
          .then((res) => (this.copy = JSON.parse(JSON.stringify(this.ticket))))
        // .then((res)=>     this.checkExisting())
        this.copy = JSON.parse(JSON.stringify(this.ticket));
        this.getComments()
      }
    },
    getComments() {
      this.ticketService
        .getComments(this.$route.params.id)
        .then((res) =>

          (this.comments = res)
        );
    },
    checkExisting() {
      if (this.$route.params.project) {
        this.ticket.project_id = this.$route.params.project
        this.ticket.project_name = this.$route.query.project_name
      }
      if (this.$route.params.template && this.$route.params.entry) {
        this.ticket.template_id = this.$route.params.template
        this.ticket.entry_id = this.$route.params.entry

        this.ticket.entry_name = this.$route.query.entry_name
        // this.ticket.entry

      }
    },
    postTicket() {

      let ticket = this.ticket;
      if (ticket.priority == -1 || ticket.status == -1 || ticket.type == -1 || !ticket.description) {
        this.$loader(false)
        this.$toast('error', 'Data mangler', 'Fyll ut påkrevde felt')
        return
      }
      this.$loader(true)


      // return

      ticket.project_id = parseInt(ticket.project_id)
      this.ticketService.postTicket(ticket).then((res) => {
        this.$toast('success', 'Ny oppgave opprettet')
        this.$router.push('/tickets/');
        this.$loader(false)
      })
      // this.ticketService.postTicket(ticket).then((res) => this.$router.push('/ticket/'+res.id));
      // this.$forceUpdate()
    },
    createComment() {
      const comment = this.newComment;
      if (!comment) {
        return
      }
      if (comment.length > 1000) {
        this.$toast('error', 'For lang kommentar, maks 1000 tegn')
        return
      }
      this.$loader(true)
      const id = this.ticket.id;
      const parentComment = this.parentComment;
      const commentObj = { comment: comment, parentComment: parentComment };
      this.ticketService
        .postComment(id, commentObj)
        .then((res) => {
          if (res.status == 'success') {
            this.newComment = null;
            this.parentComment = null;
            this.$loader(false)
            this.$toast(res.status, res.message)
            this.getComments();
          } else {
            this.$toast(res.status, res.message)
            this.$loader(true)
          }

        })
    },
    patchTicket() {
      if (this.ticket.description == "") {
        this.$toast('warning', 'Fyll ut beskrivelse')
        return
      }
      // this.loading = true;
      this.$loader(true)
      const ticket = this.ticket;
      const changes = this.changes();
      // return
      const data = {
        changes: changes,
        ticket: {
          id: ticket.id,
          project_id: ticket.project_id,
          entry_id: ticket.entry_id,
          template_id: ticket.template_id,
        },
      };
      // return
      // let ticket = this.newTicket;
      this.ticketService
        .patchTicket(data)
        .then((res) => {

          this.$loader(false)
          this.$toast('success', 'Oppgave oppdatert!');
        })
    },
    getProjects() {
      let filter = '?all=1'
      this.projectService
        .getProjects(filter)
        .then((response) => {
          (this.projects = response)
          this.$loader(false)
        });
    },

    getResult(item, template) {
      this.projects = {};
      this.newTicket.template_id = template;
      this.newTicket.entry_id = item.id;
      this.newTicket.project_id = item.project;
      this.entries = [{ id: item.id, name: 'Test' }];
      this.projects = [{ id: item.project, name: item.project_name }];
      this.searchTerm = '';
    },
    changes() {
      let ticket = this.ticket;
      let copy = this.copy;
      let changes = [];

      for (let i = 0; i < Object.keys(ticket).length; i++) {
        if (Object.values(ticket)[i] != Object.values(copy)[i]) {
          changes.push({
            [Object.keys(ticket)[i]]: Object.values(ticket)[i],
          });
        }
      }
      return changes;
    },
  },
  props: ['existingTicket', 'mode'],

  data() {
    return {
      newTicket: true,
      userService: null,
      users: null,
      showDelete: false,
      copy: null,
      ticketService: null,
      priorities: [
        { name: 'Høy', id: 3 },
        { name: 'Middels', id: 2 },
        { name: 'Lav', id: 1 },
      ],
      newComment: null,
      parentComment: null,
      loading: false,
      comments: null,
      // ticket: [],
      ticket: null,
      createNew: true,
      entries: [],
      projects: [],
      ticketTypes: this.$store.getters.ticketTypes,
      ticketStatus2: this.$store.getters.ticketStatus2,
      baseTemplates: this.$store.getters.baseTemplates,
    };
  },
  components: { Files },
};
</script>

<style scoped>
.container {
  max-width: 120rem;
}

.card--ticket {
  display: grid;
  row-gap: 2rem;
  column-gap: 4rem;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 2rem;
}

.card--comment {
  margin-top: 2rem;
}

.card--ticket-doc {
  margin-top: 2rem;
}

.entry-link {
  color: var(--main-color);
  font-size: 1.8rem;
  margin-top: 1rem;
}

.ticket-btns .btn {
  padding: 1rem 4rem;
}

.icon-send {
  height: 2rem;
}

.icon-reply {
  height: 1.6rem;
}

@media (max-width: 700px) {
  .card--ticket {
    grid-template-columns: 1fr;
  }
}
</style>
