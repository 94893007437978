import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import dayjs from 'dayjs'
require('dayjs/locale/nb')
import role from './plugins/role'
import loader from './plugins/loader'
import validate from './plugins/validate'

const app = createApp(App);

app.config.globalProperties.$dayjs = dayjs
dayjs.locale('nb') // use loaded locale globally
const utc = require('dayjs/plugin/utc')
var weekOfYear = require('dayjs/plugin/weekOfYear')

dayjs.extend(weekOfYear)
dayjs.extend(utc)
//==========================//
//      Axios               //
//==========================//

const axios_instance = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
});

axios.defaults.baseURL =
  process.env.VUE_APP_BASEURL ||
  process.env.baseURL ||
  process.env.apiUrl ||
  '';


//==========================//
//    Egne komponenter      //
//==========================//
import toast from './plugins/toast'
import Header from './components/UI/Header.vue'
import Footer from './components/UI/Footer.vue'
import DropdownMultiple from './components/UI/DropdownMultiple.vue'
import Modal from './components/UI/Modal.vue'
import Loader from './components/UI/Loader.vue'
import Toast from './components/UI/Toast.vue'
// import './registerServiceWorker'

//==========================//
//          ICONS           //
//==========================//
import IconBin from '@/components/Icons/IconBin';
import IconCard from '@/components/Icons/IconCard';
import IconCheck from '@/components/Icons/IconCheck';
import IconChevronRight from '@/components/Icons/IconChevronRight';
import IconChevronLeft from '@/components/Icons/IconChevronLeft';
import IconChevronUp from '@/components/Icons/IconChevronUp';
import IconChevronDown from '@/components/Icons/IconChevronDown';
import IconChevronRight2 from '@/components/Icons/IconChevronRight2';
import IconClose from '@/components/Icons/IconClose';
import IconContact from '@/components/Icons/IconContact';
import IconDocument from '@/components/Icons/IconDocument';
import IconDotsVertical from '@/components/Icons/IconDotsVertical';
import IconDownload from '@/components/Icons/IconDownload';
import IconEdit from '@/components/Icons/IconEdit';
import IconExMark from '@/components/Icons/IconExMark';
import IconFilter from '@/components/Icons/IconFilter';
import IconHelp from '@/components/Icons/IconHelp';
import IconLogOut from '@/components/Icons/IconLogOut';
import IconLogIn from '@/components/Icons/IconLogIn';
import IconMail from '@/components/Icons/IconMail';
import IconMenu from '@/components/Icons/IconMenu';
import IconNotification from '@/components/Icons/IconNotification';
import IconPlus from '@/components/Icons/IconPlus';
import IconProduction from '@/components/Icons/IconProduction';
import IconProfile from '@/components/Icons/IconProfile';
import IconReply from '@/components/Icons/IconReply';
import IconSave from '@/components/Icons/IconSave';
import IconSearch from '@/components/Icons/IconSearch';
import IconSetting from '@/components/Icons/IconSetting';
import IconSend from '@/components/Icons/IconSend';
import IconShare from '@/components/Icons/IconShare';
import IconUpdate from '@/components/Icons/IconUpdate';
import IconUpload from '@/components/Icons/IconUpload';

app.component('Header', Header)
app.component('Footer', Footer)
app.component('Modal', Modal)
app.component('DropdownMultiple', DropdownMultiple)
app.component('Loader', Loader)
app.component('Toast', Toast)

//==========================//
//          ICONS           //
//==========================//

app.component('IconBin', IconBin)
app.component('IconCard', IconCard)
app.component('IconCheck', IconCheck)
app.component('IconChevronRight', IconChevronRight)
app.component('IconChevronLeft', IconChevronLeft)
app.component('IconChevronDown', IconChevronDown)
app.component('IconChevronUp', IconChevronUp)
app.component('IconChevronRight2', IconChevronRight2)
app.component('IconClose', IconClose)
app.component('IconContact', IconContact)
app.component('IconDocument', IconDocument)
app.component('IconDotsVertical', IconDotsVertical)
app.component('IconDownload', IconDownload)
app.component('IconEdit', IconEdit)
app.component('IconExMark', IconExMark)
app.component('IconFilter', IconFilter)
app.component('IconHelp', IconHelp)
app.component('IconLogOut', IconLogOut)
app.component('IconLogIn', IconLogIn)
app.component('IconMail', IconMail)
app.component('IconMenu', IconMenu)
app.component('IconNotification', IconNotification)
app.component('IconPlus', IconPlus)
app.component('IconProduction', IconProduction)
app.component('IconProfile', IconProfile)
app.component('IconReply', IconReply)
app.component('IconSave', IconSave)
app.component('IconSearch', IconSearch)
app.component('IconSetting', IconSetting)
app.component('IconSend', IconSend)
app.component('IconShare', IconShare)
app.component('IconUpdate', IconUpdate)
app.component('IconUpload', IconUpload)

app.use(role)
app.use(toast)
app.use(loader)
app.use(validate)

app.use(store);
app.use(router);
store.$http = axios_instance;
app.config.globalProperties.$http = axios_instance;
app.mount('#app');
