<template>
  <div class="container">
    <Header title="Administrator innstillinger" />

    <div class="card m-top">
      <h4>Legg til ny bruker</h4>
      <div class="new-user">
        <div class="input">
          <label for="new-user-email">Ny brukers e-post<span class="req">*</span></label>
          <input id="new-user-email" required v-model="newUser.email" type="email" placeholder="epost@firma.no">
        </div>
        <div class="input">
          <label for="new-user-role">Ny brukers rolle<span class="req">*</span></label>
          <select required v-model="newUser.role" id="new-user-role">
            <option disabled selected value="-1">Velg rolle</option>
            <option value=2>Bruker</option>
            <option value=1>Prosjektleder</option>
            <option value=0>Administrator</option>
          </select>
        </div>
      </div>
      <div class="m-top">
        <button @click="sendInvite()" class="btn btn--outline btn--icon">
          <IconSend />Send
          invitasjon
        </button>
        <div class="password-error" v-if="invalidForm.length > 0">
          <p v-for="error in invalidForm">
            {{ error }}
          </p>
        </div>
      </div>
      <p class="log m-top">Du kan se hva de forskjellige rollene innebærer <a href="#/guide">her</a></p>
    </div>

    <div v-if="(pendingInvitations.length > 0)" class="card">
      <h4>Brukere som ikke har logget inn</h4>
      <div class="flex-col-1 user-pending">
        <div v-for="user in pendingInvitations" class="user-pending-box">
          <p class="user-btn-p" :for="user.id">{{ user.email }}</p>
          <div class="flex-row-1 user-pending-btns">
            <button @click="resendInvite(user.id)" class="btn btn--outline btn--icon">
              <IconMail />Send
              påminnelse
            </button>
            <button @click="deleteModal('invitation', user.id, user.email)" class="btn btn--red btn--icon">
              <IconBin />Slett
              invitasjon
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="users.length > 0" class="card">
      <h4>Aktive brukere</h4>
      <div class="flex-col-1">
        <div v-for="user in sortedUsers(users)" class="flex-row-1 user-deact-box">
          <div class="flex-col-1">
            <p style="font-weight: 500;" :for="user.id">{{ user.full_name }}</p>
            <p :for="user.id">{{ user.email }}</p>
          </div>
          <div class="flex-row-1 user-deact-btns">
            <select @change="changeRole(user, $event)" :value="user.role" class="user-btn-select" name="" :id="user.id+'role'">
              <option value="2">Bruker</option>
              <option value="1">Prosjektleder</option>
              <option value="0">Admin</option>
            </select>
            <button @click="deleteModal('user', user.id)" class="btn btn--red btn--icon">
              <IconBin />Slett bruker
            </button>
          </div>
        </div>
      </div>
    </div>

    <div>
      <p> Klikk <router-link class="" to="/progress-dash">her</router-link> for dashbordvisning av fremgang</p>
      <p class="log">Får å komme ut av dashbordvisning, klikk på logo øverst til venstre</p>
    </div>
    <Modal :title="`Advarsel!`" :type="modalIcon" :message="warningMessage" :confirmLabel="confirmationLabel"
      cancelLabel="Nei, Avbryt" @confirm="confirmation" v-if="showDelete" />
  </div>
</template>

<script>
import UserService from '@/services/User';
export default {
  created() {
    this.userService = new UserService()
    this.fetchData();
    this.getPendingInvitations()
  },
  methods: {
    sortedUsers(users) {
      let direction = 1
      return users.sort((a, b) => (a.full_name > b.full_name) ? direction : direction * -1)
    },
    changeRole(user, event) {
      this.selectedRole = event.target.value
      this.oldRole = user.role
      user.role = event.target.value
      this.modalIcon = 'delete'
      this.deleteModal('userRole', user.id)
    },
    deleteModal(type, id, email, role) {
      if (type == 'user') {
        this.selectedId = id
        this.type = type
        this.modalIcon = null
        this.warningMessage = `Er du sikker på at du vil slette brukeren til ${this.userName()}?`
        this.confirmationLabel = 'Slett bruker'
        this.showDelete = true
      }
      if (type == 'invitation') {
        this.selectedId = id
        this.modalIcon = null
        this.type = type
        this.warningMessage = 'Er du sikker på at du vil slette invitasjonen til ' + email
        this.confirmationLabel = 'Slett invitasjon'
        this.showDelete = true
      }
      if (type == 'userRole') {
        this.selectedId = id
        this.selectedRole = this.selectedRole
        this.type = type
        this.warningMessage = `Endre rolle til ${this.userName()}?`
        this.confirmationLabel = 'Endre rolle'
        this.showDelete = true
      }
    },
    resendInvite(id) {
      this.userService
        .resendInvite(id)
        .then((res) => {
          this.$loader(false)
          this.$toast(res.status, res.message)
        })
    },
    getPendingInvitations() {
      this.userService
        .getPendingInvitations()
        .then((res) => {
          this.pendingInvitations = res.data
        })
    },
    deleteInvitation(id) {
      this.$loader(true)
      this.userService
        .deleteInvitation(id)
        .then((res) => {
          this.$loader(false)
          this.$toast(res.status, res.message)
          this.getPendingInvitations()
        })

    },
    sendInvite() {
      this.invalidForm = []
      if (!this.$validateEmail(this.newUser.email)) {
        this.invalidForm.push('Skriv en gyldig epost!')
        // return
      }
      if (this.newUser.role == -1) {
        this.invalidForm.push('Velg en rolle!')
        // return
      }
      if (this.invalidForm.length > 0) {
        return
      }
      this.$loader(true)
      this.userService
        .sendInvite(this.newUser)
        .then((res) => {
          this.newUser.email = ''
          this.newUser.role = -1
          this.$loader(false)
          this.getPendingInvitations()
          this.$toast(res.status, res.message)
        })
    },
    userName() {
      let userName = this.users.find(x => x.id == this.selectedId)
      return userName.full_name
    },
    async fetchData() {
      await this.userService
        .getAllUsers()
        .then((data) => {
          this.users = data
        })
      // this.$http.get("/getprojects").then((res) => (this.projects = res.data));

    },
    updateUser(userId, field, value) {
      this.$loader(true)
      const data = {
        userId: userId,
        [field]: value
      }
      if (field == 'role') {
        this.userService
          .updateUser(data)
          .then((res => {
            this.$loader(false)
            this.$toast(res.status, res.message)
          }))
      }
      if (field == 'delete') {
        this.userService
          .deleteUser(data)
          .then((res => {
            this.$loader(false)
            this.$toast(res.status, res.message)
          }))
      }
    },
    confirmation(t) {

      if (t == 'cancel') {
        this.showDelete = false
        this.modalIcon = null
        // this.users[0].role=0
        if (this.type == 'userRole') {
          this.users.find(x => x.id == this.selectedId).role = this.oldRole
        }
      }
      if (t == 'confirm') {
        if (this.type == 'invitation') {
          this.deleteInvitation(this.selectedId)
          this.selectedId = null
          this.type = null
          this.showDelete = false
        }
        if (this.type == 'userRole') {
          this.updateUser(this.selectedId, 'role', this.selectedRole)
          this.selectedId = null
          this.type = null
          this.showDelete = false
        }
        if (this.type == 'user') {
          this.updateUser(this.selectedId, 'delete', true)
          // this.deleteInvitation(this.selectedId)
          this.selectedId = null
          this.type = null
          this.showDelete = false
        }
        // if(this.type=='userRole'){
        // this.updateUser(this.selectedId,'')
        // }
      }
    },
  },
  data() {
    return {
      invalidForm: [],
      selectedId: null,
      type: null,
      projects: [],
      userService: null,
      users: [],
      modalIcon: false,
      selectedRole: null,
      oldRole: null,
      warningMessage: null,
      confirmationLabel: null,
      selectedUser: null,
      showDelete: false,
      pendingInvitations: [],
      newUser: { email: '', role: -1 }
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 80rem;
}

h4 {
  margin-bottom: 2rem;
}

.card {
  margin-bottom: 3rem;
}

.new-user {
  display: flex;
  gap: 2rem;
}

.input {
  width: 40rem;
}

.user-pending-box,
.user-deact-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 9px;
  padding: 1rem 1rem;
  width: 100%;
}

.user-deact-btns {
  gap: 2rem;
}

.flex-row-1 {
  margin-top: 1rem;
}

.user-deact-box {
  flex-direction: row;
  justify-content: space-between;
}

.user-btn-select {
  width: 15rem;
}

.icon-bin,
.icon-send {
  height: 2rem;
}


@media (max-width: 800px) {

  .user-pending-btns {
    flex-direction: column;
    align-items: normal;
  }

  .new-user {
    flex-direction: column;
  }
}

@media (max-width: 750px) {
  .new-user {
    flex-direction: column;
  }

  .input {
    width: 90%;
  }

}

@media (max-width: 700px) {
  .user-deact-box {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2rem;
  }

  .user-deact-btns {
    flex-direction: column;
  }
}

@media (max-width: 650px) {
  .user-pending-box {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 2rem;
  }
}


@media (max-width: 580px) {}
</style>