<template>
  <div class="container">
    <div v-if="step == 0" class="change-box">
      <h2>Endre e-post</h2>
      <div class="input">
        <label for="email">Ny e-post<span class="req">*</span></label>
        <input v-model="email1" id="email" type="email" placeholder="epost@firma.no" autocomplete="username" />
      </div>
      <div class="input">
        <label for="email2">Gjenta ny e-post<span class="req">*</span></label>
        <input v-model="email2" id="email2" type="email" placeholder="epost@firma.no" autocomplete="username" />
      </div>
      <div>
        <div class="password-error" v-if="invalidForm.length > 0">
          <p v-for="error in invalidForm">
            {{ error }}
          </p>
        </div>
        <button @click="submitLogin" class="btn btn--full btn--icon">
          <IconCheck />
          Lagre
        </button>
      </div>
    </div>

    <div v-if="(step == 1)" class="change-box">
      <div class="email-changed">
        <h2>Sjekk e-posten din</h2>
        <p>Vi har sendt deg en link på den nye e-postadressen.</p>
        <p>Klikk på linken innen 1 time for å fullføre endringen</p>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/User'
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      invalidForm: [],
      step: 0,
      pin: null,
      formValid: true,
      userData: {
        email: null,
        pass1: null,
        pass2: null,
        fornavn: null,
        etternavn: null,
      },
      isLoading: false,
      error: null,
      email1: null,
      email2: null,
      userService: null
    };
  },
  created() {
    this.userService = new UserService()
  },
  mounted() {
    if (this.$route.query.pin && this.$route.query.email) {
      this.pin = this.$route.query.pin;
      this.email = this.$route.query.email;
      this.checkPin()
    }

  },
  computed: {
    ...mapGetters(['allstate', 'isAuthenticated']),
  },
  methods: {
    checkPin() {
      if (this.pin.length > 0) {
        let data = {
          pin: this.pin,
          email: this.email
        }
        this.userService
          .confirmEmailChange(data)
          .then((res) => {
            this.$toast(res.status, res.message)
            this.$store.dispatch('logout')
            this.$router.push('/login')
            //logg ut
          })
          .catch((err) => {
          })
      }
    },
    async submitLogin() {
      // this.step++
      this.invalidForm = []
      let userData = {
        email: this.email1,
        userId: this.$store.getters.userInfo.id
      }

      if (this.email1 !== this.email2) {
        this.invalidForm.push('E-post matcher ikke!')
      }
      if (!this.$validateEmail(this.email1) || !this.$validateEmail(this.email2)) {
        this.invalidForm.push('E-post er ikke gyldig')
      }

      if (this.invalidForm.length > 0) {
        return
      }


      //Send http
      this.$loader(true)
      this.userService
        .changeEmail(userData)
        .then((res) => {
          if (res.status == 'success') {
            this.$loader(false)
            this.step++
          }
          if (res.status == 'warning') {
            this.$loader(false)
            this.$toast(res.status, res.message, 'Kontakt FiberX hvis dette er en feil.')
          }
        })
        .catch((err) => {
          this.$toast('error', 'En feil har oppstått', 'Ta kontakt med FiberX, eller prøv igjen senere.')
          this.$loader(false)
        })

    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  background: var(--bg-color);
}

.change-box {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
  width: 60rem;
  max-height: 35rem;
  margin: 0 auto;
  border-radius: 12px;
  background: rgba(100%, 100%, 100%, 0.1);
}

.input {
  width: 75%;
}

.btn--full {
  gap: 1rem;
  padding: 1rem 3rem;
  font-size: 2rem;
}

.email-changed {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-align: center;

}

@media (max-width: 800px) {
  .change-box {
    width: 80%;
  }
}

@media (max-width: 550px) {

  .change-box {
    width: 95%;
  }

  .heading--login {
    font-size: 2.4rem;

  }

  .input {
    width: 95%;
  }
}

@media (prefers-color-scheme: dark) {
  .input input {
    background-color: #e8e8e8;
    color: #444;
    border: none;
  }

}
</style>
