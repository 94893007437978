<template>
  <footer>
    <img :src="imageUrl()" />
    <a href="https://laaneits.no">LaaneITS.no</a>
  </footer>
</template>

<script>
export default {

  methods: {
    imageUrl() {
      let images = require.context("../../assets/", false, /\.png$/);
      let darkmode = window.matchMedia("(prefers-color-scheme: dark)").matches;
      if (darkmode) {
        return images("./Darkmode500.png");
      }
      else {
        return images("./Lightmode500.png");
      }
    },
  },
}
</script>

<style scoped>
footer {
  position: fixed;
  bottom: 0;
  height: 4rem;
  width: 100%;
  z-index: 11;
  background: rgba(100%, 100%, 100%, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
}

footer img {
  height: 3rem;
}

a {
  color: var(--text-color);
}
</style>
