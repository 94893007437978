import axios from 'axios'
// const axios = this.$http

export default class TicketService{
    getTickets(filters){
        return axios.get(`/api/v1/tickets${filters}`).then(res=>res.data)
    }  
    postTicket(ticket){
        return axios.post('/api/v1/ticket',ticket).then(res=>res.data)
    }
    patchTicket(data){
        return axios.patch('/api/v1/ticket',data).then(res=>res.data)
    }
    deleteTicket(id){
        return axios.delete(`/api/v1/ticket/${id}`).then(res=>res.data)
    }
    getTicketsFiltered(filters){
        return axios.get('/api/v1/tickets/filtered?'+filters).then(res=>res.data)
    }
    getTicket(ticketId){
        return axios.get(`api/v1/ticket/${ticketId}`).then(res=>res.data)
    }

    postComment(id,comment){
        return axios.post(`api/v1/ticket/${id}/comment`,comment).then(res=>res.data)
    }

    getComments(id){
        return axios.get(`api/v1/ticket/${id}/comments`).then(res=>res.data)
    }
    deleteComment(id){
        return axios.delete(`api/v1/ticket/comment/${id}`).then(res=>res.data)
    }

    getFilesToTicket(ticketId){
        return axios.get(`/api/v1/files/ticket/${ticketId}`).then(res=>res.data)
    }
}