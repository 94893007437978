<template>
  <div class="container">
    <Header :title="checkTitle()" :button="headerButton" />
    <div v-if="tickets">
      <TicketsTable @click="selectTicket" :existingTickets="tickets" :mode="'entry'" />
    </div>
  </div>
</template>
  
<script>
import TicketService from '@/services/Ticket';
import TicketsTable from '@/components/Tickets/TicketsTable';
import Ticket from '@/components/Tickets/Ticket';
export default {
  components: { TicketsTable, Ticket },
  props: ['entry', 'showAll'],
  data() {
    return {
      ticketService: null,
      tickets: null,

    };
  },
  unmounted() {
    // this.tickets=null
  },
  mounted: async function () {
    this.ticketService = new TicketService();
    this.fetchData();
  },
  computed: {
    headerButton() {
      let entryName = null
      switch (this.$route.params.template) {
        case '1':
          if (!this.entry.punkt_navn) {
            entryName = ''
          } else {
            entryName = this.entry.punkt_navn
          }
          break;
        case '2':
          if (!this.entry.punkt_fra) {
            entryName = ''
          } else {
            entryName = this.entry.punkt_fra + "->" + this.entry.punkt_til
          }
          break;
        case '8':
          if (!this.entry.navn) {
            entryName = ''
          } else {
            entryName = this.entry.navn
          }
          break;
        default:
          entryName = 'oki'
          break;
      }
      return {
        label: 'Ny oppgave',
        icon: 'IconPlus',
        link: `/ticket/new/${this.$route.params.project}?project_name=${this.$route.query.project_name}`
      };
    }
  },
  methods: {
    checkTitle() {
      if (localStorage.getItem('projectInfo')) {
        let project = JSON.parse(localStorage.getItem('projectInfo'))
        if (project.id == this.$route.params.project) {
          return `${project.name} / Oppgaver`
        }
      }
      return 'Oppgaver'
    },
    selectTicket(ticket) {
      this.mode = 'edit';
      this.choosenTicket = ticket;
      this.$router.push('/ticket/' + ticket.id)
    },
    async fetchData() {
      await this.ticketService
        .getTicketsFiltered(
          `project=${this.$route.params.project}`
        )
        .then((message) => {
          this.tickets = message;
        });
    },
  },
};
</script>
  
<style scoped>
.container {
  max-width: 170rem;
}
</style>
  