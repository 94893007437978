<template>
  <div class="container">



    <Header title="Brukerinstillinger" />
    <div class="card m-top">
      <h3>Brukerdetaljer</h3>
      <div class="input">
        <label for="firstname">Fornavn</label>
        <input disabled :value="this.$store.getters.userInfo.firstName" id="firstname" type="text" />
      </div>
      <div class="input">
        <label for="lastname">Etternavn</label>
        <input disabled :value="this.$store.getters.userInfo.lastName" id="lastname" type="text" />
      </div>
      <div class="input">
        <label for="company">Firma</label>
        <input autocomplete="off" disabled :value="this.$store.getters.userInfo.companyName" id="company" type="text" />
      </div>
      <div class="input">
        <div class="input-changeemail">
          <label for="email">E-post</label>
          <span class="change-email log">&nbsp;<a href="#/changeemail">(Endre e-post)</a></span>
        </div>
        <input autocomplete="off" disabled :value="this.$store.getters.userInfo.email" id="email" type="email" />
      </div>
      <!-- <div class="input">
        <label for="">Telefon</label>
        <input id="username" type="tel" />
      </div> -->
      <!-- <button class="btn btn--full btn--usersettings">Lagre</button> -->
      <p class="log">Om du vil endre navn, ta kontakt med FiberX</p>
    </div>

    <div class="card">
      <h3>Bytt passord</h3>
      <div class="input">
        <label for="password_0">Gammelt Passord<span class="req">*</span></label>
        <input v-model="oldPassword" required type="password" name="" id="password_0" autocomplete="current-password" />
      </div>
      <div class="input">
        <label for="password_1">Nytt Passord<span class="req">*</span></label>
        <input v-model="newPassword" required type="password" name="" id="password_1" autocomplete="new-password" />
      </div>
      <div class="input">
        <label for="password_2">Gjenta Passord<span class="req">*</span></label>
        <input v-model="confirmPassword" required type="password" name="" id="password_2" autocomplete="new-password" />
      </div>
      <p class="password-tip">Passord må være minst 8 tegn, inneholde minst en stor, en liten bokstav og ett tall</p>
      <div class="flex-row-1">
        <button @click="updatePassword()" class="btn btn--outline btn--icon">
          <IconUpdate />Oppdater
          passord
        </button>
        <div class="password-error" v-if="invalid.length > 0">
          <p v-for="error in invalid">
            {{ error }}
          </p>
        </div>
      </div>
    </div>

    <div class="card">
      <h3>Overstyr Tema</h3>
      <div class="input">
        <select @change="changeColor" v-model="color" name="color" id="colorTheme">
          <option disabled value="-1">Velg tema</option>
          <option value="light">Light</option>
          <option value="dark">Dark</option>
          <option value="auto">Auto</option>
        </select>
      </div>
    </div>

    <div class="">
      <button @click="forceResetData()" class="btn btn--outline">
        Reset Grunndata
      </button>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserService from '@/services/User'
export default {
  mounted() {
    this.userService = new UserService
    this.getColor()
  },
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
      invalid: [],
      userService: null,
      color: null,
    }
  },
  methods: {
    getColor() {
      if (localStorage.getItem("theme")) {
        this.color = (localStorage.getItem("theme"))
        // document.documentElement.setAttribute("data-theme", this.color);
      } else {
        this.color = "auto"
      }
    },
    changeColor() {
      document.documentElement.setAttribute("data-theme", this.color);
      localStorage.setItem("theme", this.color)
      return

    },
    async forceResetData() {
      try {
        await this.$store.dispatch('getData');
        // this.$router.push("/");
      } catch (err) {
        this.error = err.message || 'Feil med registrering';
      }
    },
    updatePassword() {
      this.invalid = []
      if (!this.newPassword || !this.confirmPassword || !this.oldPassword) {
        this.invalid.push('Fyll ut begge passord')
        return
      }
      this.invalid = []
      // let password = this.newPassword
      // let confirmPassword = this.confirmPassword
      if (this.newPassword != this.confirmPassword) {
        this.invalid.push('Passord er ikke likt')
      }
      if (this.newPassword.length < 8) {
        this.invalid.push('Passord er for kort')
      }
      if (!this.$validatePassword(this.confirmPassword)) {
        this.invalid.push('Passordet oppfyller ikke alle kriterier')
      }
      if (this.invalid.length > 0) {
        return
      }
      const data = {
        userId: this.$store.getters.userInfo.id,
        password: this.newPassword,
        oldPassword: this.oldPassword
      }
      this.userService
        .changePassword(data)
        .then((res) => {
          this.$toast(res.status, res.message)
          if (res.status == 'success') {
            this.$store.dispatch('logout');
            this.$router.push('/login');
          }
        })
      return

    },
    async forceResetData() {
      try {
        await this.$store.dispatch('getData');
      } catch (err) {
        this.error = err.message || 'Feil med registrering';
      }
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 70rem;
}

.card {
  gap: 2rem;
  margin-bottom: 3rem;
}

.input-changeemail a {
  vertical-align: text-top;
}

.change-email a {
  color: var(--main-color);
}

.password-tip {
  font-style: italic;
  color: var(--log-color);
}
</style>
