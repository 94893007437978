<template>
  <div v-show="tickets" class="container">
    <Header title="Oppgaver" :button="button" />
    <TicketsTable @loaded="loaded()" @click="selectTicket" />
    <p class="log m-top">For mer om hvordan du bruker oppgaver se <a href="#/guide">her</a></p>
  </div>
</template>

<script>
import TicketsTable from '../components/Tickets/TicketsTable.vue';
import SearchResults from '../components/Nav/SearchResults.vue';
import TicketService from '@/services/Ticket';

export default {
  mounted: function () {
    this.ticketService = new TicketService();
    this.$loader(true)
  },

  methods: {
    loaded() {
      this.$loader(false)
      this.tickets = true
    },
    selectTicket(ticket) {
      this.mode = 'edit';
      this.choosenTicket = ticket;
      this.$router.push('/ticket/' + ticket.id)
    },

    getResult(item, template) {
      this.projects = {};
      this.newTicket.template_id = template;
      this.newTicket.entry_id = item.id;
      this.newTicket.project_id = item.project;
      this.entries = [{ id: item.id, name: 'Test' }];
      this.projects = [{ id: item.project, name: item.project_name }];
      this.searchTerm = '';
    },
  },
  data() {
    return {
      button: {
        label: 'Ny oppgave',
        icon: 'IconPlus',
        link: '/ticket/new',
      },
      mode: 'list',
      userService: null,
      choosenTicket: null,
      ticketService: null,
      searchTerm: null,
      priorities: [
        { name: 'Høy', id: 3 },
        { name: 'Middels', id: 2 },
        { name: 'Lav', id: 1 },
      ],
      createNew: true,
      newTicket: {},
      users: null,
      tickets: false,
      ticketTypes: this.$store.getters.ticketTypes,
      ticketStatus2: this.$store.getters.ticketStatus2,
      baseTemplates: this.$store.getters.baseTemplates,
    };
  },
  components: {
    TicketsTable,
    SearchResults,
  },
};
</script>

<style scoped>
.container {
  max-width: 170rem;
}
</style>



