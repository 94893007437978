<template>
  <div @click="toggleNav()" v-if="showNav" class="close"></div>
  <header class="header">
    <nav class="main-nav">
      <div class="main-nav-list">
        <router-link class="main-nav-item" to="/">Prosjekter</router-link>
        <router-link class="main-nav-item" to="/progress">Fremgang</router-link>
        <router-link class="main-nav-item" to="/tickets">Oppgaver</router-link>
      </div>
    </nav>
    <nav class="secondary-nav">
      <div class="secondary-nav-list">
        <div @click="toggleSearch()" class="secondary-nav-item">
          <IconSearch class="icon" />
        </div>


        <div class="secondary-nav-item" @click="toggleNot()">
          <div class="notif-badge">
            <IconNotification class="icon" />
            <div :class="{ 'notif-indicator': unreadNotifications }"></div>
          </div>
        </div>

        <div class="secondary-nav-item" @click="toggleNav(); $refs.navDiv.focus()">
          <IconMenu class="icon" />
        </div>
      </div>
    </nav>
  </header>
  <div ref="navDiv">
    <nav tabindex="0" ref="p" class="mobile-nav" :class="{ show: showNav }">
      <div class="nav-top" @click="toggleNav">
        <IconClose class="icon close-mobile" />
      </div>
      <div class="mobile-nav-list">
        <router-link class="mobile-nav-item" to="/usersettings">
          <IconProfile class="icon" />
          Min profil
        </router-link>
        <div v-if="this.$role('admin')">
          <router-link class="mobile-nav-item" to="/adminsettings">
            <IconSetting class="icon" />
            Administrator
          </router-link>
        </div>
        <router-link class="mobile-nav-item" to="/contact">
          <IconContact class="icon" />
          Kontakt
        </router-link>
        <router-link class="mobile-nav-item" to="/guide">
          <IconHelp class="icon" />
          Hjelp
        </router-link>
        <router-link v-if="this.$role(0) || this.$role(1)" class="mobile-nav-item" to="/production">
          <IconProduction class="icon" />
          Produksjon
        </router-link>
        <span @click="logOut()" class="mobile-nav-item">
          <IconLogOut class="icon" />
          Logg Ut
        </span>
        <div class="nav-footer">
          <p>V1.1.4</p>
          <router-link class="changelog" to="/changelog/1">
            Changelog
          </router-link>
        </div>
      </div>

    </nav>
  </div>

  <div>
    <div @click="toggleNot()" v-if="showNot" class="close"></div>
    <Notifications @unreadNotification="(n) => unreadNotifications = n" @close="showNot = false"
      :class="{ show: showNot }" />
  </div>

  <div>
    <div @click="toggleSearch()" v-if="showSearch" class="close"></div>
    <SearchResults :isShown="showSearch" @close="showSearch = false" :class="{ show: showSearch }" />
  </div>
</template>

<script>
import Notifications from '@/components/Nav/Notifications.vue'
import SearchResults from '@/components/Nav/SearchResults.vue';
import EntryService from '@/services/Entry';
export default {
  mounted: function () {
    this.entryService = new EntryService();
    setInterval(this.changeTitle, 4000);
    this.checkCustomColorTheme()
  },
  watch: {
    $route() {
      document.documentElement.classList.remove('stop-scrolling')
      this.showSearch = false;
      this.showNav = false;
      this.showNot = false;
    },
  },
  data() {
    return {
      showNav: false,
      showNot: false,
      showSearch: false,
      unreadNotifications: 0,
      toggleTitle: false,
    };
  },

  methods: {
    checkCustomColorTheme() {
      if (localStorage.getItem("theme")) {
        this.color = (localStorage.getItem("theme"))
        document.documentElement.setAttribute("data-theme", this.color);
      }
    },
    unreadNotif(quantity) {
      if (quantity > 0) {
        this.unreadNotifications = true

      } else {
        this.unreadNotifications = false
      }
    },
    toggleSearch() {
      this.showSearch = !this.showSearch
      if (this.showSearch) {
        document.documentElement.classList.add('stop-scrolling')
      } else {
        document.documentElement.classList.remove('stop-scrolling')
      }
    },
    toggleNav() {
      this.showNav = !this.showNav;
      if (this.showNav) {
        document.documentElement.classList.add('stop-scrolling')
      } else {
        document.documentElement.classList.remove('stop-scrolling')
      }

    },
    changeTitle() {
      if (!this.unreadNotifications) {
        return
      }
      if (this.toggleTitle) {
        document.title = "Nytt varsel"
        this.toggleTitle = !this.toggleTitle
      } else {
        document.title = "FiberX"
        this.toggleTitle = !this.toggleTitle
      }
    },
    toggleNot() {
      this.showNot = !this.showNot;
      if (this.showNot) {
        document.documentElement.classList.add('stop-scrolling')
      } else {
        document.documentElement.classList.remove('stop-scrolling')
      }

    },
    logOut() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    },
  },
  components: {
    SearchResults,
    Notifications
  },
  computed: {
    loggedIn() {
      return this.$store.getters.isAuthenticated;
    },
  },
};
</script>

<style scoped>
.header {
  height: 4rem;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4rem;
  background: var(--main-color);
  backdrop-filter: blur(10px);
  border-radius: 0 0 6px 6px;
}

.main-nav-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.main-nav-item:link,
.main-nav-item:visited {
  display: inline-block;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 400;
  color: var(--text-nav-color);
  transition: all 200ms ease-in-out;
}

.secondary-nav-list {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.secondary-nav-item {
  transition: all 200ms ease-in-out;
  color: var(--text-nav-color);
  cursor: pointer;
}

.main-nav-item:hover,
.main-nav-item:active,
.secondary-nav-item:hover,
.secondary-nav-item:hover {
  color: var(--bg-color);
}

.icon {
  width: 3rem;
  height: 3rem;
}

.notif-badge {
  position: relative;
}

.notif-indicator {
  position: absolute;
  top: -2px;
  left: 3px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: var(--red-color);
}

.close-mobile:hover {
  stroke: var(--main-color);
  cursor: pointer;
}

.mobile-nav {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateX(200%);
  height: 100vh;
  position: fixed;
  top: 0rem;
  right: 0rem;
  width: 50rem;
  background: var(--bg-light-color);
  z-index: 777;
  transition: all 200ms ease-in-out;
  overflow: scroll;
}

.nav-top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 4rem;
  padding: 4rem 2rem;
}

.mobile-nav-list {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding-top: 2rem;
  justify-content: center;
  align-items: center;
}

.mobile-nav-item,
.mobile-nav-item:link,
.mobile-nav-item:visited {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  font-weight: 400;
  color: var(--text-nav-color);
  padding: 1rem;
  border-radius: 9px;
  transition: all 300ms;
  width: 20rem;
}

.mobile-nav-item:hover,
.mobile-nav-item:active {
  color: var(--main-color);
  box-shadow: 0 0 2px 2px var(--main-color);

}

.show {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
  position: fixed;
  transform: translateX(0);
}

.nav-footer {
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 2rem;
  margin-top: 5rem;
  padding-left: 1rem;
  color: var(--log-color);
  font-size: 1.4rem;
  width: 20rem;
}

.changelog,
.changelog:visited {
  color: var(--log-color);
  text-decoration: none;
}

.changelog:hover,
.changelog:active {
  text-decoration: underline;
}

.mobile-nav::-webkit-scrollbar {
  display: none;
}

@media (max-width: 700px) {
  .main-nav-list {
    gap: 1rem;
  }

  .main-nav-item:link {
    font-size: 1.8rem;
  }

  .search-box {
    width: 15rem;
  }

  .secondary-nav-item .icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (max-width: 550px) {
  .header {
    padding: 0 0.5rem;
  }

  .search-box {
    width: 10rem;
  }
}

@media (max-width: 400px) {
  .header {
    padding: 0 .5rem;
  }

  .main-nav-list {
    gap: .7rem;
  }

  .secondary-nav-list {
    gap: .7rem;
  }

  .main-nav-item:link {
    font-size: 1.6rem;
  }

  .secondary-nav-item .icon {
    width: 2.3rem;
    height: 2.3rem;
  }
}

@media (prefers-color-scheme: dark) {

  .header {
    background: var(--navbar-color);
  }
}

/* @media only screen and (hover: none) { */
@media only screen and (max-width:800px) {

  .mobile-nav,
  .notification-nav {
    width: 100%;
    padding: 2rem 0 2rem 0;
  }
}


@media (max-height: 740px) {

  .mobile-nav-list {
    gap: 2rem;
    margin-bottom: 5rem;
  }
}

@media (max-height: 620px) {

  .mobile-nav-list {
    padding-top: 0;
  }

  .nav-top {
    padding-bottom: 1rem;
  }

  .nav-footer {
    margin-top: 1rem;
  }
}
</style>
