import axios from 'axios'
// const axios = this.$http

export default class ProjectService{
    getNotifications(){
        return axios.get(`/api/v1/notifications`).then(res=>res.data)
    }
    readNotification(id){
        return axios.get(`/api/v1/notification/read/${id}`).then(res=>res.data)
    }
    clearAll(){
        return axios.put(`/api/v1/notifications/clear/`).then(res=>res.data)
    }

}