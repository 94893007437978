export default{
    setBasedata(state,payload){
        state.templates=payload.templates
        state.materials=payload.materials
        state.checklists=payload.checklists
        // state=payload
        // state.templates=payload[0].templates
        // state.materials=payload
    },
    setCustomSort(state,payload){
        
        state.customSort=payload.sort
        return
    },
    setCurrentSort(state,payload){
        state.currentSort = payload.sort
        return
    },
    setSortChoice(state){

    }
}

