import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default{
    state() {
        return {
            userId:null,
            token:null,
            firstName:null,
            lastName:null,
            tokenExpiration:null,
            didAutoLogout:false,
            email:null,
            role:null,
        }
    },
    mutations,
    actions,
    getters
};