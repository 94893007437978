<template>
  <div v-if="projects" class="container">
    <div class="header--progress">
      <Header title="Fremgang" />
      <button v-if="this.show.length < this.projects.length" @click="toggleAll()" class=" btn btn--outline btn--icon">
        <IconChevronDown /><span>Åpne
          alle</span>
      </button>
      <button v-if="this.show.length == this.projects.length" @click="toggleAll()" class=" btn btn--outline btn--icon">
        <IconChevronUp /><span>Lukk alle</span>
      </button>
    </div>



    <div class="m-top" v-for="project in projects" :key="project.id">
      <div @click="toggleExpand(project.id)" class="card--progress">
        <h4>{{ project.project_name }}</h4>
        <div class=" prog-bar-container">
          <span v-if="project.progress">
            {{ calculatePercentage(project.progress.totalProgress) }}
          </span>
          <div v-if="project.progress" :style="'width:' + calculatePercentage(project.progress.totalProgress, 'css')"
            class="prog-bar-bar">
          </div>
        </div>
        <div class="progress-icon">
          <IconChevronDown v-if="!show.includes(project.id)" />
          <IconChevronUp v-if="show.includes(project.id)" />

        </div>
      </div>

      <Transition name="fade">
        <div v-if="show.includes(project.id)" class="card--progress-details table-overflowx">
          <table>
            <thead>
              <tr>
                <th class="th-md">Task</th>
                <th class="progress-text-center">Fremgang</th>
              </tr>
            </thead>
            <tbody>

              <tr v-for="task in usedTasks(project.progress)">
                <td>{{ task.label }}</td>
                <td v-if="project.progress">
                  <div class="prog-bar-container">
                    <span>{{ calculatePercentage(project.progress[task.dataName]) }}</span>
                    <div :style="'width:' + calculatePercentage(project.progress[task.dataName], 'css')"
                      class="prog-bar-bar" v-if="project.progress">

                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Transition>

    </div>
    <div class="m-top">
      <p class="log">For mer om hvordan du bruker fremgang fungerer, se <a href="#/guide/">her</a></p>
    </div>
  </div>
</template>

<script>
import ProjectService from '@/services/Project';
export default {
  created() {
    this.$loader(true)
    this.projectService = new ProjectService();
    this.getProjects()
  },
  data() {
    return {
      projects: null,
      show: [],
      tasks: [
        { dataName: 'linework', label: 'Linjearbeid' },
        { dataName: 'installationwork', label: 'Montørarbeid' },
        { dataName: 'documentation', label: 'Dokumentasjon' },
        // { dataName: 'checklist', label: 'Sjekkliste' },
      ]
    }
  },

  methods: {
    usedTasks(progress) {

      let tasks = JSON.parse(JSON.stringify(this.tasks))
      if (progress.checklist) {
        tasks.push({ dataName: 'checklist', label: 'Sjekkliste' })
      }
      return tasks
    },
    toggleAll() {
      if (this.show.length == this.projects.length) {
        this.show = []
      } else {
        for (let i = 0; i < this.projects.length; i++) {
          this.show.push(this.projects[i].id)
        }

      }
    },
    toggleExpand(projectId) {
      if (this.show.includes(projectId)) {
        let i = this.show.findIndex(x => x == projectId)
        this.show.splice(i, 1)
      } else {
        this.show.push(projectId)
      }
    },
    calculatePercentage(progress, type) {
      if (!progress) {
        return ''
      }
      const sum = progress.yes + progress.no + progress.na
      const finished = progress.yes + progress.na
      if (isNaN(Math.floor(finished / sum * 100))) {
        if (type == 'css') {
          return '0%'
        }
        return "Ingen oppføringer"
      }
      return Math.floor(finished / sum * 100) + "%"
    },
    projectProgress(id) {
      this.projectService
        .getProjectProgress(id)
        .then((res) => {
          const projectIndex = this.projects.findIndex(project => project.id == id)
          this.projects[projectIndex].progress = res
        })
    },
    getProjects() {
      this.projectService
        .getProjects('')
        .then((res) => {
          this.projects = res
        })
        .then((res) => {
          for (let i = 0; i < this.projects.length; i++) {
            this.projectProgress(this.projects[i].id)
          }
          this.$loader(false)
        })
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 170rem;
}

.progress-btm {
  padding-top: 2rem;
}

.card--progress .icon-chevron-down,
.card--progress .icon-chevron-up {
  height: 2.5rem;
  stroke-width: 2;
  margin-left: 1rem;
}

.card--progress {
  grid-template-columns: 1fr 2fr auto;
}

@media (max-width: 700px) {
  .card--progress {
    position: relative;
    grid-template-columns: 6fr 1fr;
    grid-template-rows: 1fr 1fr;

  }

  .prog-bar-container {
    grid-column: 1 / 2;
    grid-row: 2 /3;
  }

  .progress-icon {
    position: absolute;
    top: 50%;
    right: 0;
    translate: 0 -50%;
  }

  .progress-icon .icon-chevron-down,
  .progress-icon .icon-chevron-up {

    margin: 0 1rem;
  }
}
</style>
