import axios from 'axios'

export default class EntryService {
    search(search) {
        return axios.get(`api/v1/search/${search}`).then(res => res.data)
    }
    getEntryLog(templateId, entryId) {
        return axios.get(`/api/v1/log/template/${templateId}/entry/${entryId}`).then(res => res.data)
    }
    getEntriesForTable(projectId, templateId) {
        return axios.get(`/api/v1/project/${projectId}/template/${templateId}`).then(res => res.data)
    }
    patchEntry(data) {
        return axios.patch(`/api/v1/template/${data.template}/entry/${data.entryId}`, data).then(res => res.data)
    }
    deleteEntry(templateId, entryId) {
        return axios.delete(`/api/v1/template/${templateId}/entry/${entryId}`).then(res => res.data)
    }
    getRelations(templateId,entryId){
        return axios.get(`api/v1/relations/template/${templateId}/entry/${entryId}`).then(res => res.data)
    }
    uploadFiles(data, files, formDatae) {
        // const formData = new FormData()
        // formData.append("info", JSON.stringify(data))
        // for (let i = 0; i < files.length; i++) {
        //     formData.append("files", files[i])
        // }
        // console.log(files)
        return axios.post('/api/v1/files/upload-files', formDatae).then(res => res.data)
    }
    getFilesToEntry(templateId, entryId) {
        return axios.get(`/api/v1/files/images/template/${templateId}/entry/${entryId}`).then(res => res.data)
    }
    postComment(projectId, templateId, entryId, comment) {
        return axios.post(`/api/v1/comment/project/${projectId}/template/${templateId}/entry/${entryId}`, comment).then(res => res.data)
    }

    getComments(projectId, templateId, entryId) {
        return axios.get(`/api/v1/comment/project/${projectId}/template/${templateId}/entry/${entryId}`).then(res => res.data)
    } 
    deleteComment(id){
        return axios.delete(`api/v1/comment/${id}`).then(res=>res.data)
    }
    deleteFile(id){
        return axios.delete(`api/v1/file/${id}`).then(res=>res.data)
    }
    createEntry(templateId,data){
        return axios.post(`/api/v1/template/${templateId}/entry`,data).then(res=>res.data)
    }
}


// importTemplateFilledExcel(data,file){
//     const formData = new FormData()
//     formData.append("file", file)
//     formData.append("info",JSON.stringify(data))
//     return axios.post(`/api/v1/template/${data.template_id}/project/${data.project_id}/filled-excel`,formData).then(res=>res.data)
// }

