<template>
  <div class="container" v-if="project">
    <Header v-if="project" :title="project.project_name + ' / Instillinger'" />
    <Transition name="slide">
      <div @click="postChanges()" v-if="changed && hideSave == false" class="popup">
        <span class="btn--icon">
          <IconSave />Lagre
        </span>
      </div>
    </Transition>

    <div class="container--proj m-top">
      <div class="col-proj">
        <div class="card card--proj">
          <h3>Prosjektdetaljer</h3>
          <div class="input">
            <label for="projectname">Prosjektnavn<span class="req">*</span></label>
            <input required id="projectname" v-model="project.project_name" type="text" />
          </div>
          <div class="input">
            <label for="project-area">Område</label>
            <input v-model="project.area" type="text" id="project-area" />
          </div>
          <div v-if="project && users.length > 0" class="input">
            <label for="projectleader">Prosjektleder<span class="req">*</span></label>
            <DropdownMultiple @input="project.project_leader = $event" v-model="project.project_leader"
              :data="project.project_leader" :options="users" :id="'projectleader'" label="full_name" />
          </div>
          <div class="input">
            <label for="employer">Oppdragsgiver</label>
            <input v-model="project.employer" type="text" id="employer" />
          </div>
          <p class="log">Felter med<span class="req">*</span> må være fylles ut</p>
          <div class="password-error" v-if="invalidForm.length > 0">
            <p v-for="error in invalidForm">
              {{ error }}
            </p>
          </div>
        </div>

        <div class="card card--proj">
          <h4>Datoer</h4>
          <div class="input">
            <label for="service_delivery">Tjenesteleveranse</label>
            <input v-model="project.service_delivery" type="date" id="service_delivery" />
          </div>
          <div class="input">
            <label for="technically_finished">Teknisk ferdig</label>
            <input v-model="project.technically_finished" type="date" id="technically_finished" />
          </div>
          <div class="input">
            <label for="duedate">Ferdigstillingsdato</label>
            <input v-model="project.deadline" type="date" id="duedate" />
          </div>

          <div class="fin-arc">
            <div class="input-check proj-toggle">
              <input id="project_finished" v-model="project.project_finished" true-value="1" false-value="0"
                class="input-switch" type="checkbox" />
              <label for="project_finished">Ferdigstill prosjekt</label>
            </div>
            <div class="input-check proj-toggle">
              <input v-model="project.project_archived" true-value="1" false-value="0" class="input-switch"
                type="checkbox" name="" id="project_archived" />
              <label for="project_archived">Arkiver Prosjekt</label>
            </div>
            <div class="input-check proj-toggle">
              <input v-model="project.service_delivered" true-value="1" false-value="0" class="input-switch"
                type="checkbox" name="" id="service_delivered" />
              <label for="service_delivered">Sendt Tjen.lev</label>
            </div>
          </div>
          <p class="log">Skjul prosjektet fra prosjektoversikten ved å velge 'Arkiver Prosjekt' </p>

        </div>

        <div class="card card--proj">
          <h3>Templates</h3>
          <div v-for="template in templates" class="input--checkbox">
            <input :id="'template' + template.id" v-model="project.templates" :value="template.id" type="checkbox" />
            <label :for="'template' + template.id">{{ template.name }}</label>
          </div>
        </div>

        <div class="card card--proj">
          <h3>Sjekklister</h3>
          <div v-for="checklist in checklists" class="input--checkbox">
            <input :id="'checklist' + checklist.id" v-model="project.checklists" :value="checklist.id" type="checkbox" />
            <label :for="'checklist' + checklist.id">{{ checklist.name }}</label>
          </div>
        </div>

        <div class="card card--proj">
          <h3>Eksporter data</h3>
          <div v-for="template in templates" class="input--checkbox">
            <input v-model="templateExport" :value="Number(template.id)" :key="template.id" type="checkbox"
              :id="'eksport' + template.id" />
            <label :for="'eksport' + template.id">{{ template.name }}</label>
          </div>
          <div>
            <button @click="btnExportEntries()" class="btn btn--outline btn--icon">
              <IconDocument />Excel
            </button>
          </div>
        </div>
      </div>

      <div class="col-proj">
        <div class="card card--proj card--proj-usr">
          <h3>Brukertilgang</h3>
          <div class="input--checkbox">
            <input v-model="addAll" @change="addAllUsers()" id="choose-all" type="checkbox">
            <label for="choose-all">Velg alle brukere</label>
          </div>
          <div class="proj-usr-header">
            <p>T</p>
            <p>B</p>
            <p>Bruker</p>
          </div>
          <div class="proj-usr">
            <div v-for="user in sortedUsers(users)" class="proj-usr-input">
              <input :id="'usert' + user.id" :key="user.id" :value="Number(user.id)" type="checkbox"
                v-model="project.team_leaders" />
              <input :id="'user' + user.id" :key="user.id" :value="Number(user.id)" type="checkbox"
                v-model="project.assigned_users" />
              <div>{{ user.full_name }}</div>
            </div>
          </div>
          <p class="log">Velg brukere med <strong> 'B'</strong>, og teamledere med <strong> 'T'</strong></p>

        </div>
      </div>
    </div>

    <div class="m-top">
      <button v-if="this.$role(1)" @click="showDelete = !showDelete" class="btn btn--red btn--icon">
        <IconBin />
        Slett
        prosjekt
      </button>

    </div>
    <Modal title="Er du sikker du vil slette prosjektet?" confirmLabel="Slett prosjekt" cancelLabel="Nei, Avbryt"
      @confirm="confirmation" v-if="showDelete" />

  </div>
</template>

<script>
import UserService from '@/services/User';
import ProjectService from '@/services/Project';
export default {
  created() {
    this.$loader(true)
    this.fetchData();
    this.userService = new UserService();
    this.projectService = new ProjectService();
  },
  data() {
    return {
      showDelete: false,
      invalidForm: [],
      addAll: false,
      projectService: null,
      userService: null,
      copy: null,
      entries: [],
      project: null,
      users: [],
      templateExport: [],
      hideSave: true
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.changes().length) {
      const answer = window.confirm(
        "Vil du virkelig forlate siden? Du har ulagrede endringer!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  computed: {
    changed() {
      if (JSON.stringify(this.project) === JSON.stringify(this.copy)) {
        this.hideSave = false
        return false;
      } else {
        return true;
      }
    },
    templates() {
      return this.$store.getters.templates;
    },
    checklists() {
      return this.$store.getters.checklists;
    },
    checklistsObjects() {
      return this.$store.getters.checklistsObjects;
    },
  },

  methods: {
    confirmation(t) {
      if (t == 'cancel') {
        this.showDelete = false
      }
      if (t == 'confirm') {
        this.$loader(true)
        this.projectService
          .deleteProject(this.$route.params.project)
          .then((res) => {
            this.$loader(false)
            if (res.status == 'success') {
              this.$toast('success', res.message)
              this.$router.push('/')
            } else {
              this.$toast('error', 'En feil har oppstått, kontakt FiberX')
            }

          })
      }
    },
    sortedUsers(users) {
      let direction = 1
      return this.users.sort((a, b) => (a.full_name > b.full_name) ? direction : direction * -1)
    },
    btnExportEntries() {
      this.projectService
        .exportEntries(this.$route.params.project, this.templateExport)
        .then(function (response) {
          let headerLine = response.headers['content-disposition'];
          headerLine = headerLine.split('=')[1];
          let blob = new Blob([response.data], { type: 'application/xlsx' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = headerLine;
          link.click();
        })
    },
    goBack() {
      const project = this.$route.params.project;
      this.$router.push(`/project/${project}`);
    },
    postChanges() {
      this.invalidForm = []
      if (!this.$validateText(this.project.project_name)) {
        this.$toast('error', 'Fyll ut Prosjektnavn')
        this.invalidForm.push('Fyll ut Prosjektnavn')
      }
      if (!this.$validateArray(this.project.project_leader)) {
        this.$toast('error', 'Fyll ut Prosjektleder')
        this.invalidForm.push('Fyll ut Prosjektleder')
      }
      if (this.invalidForm.length > 0) {
        return
      }
      this.hideSave = true
      this.$loader(true)
      let changes = this.changes();
      let data = {
        changes: changes,
      };
      this.projectService
        .patchProject(this.project.id, data)
        .then((data) => {
          this.$toast('success', 'Prosjektet er oppdatert!',)
          this.fetchData()
          this.$loader(false)

        })
        .catch((err) => {
          this.$loader(false)
          this.$toast('error', 'Feil', 'Får ikke kontakt med server')
        })
    },
    changes() {
      let entry = this.project;
      let copy = this.copy;
      let changes = [];

      for (let i = 0; i < Object.keys(entry).length; i++) {
        if (
          JSON.stringify(Object.values(entry)[i]) !=
          JSON.stringify(Object.values(copy)[i])
        ) {
          if (Object.keys(entry)[i] == 'idk') { //WTF er dette....???????? Endret fra 'deadline' til alltid false, dette er feil....
            changes.push({ //yyyy i MomentJS virker, yyyy i DayJS virker ikke? Ikke relevant lenger, bare brukt til deadline før. Rydd bort!
              [Object.keys(entry)[i]]: this.$dayjs(Object.values(entry)[i]).format(
                'yyyy.MM.DD'
              ),
            });
          } else {
            changes.push({ [Object.keys(entry)[i]]: Object.values(entry)[i] });
          }
        }
      }
      return changes;
    },
    addAllUsers(e) {
      if (this.addAll == true) {
        this.project.assigned_users = []
        for (let i = 0; i < this.users.length; i++) {
          this.project.assigned_users.push(this.users[i].id)
        }
      }
      if (this.addAll == false) {
        this.project.assigned_users = []
      }
    },
    alterData() {
      this.project.assigned_users = this.project.assigned_users.toString()
        .split`,`.map((x) => +x);
      this.project.team_leaders = this.project.team_leaders.toString()
        .split`,`.map((x) => +x);
      this.project.project_leader = this.project.project_leader.toString()
        .split`,`.map((x) => +x);
      if (this.project.deadline) {
        this.project.deadline = this.$dayjs(this.project.deadline).format('YYYY-MM-DD');
      }
      if (this.project.technically_finished) {
        this.project.technically_finished = this.$dayjs(this.project.technically_finished).format('YYYY-MM-DD');
      }
      if (this.project.service_delivery) {
        this.project.service_delivery = this.$dayjs(this.project.service_delivery).format('YYYY-MM-DD');
      }
      this.copy = JSON.parse(JSON.stringify(this.project));
    },
    async fetchData() {
      const project = this.$route.params.project;
      await this.$http
        .get(`/getproject/${project}`)
        .then((res) => {
          this.project = res.data
          this.$loader(false)

        })
        .catch((err) => {
          this.$loader(false)
          this.$toast('error', 'Feil', 'Får ikke kontakt med server')
        })

      this.project.templates = this.project.templates.toString().split`,`.map(
        (x) => +x
      );
      this.project.checklists = this.project.checklists.toString().split`,`.map(
        (x) => +x
      );
      if (this.project.checklists_objects) {
        this.project.checklists_objects =
          this.project.checklists_objects.toString().split`,`.map((x) => +x);
        if (this.project.checklists_objects[0] === 0) {
          this.project.checklists_objects.splice(0, 1);
        }
      }

      if (this.project.templates[0] === 0) {
        this.project.templates.splice(0, 1);
      }
      if (this.project.checklists[0] === 0) {
        this.project.checklists.splice(0, 1);
      }

      await this.userService
        .getAllUsers()
        .then((data) => (this.users = data))
        .then(this.alterData());
    },
  },
};
</script>

<style scoped>
.svg-icon {
  /* height: 2.4rem; */
}
</style>
