<template>
  <div class="input">
    <label for="link-field">{{ label }}</label>
    <div v-if="this.edit">
      <input id="link-field" type="text" autocapitalize="none" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
        :disabled="isEnabled"  />
    </div>
    <div class="no-edit" v-else>
      <div v-if="options[0] == 'url'">
        <a class="url" target="_blank" :href="data">{{ data }}</a>
      </div>
      <div v-if="options[0] == 'phone'">
        <a class="phone" target="_blank" :href="'tel:' + data">{{ data }}</a>
      </div>
      <div v-if="options[0] == 'mail'">
        <a class="email" target="_blank" :href="'mailto:' + data">{{ data }}</a>
      </div>
    </div>
    <div v-if="log" class="log">
      {{ log.name }}
      {{ log.date }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit('input', this.content);
    },
  },
  computed: {
    isEnabled() {
      if (this.type == 'dyamic') {
        return false;
      }
      if (this.type == 'static' && this.edit == false) {
        return true;
      } else {
        return false;
      }
    },
  },
  emits:['update:modelValue'],
  props: ['modelValue','value', 'label', 'data', 'log', 'type', 'edit', 'options'],
};
</script>

<style scoped>
input {
  width: 100%;
}

.email,
.phone,
.url .email:visited,
.phone:visited,
.url:visited {
  color: var(--main-color);
  font-size: 1.8rem;
  font-family: inherit;
}

.email:hover,
.phone:hover,
.url:hover,
.email:active,
.phone:hover,
.url:hover {
  text-decoration: none;
}

.no-edit {
  margin: 0.5rem;
}
</style>
