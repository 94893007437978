import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
    state() {
        return {
            templates: null,
            customSort:[],
            currentSort:[],
            materials: null,
            checklists: null,
            ticketStatus2: [
                { id: 1, name: 'Åpen', type: 1 },
                { id: 2, name: 'Pågår', type: 1 },
                { id: 3, name: 'Avventer', type: 1 },
                { id: 4, name: 'Lukket', type: 1 },
                { id: 5, name: 'ANU', type: 2 },
                { id: 7, name: 'AVK', type: 2 },
                { id: 6, name: 'AVC', type: 2 },
                { id: 8, name: 'AVS', type: 2 },
                { id: 9, name: 'FIK', type: 2 },
                { id: 10, name: 'UNU', type: 2 },
                { id: 11, name: 'UTF', type: 2 },

            ],
            ticketType: [
                { id: 1, name: 'Spørsmål' },
                { id: 2, name: 'Graving gjenstår' },
                { id: 3, name: 'Utbygging mangler' },
                { id: 4, name: 'Endring' },
                { id: 5, name: 'Ny oppgave' },
                { id: 6, name: 'Kundeklage' },
                { id: 7, name: 'KOA' },
                { id: 8, name: 'Feilretting' },
            ],
        }
    },
    mutations,
    actions,
    getters
};