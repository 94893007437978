<template>
  <div class="container">
    <div v-if="showMain" class="login-box box1">
      <img :src="imageUrl()" />
      <div class="input">
        <label for="username">E-post</label>
        <input placeholder="epost@firma.no" v-model="userData.email" id="username" type="email" autocomplete="username" />
      </div>
      <div class="input">
        <label for="password">Passord</label>
        <input v-on:keyup.enter="submitLogin" placeholder="Passord" v-model="userData.password" id="password"
          type="password" autocomplete="current-password" />
      </div>
      <div class="login-btn-container">
        <button @click="submitLogin" class="btn btn--full btn--login btn--icon">
          <IconLogIn />
          Logg inn
        </button>
        <button @click="resetPassword()" class="forg-pass" href="">Glemt passord?</button>
        <div class="password-error" v-if="invalid.length > 0">
          <p v-for="error in invalid">
            {{ error }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="showResetpassword" class="login-box box2">
      <p>Skriv inn e-post nedenfor, så sender vi deg en link for å tilbakestille passordet ditt</p>
      <div class="input">
        <input type="email" v-model="forgottenEmail" placeholder="epost@domain.com" autocomplete="username">
      </div>
      <div class="flex-row-1">
        <button @click="sendReset()" class="btn btn--full btn--icon">
          <IconSend />Send e-post
        </button>
        <button @click="this.showResetpassword = false; this.showMain = true;" class="btn btn--full btn--icon ">
          <IconClose />Avbryt
        </button>
        <div class="password-error" v-if="invalid.length > 0">
          <p v-for="error in invalid">
            {{ error }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="passwordSent" class="login-box box3">
      <h4>E-post sendt</h4>
      <p>Om du ikke ser e-posten, sjekk søppelpostkassen.</p>
      <button @click="this.passwordSent = false; this.showMain = true; this.userData.password = ''"
        class="btn btn--full">Fortsett</button>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/User';
import { mapGetters } from 'vuex';
export default {
  mounted() {
    this.userService = new UserService
  },
  data() {
    return {
      userService: null,
      formValid: true,
      showResetpassword: false,
      passwordSent: false,
      invalid: [],
      showMain: true,
      forgottenEmail: null,
      userData: {
        // email: 'tobias@laaneits.no',
        email: '',
        // password: '123qweQWE!',
        password: '',
      },
      isLoading: false,
      error: null,
    };
  },
  computed: {
    ...mapGetters(['allstate', 'isAuthenticated']),
  },
  methods: {
    resetPassword() {
      this.invalid = []
      this.showResetpassword = true
      this.showMain = false
    },
    sendReset() {
      this.invalid = []
      if (!this.$validateEmail(this.forgottenEmail)) {
        this.invalid.push('Ugyldig e-post')
      }
      if (this.invalid.length > 0) {
        return
      }
      let user = { email: this.forgottenEmail }
      this.userService
        .forgottenPassword(user)
        .then((res) => {
          this.$toast(res.status, res.message)
        })
      this.forgottenEmail = ""
      this.showResetpassword = false
      this.passwordSent = true
    },
    imageUrl() {
      let images = require.context('../assets/', false, /\.png$/)
      let darkmode = window.matchMedia('(prefers-color-scheme: dark)').matches
      if (darkmode) {
        return images('./Darkmode500.png')
      } else {
        return images('./Lightmode500.png')
      }
    },
    async submitLogin() {
      this.$loader(true)
      this.invalid = [],
        this.formValid = true;
      if (this.userData.password.length < 3) {
        this.invalid.push("Fyll ut passord, minst 8 tegn")
        // this.$toast('error', 'Fyll ut passord, minst 8 tegn')
        this.$loader(false)
        return
      }
      let userData = this.userData;
      if (userData.email === '') {
        this.formValid = false;
        return;
      }
      //Send http
      this.isLoading = true;

      try {
        await this.$store.dispatch('login', userData)
          .then(res => {
          })
        await this.$store.dispatch('getData');
        await this.$store.dispatch('getUserPreferences')
        this.$router.push('/');
      } catch (err) {
        this.invalid.push(err.message)
      }

      this.$loader(false)
    },
  },
};
</script>

<style scoped>

.container {
  display: flex;
  justify-content: center;
  background:
    linear-gradient(0deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(253, 187, 45, 1) 100%);
}

.login-box {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
  border-radius: 12px;
  background: rgba(100%, 100%, 100%, 0.1);
}

.box1 {
  width: 50rem;
  height: 40rem;
}

img {
  width: 12rem;
}

.input {
  width: 75%;
}

.login-btn-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  width: 75%;
}

.btn--login {
  width: 100%;
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
}

.btn--icon {
  justify-content: center;
}

.forg-pass {
  color: var(--text-color);
  cursor: pointer;
  box-shadow: none;
  background: none;
  border: none;
  transition: all 300ms ease;
  border-radius: 9px;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
}

.forg-pass:hover {
  text-decoration: underline;
}

.forg-pass:active {
  scale: 0.95;
}

.password-error {
  padding: 1rem 2rem;
  border-radius: 9px;
  background: rgba(255, 255, 255, 0.400);
}

.box2 {
  width: 60rem;
  height: 23rem;
}

.box2 button {
  white-space: nowrap;
}

.box2 p {
  text-align: center;
  font-weight: 400;
}

.box3 {
  width: 60rem;
  height: 20rem;
}

.box3 p {
  text-align: center;
}


/* @media only screen and (hover: none) and (orientation: landscape) and (max-height:700px) { */
@media only screen and (orientation: landscape) and (max-height:700px) {
  .login-box {
    scale: 0.8;
    margin-top: -3rem;
  }
}

@media (max-width: 650px) {

  h4 {
    font-size: 1.8rem;
  }

}

@media (max-width:440px) {
  .box2 .input {
    width: 90%;
  }
}

@media (max-width:365px) {
  .password-error p {
    font-size: 1.3rem;
  }
}

@media (prefers-color-scheme: dark) {
  .container--login {
    background: linear-gradient(rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)),
      linear-gradient(0deg,
        rgba(34, 193, 195, 1) 0%,
        rgba(253, 187, 45, 1) 100%);
  }

  .password-error {
    background: rgba(0, 0, 0, 0.1);
  }

  .input input {
    background-color: #e8e8e8;
    color: #444;
    border: none;
  }

}
</style>
