<template>
  <div v-if="entry && copy">
    <div class="container">
      <Header :title="templateName + formatName()" />
      <Transition name="slide">
        <div @click="postChanges()" v-if="(changes().length && hideSave == false) || materialChanges" class="popup">
          <span class="btn--icon">
            <IconSave /> Lagre
          </span>
        </div>
      </Transition>

      <div class="tab-scroll">
        <div class="tab-box">
          <button :class="{ 'tabs-active': tab == 0 }" class="btn--tabs" @click="changeTab(0)">
            Oppføring
          </button>
          <button :class="{ 'tabs-active': tab == 1 }" class="btn--tabs" @click="changeTab(1)">
            Filer
          </button>
          <button v-if="templateId != 3" :class="{ 'tabs-active': tab == 2 }" class="btn--tabs" @click="changeTab(2)">
            Materiell
          </button>
          <button :class="{ 'tabs-active': tab == 3 }" class="btn--tabs" @click="changeTab(3)">
            Oppgaver
          </button>
          <button :class="{ 'tabs-active': tab == 4 }" class="btn--tabs" @click="changeTab(4)">
            Logg
          </button>
          <button :class="{ 'tabs-active': tab == 5 }" class="btn--tabs" @click="changeTab(5)">
            Kommentarer
          </button>
        </div>
      </div>

      <div v-if="tab == 0">
        <div class="entry-top">
          <div class="pri-exp">
            <div class="input-check">
              <input v-model="entry.prioritert" true-value="1" false-value="0" type="checkbox" id="priority"
                class="input-switch" />
              <label for="priority">Prioritert</label>
            </div>
            <div class="input-check">
              <input v-model="entry.utgaar" true-value="1" false-value="0" type="checkbox" id="expire"
                class="input-switch" />
              <label for="expire">Utgår</label>
            </div>
          </div>
          <div class="change-edit">
            <button v-if="tab == 0 && !edit" @click="edit = !edit" class="btn btn--outline btn--icon">
              <IconEdit />
            </button>
          </div>
        </div>

        <div v-for=" box  in  boxes " :key="box">
          <div class="card grid--entry-box">
            <div v-for=" field  in  fieldsBox(box) " :key="field.id">
              <!-- {{entry[field.dataName]}} -->
              <component :is="field.component" @update:modelValue="newValue => entry[field.dataName] = newValue"
                :modelValue="entry[field.dataName]" :data="entry[field.dataName]" :label="field.label"
                :options="field.options" :edit="edit" :type="field.type" :log="logExist(field.dataName)">
              </component>

            </div>
          </div>
          <br />
        </div>

        <div v-if="relations && relations.length > 0">
          <div class="card">
            <div v-if="$route.params.template == 1">
              <h4>Relaterte kunder</h4>
              <div class="ent-related" v-for=" customer  in  relations ">
                <div class="flex-col-1">
                  <p>{{ customer.navn }}</p>
                  <p> {{ customer.adresse }}</p>
                </div>
                <button @click="this.$router.push(`/template/8/entry/${customer.customer_id}`)"
                  class="btn btn--outline btn--icon">
                  <IconChevronRight class="go-to-icon" />
                  Gå til
                </button>
              </div>
            </div>

            <div v-if="$route.params.template == 8">
              <h4>Punkt</h4>
              <div class="ent-related" v-for=" punkt  in  relations ">
                <p>{{ punkt.punkt_navn }}</p>
                <button @click="this.$router.push(`/template/1/entry/${punkt.point_id}`)"
                  class="btn btn--outline btn--icon">
                  <IconChevronRight class="go-to-icon" />
                  Gå til
                </button>
              </div>
            </div>
          </div>
          <br>
        </div>

        <div v-if="entry.status != 4 && templateId != 3" class=" card">
          <Materials ref="matrComponent" @changes="materialChanges = true" :disableSpinner="true" :entry="entry"
            :showAll="false" />
        </div>
      </div>

      <div v-if="tab == 1">
        <Files type="entry" :entry="entry" />
      </div>

      <div v-if="tab == 2">
        <Materials ref="matrComponent" @changes="materialChanges = true" :entry="entry" :showAll="true" />
      </div>

      <div v-if="tab == 3">
        <Tickets :entry="entry" />
      </div>

      <div v-if="tab == 4">
        <Log :log="rawLog" :entry="entry" :fields="fields" />
      </div>
      <div v-if="tab == 5">
        <Comments :projectId="entry.project" />
      </div>
      <div class="m-top">
        <button @click="showDelete = !showDelete" v-if="tab == 0" class="btn btn--red btn--icon">
          <IconBin />
          Slett entry
        </button>
      </div>
    </div>
    <Modal title="Er du sikker du vil slette oppføringen?" confirmLabel="Slett oppføring" cancelLabel="Nei, Avbryt"
      @confirm="confirmation" v-if="showDelete" />
  </div>
</template>

<script>
// import EntryTemplate from '../components/Template/Entries/EntryTemplate.vue';
import Files from '@/components/Template/Tabs/Files.vue';
import Materials from '@/components/Template/Tabs/Materials.vue';
import Log from '@/components/Template/Tabs/Log.vue';
import Comments from '@/components/Template/Tabs/Comments.vue';
import Tickets from '@/components/Template/Tabs/Tickets.vue';
import EntryService from '@/services/Entry';

import CheckboxField from '@/components/DataTypes/CheckboxField.vue';
import TextField from '@/components/DataTypes/TextField.vue';
import NumberField from '@/components/DataTypes/NumberField.vue';
import DropdownField from '@/components/DataTypes/DropdownField.vue';
import LinkField from '@/components/DataTypes/LinkField.vue';
import SimpleCheckboxField from '@/components/DataTypes/SimpleCheckboxField.vue';
import MaterialField from '@/components/DataTypes/MaterialField.vue';
import StatusField from '@/components/DataTypes/StatusField.vue';
import TextBoxField from '@/components/DataTypes/TextBoxField.vue';
export default {

  components: {
    Tickets,
    Materials,
    Log,
    Files,
    Comments,
    TextField,
    CheckboxField,
    DropdownField,
    LinkField,
    NumberField,
    SimpleCheckboxField,
    MaterialField,
    StatusField,
    TextBoxField,
  },
  created() {
    this.$loader(true)
    this.entry = null;
    // this.fetchEntry();
    this.entryService = new EntryService();
    // this.copy = JSON.parse(JSON.stringify(this.entry));
    // this.fetchData();
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.preventNav);
  },
  mounted() {
    this.entry = null;
    this.fetchEntry();
    this.fetchRelations();
  },
  data() {
    return {
      materialChanges: false,
      relations: null,
      showDelete: false,
      loading: true,
      entry: null,
      error: false,
      log: null,
      entryService: null,
      copy: null,
      rawLog: null,
      hideSave: false,
      edit: false,
      tmp: null,
      tab: 0,
      // fields:null,
      // boxes:null,
    };
  },
  computed: {
    templateId() {
      return this.$route.params.template
    },
    templateName() {
      return this.$store.getters.templates.find(
        (template) => template.id == this.$route.params.template
      ).name;
    },

    boxesOld() {
      return this.$store.getters.findTemplateFields(
        Number(this.$route.params.template)
      ).boxes;
    },
    fieldsOld() {
      return this.$store.getters.findTemplateFields(
        Number(this.$route.params.template)
      ).fields;
    },
    boxes() {
      return this.$store.getters.templateBoxes(
        Number(this.$route.params.template)
      );
    },
    fields() {
      return this.$store.getters.templateFields(
        Number(this.$route.params.template)
      );
    },
  },
  methods: {
    preventNav(event) {
      // if (!this.isEditing) return
      if (this.changes().length) {
        event.preventDefault()
        event.returnValue = ""
      }
    },
    confirmation(t) {
      if (t == 'cancel') {
        this.showDelete = false
      }
      if (t == 'confirm') {
        this.deleteEntry()
      }
    },
    formatName() {
      switch (this.$route.params.template) {
        case '1':
          if (!this.entry.punkt_navn) {
            return '';
          }
          return ` / ${this.entry.punkt_navn}`;
        case '8':
          if (!this.entry.navn) {
            return '';
          }
          return ` / ${this.entry.navn}`;
        case '2':
          if (!this.entry.punkt_fra) {
            return '';
          }
          return ` / ${this.entry.punkt_fra}`;
        default:
          return '';
      }
    },
    checkPri() {
      //Konverterer Pri fra 0/1 til true/false
      let pri = this.entry.prioritert;
      let utg = this.entry.utgaar;
      if (pri == 1 || pri == true) {
        this.entry.prioritert = true;
      } else {
        this.entry.prioritert = false;
      }
      if (utg == 1 || utg == true) {
        this.entry.utgaar = true;
      } else {
        this.entry.utgaar = false;
      }
    },
    changeTab(tab) {
      if (this.materialChanges) {
        const answer = window.confirm(
          "Vil du virkelig bytte fane? Du har ulagrede materiell endringer!"
        )
        if (answer) {
          this.materialChanges = false
          this.tab = tab
        }
      } else {
        this.tab = tab;
      }

    },


    fieldsBox(box) {
      return this.fields.filter((thing) => thing.box === box);
    },
    fetchRelations() {
      const template = this.$route.params.template;
      const id = this.$route.params.entry;
      // return
      this.entryService
        .getRelations(template, id)
        .then((result) => {
          this.$loader(false)
          this.relations = result
        })

      // .catch((error) => {
      //   console.log(error)
      //   let err = error.response.data
      //   this.$toast(err.status, err.title, err.message)
      //   this.$loader(false)
      // })
    },
    fetchData() {
      this.hideSave = false
      this.copy = JSON.parse(JSON.stringify(this.entry));
      const templateId = this.$route.params.template;
      const entryId = this.$route.params.entry;
      this.entryService
        .getEntryLog(templateId, entryId)
        .then((data) => this.logFunction(data));
      // .then((data)=>this.checkPri())
      // .then((data) => (this.log = data));

      // this.logFunction(this.log)
    },
    updateMyValue(newValue) {
      this.myvalue = newValue;
    },
    deleteEntry() {
      this.$loader(true)
      this.entryService
        .deleteEntry(this.$route.params.template, this.entry.id)
        .then((res) => {
          if (res.status == "success") {
            this.$loader(false)
            this.$toast(res.status, res.message)
            this.$router.push(`/project/${this.entry.project}/template/${this.$route.params.template}`)
          }
        })
      // this.goBack()
    },
    postChanges() {
      this.$loader(true)
      this.hideSave = true
      let changes = this.changes();

      if (this.materialChanges == true) {
        this.materialChanges = false
        this.updateMaterials = true
        this.$refs.matrComponent.updateMaterials()
        this.hideSave = false
      }

      if (changes.length == 0) {
        this.$loader(false)
        this.hideSave = false
        return
      }

      let data = {
        changes: changes,
        template: this.$route.params.template,
        entryId: this.entry.id,
        project: this.entry.project,
      };
      this.entryService.patchEntry(data).then((data) => {
        if (data.status == 'success') {
          this.$toast('success', 'Endringer lagret!')
          this.$loader(false)
          this.edit = false
          this.fetchEntry()
          this.fetchData();

        } else {
          this.$loader(false)
          this.$toast('error', 'Noe gikk galt!', 'Prøv igjen senere')

        }

      })
      // this.$http.post("/updateentry", data);
    },
    logFunction(log) {
      this.rawLog = log;
      let entryLog = {};
      for (let i = 0; i < log.length; i++) {
        if (!entryLog[log[i].field]) {
          let dato = this.$dayjs(log[i].created_at).format('DD.MMMYY, HH:mm');
          let logObject = {
            action: log[i].action,
            user: log[i].user,
            date: dato,
          };
          entryLog[log[i].field] = logObject;
        }
      }

      this.log = entryLog;
    },
    logExist(field) {
      if (this.log && this.log[field]) {
        return {
          name: this.log[field].user,
          date: this.log[field].date,
        };
      } else {
        return null;
      }
    },
    changes() {
      let entry = this.entry;
      let copy = this.copy;
      let changes = [];

      for (let i = 0; i < Object.keys(entry).length; i++) {
        if (Object.values(entry)[i] != Object.values(copy)[i]) {
          changes.push({
            [Object.keys(entry)[i]]: Object.values(entry)[i],
          });
        }
      }
      return changes;
    },

    fetchEntry() {
      const template = this.$route.params.template;
      const id = this.$route.params.entry;
      this.$http
        .get(`/api/v1/template/${template}/entry/${id}`)
        .then((result) => {
          this.$loader(false)
          this.entry = result.data[0]
        })
        .then((result) => {
          this.fetchData()
        })
        .catch((error) => {
          let err = error.response.data
          this.$toast(err.status, err.title, err.message)
          this.$loader(false)
        })
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.entry) {
      next()
    }
    if (this.changes().length || this.materialChanges) {
      const answer = window.confirm(
        "Vil du virkelig forlate siden? Du har ulagrede endringer!"
      );
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.container {
  max-width: 130rem;
}

.icon-edit {
  stroke-width: 2;
  height: 1.8rem;
}

.entry-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.pri-exp {
  display: flex;
}

.change-edit button {
  padding: 0.6rem 1rem;
}

.grid--entry-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.input {
  max-width: 23rem;
  height: 7rem;
}

.ent-related button {
  gap: 0;
  padding: .6rem 1rem;
}

.go-to-icon {
  height: 2rem;
}

.ent-related {
  display: flex;
  max-width: 50rem;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: 9px;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 900px) {
  .grid--entry-box {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 561px) {
  .grid--entry-box {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 450px) {
  .entry-footer-btns {
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
