<template>
  <div>
    <div v-if="test" class="file-input">
      <input multiple
        @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
        ref="myFiles" type="file" id="file" class="file">
      <label for="file">
        + Velg en fil
        <p class="file-name"></p>
      </label>
    </div>

    <div v-if="files && files.length > 0">
      <h3>Bilder</h3>
      <div class=" gallery">
        <div class=" card" v-for="file in filteredFiles('image')" :key="file.id">
          <div class="file-header">
            <div class="title">
              <p>{{ file.name }}</p>
              <div v-if="this.$canDelete(file.user) || canDeleteFile(file.user_id)">
                <IconBin @click="deleteFile(file.id)" />
              </div>
            </div>
            <p class="log">Lastet opp av {{ file.userName }}, {{ $dayjs(file.created_at).format('DD.MM.YY HH:mm') }}</p>
          </div>
          <div class="img-box">
            <a target="_blank" :href="file.url">
              <img class="img" :src="file.url" alt="">
            </a>
          </div>
        </div>
      </div>
      <div class="m-top">
        <p class="log">Klikk på bildet for å åpne. Hold inne ALT/Option + klikk for å laste ned</p>
      </div>

      <h3>Filer</h3>
      <div class="gallery m-top">
        <div class="card card--file" v-for="file in filteredFiles('document')" :key="file.id">
          <div class="file-header">
            <div class="title">
              <a :href="file.url">
                <p>{{ file.name }}</p>
              </a>
              <div v-if="this.$canDelete(file.user)">
                <IconBin @click="deleteFile(file.id)" />
              </div>
            </div>
            <p class="log">Lastet opp av {{ file.userName }}, {{ $dayjs(file.created_at).format('DD.MM.YY HH:mm') }}</p>
          </div>
        </div>
      </div>
      <div class="m-top">
        <p class="log">Klikk på filen for å åpne. Hold inne ALT/Option + klikk for å laste ned</p>
      </div>
    </div>
  </div>
</template>

<script>

import EntryService from "@/services/Entry";
import ProjectService from '@/services/Project'
import TicketService from '@/services/Ticket'
import Compressor from 'compressorjs'
import axios from 'axios'
export default {
  props: ["entry", "type"],
  emits: ['loaded'],
  created() {
    this.$loader(true)
    this.entryService = new EntryService()
    this.projectService = new ProjectService()
    this.ticketService = new TicketService()
    this.getFiles()
  },
  data() {
    return {
      files: null,
      fileCount: null,
      entryService: null,
      projectService: null,
      ticketService: null,
      test: true,
      form: { file: null }
    };
  },
  methods: {
    canDeleteFile(fileId) {
      let userId = this.$store.getters.userId
      //File ID = Hvem lastet opp ID, sjekk hvis det er deg selv?
      if (userId == fileId) {
        return true
      }
      if (!this.entry) { return false }


      if (this.entry.team_leader.split(',').includes(userId)) {
        return true
      }
      if (this.entry.project_leader.split(',').includes(userId)) {
        return true
      }

      if (userId == fileId) {
        return true
      }
    },
    deleteFile(fileId) {
      if (confirm('Er du sikker?')) {
        this.$loader(true)
        this.entryService
          .deleteFile(fileId)
          .then((res) => {
            this.$toast(res.status, res.message)
            this.getFiles()
            this.$loader(false)
          })
      }
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadWithAxios(url, title) {

    },
    downloadImg(url, title) {
      // axios({
      //   method: 'get',
      //   url,
      //   responseType: 'arraybuffer',
      // })
      //   .then((response) => {
      //     this.forceFileDownload(response, title)
      //   })
      //   .catch(() => console.log('error occured'))

      // let url = window.URL.createObjectURL(new Blob([response.data]))
      // const link = document.createElement('a')
      let files = this.filteredFiles('image')

      let links = {}
      for (let i = 0; i < files.length; i++) {
        // console.log(files[i].url)
        // return
        links['link' + i] = document.createElement('a')
        // link.href = url
        links['link' + i].href = files[i].url
        links['link' + i].setAttribute('download', title)
        document.body.appendChild(links['link' + i])
        // console.log(links)
      }
      links.link0.click()
      links.link1.click()
      // links['link0'].click()
      // links['link1'].click()
      // links['link1'].click()
      // links['link1'].click()
      // return
      // let uri = this.remoteURL();
      // fetch(uri)
      //   .then((response) => response.blob())
      //   .then((blob) => {
      //     saveAs(blob, 'image_name.jpg');
      //   });
      // console.log('downloading', url);
    },
    remoteURL() {
      //return this.BASE_URL + link //link is the image's path fetched from database
      return this.filteredFiles('image')[0].url
    },
    filteredFiles(filetype) {
      // let filtered = this.files[0]
      // let filtered = this.files.filter(image => image.id == "29")
      if (this.files && this.files.length > 0) {
        let filtered = this.files.filter(image => this.fileExtension(image.name) == filetype)
        return filtered

      } else {

        return this.files
      }
    },
    fileExtension(name) {
      let filetype = name.toLowerCase().split('.').pop();
      if (['jpg', 'png', 'gif', 'jpeg'].includes(filetype)) {
        return 'image'
      }
      if (['xlsx', 'pdf', 'xls', 'txt', 'doc', 'docx', 'csv']) {
        return 'document'
      }
      if (filetype == 'xlsx') {
        return 'document'
      }
      if (filetype == 'pdf') {
        return 'document'
      }
      return 'unknown'
    },
    getFiles() {
      if (this.type == "entry") {
        this.entryService
          .getFilesToEntry(this.$route.params.template, this.entry.id)
          .then((res) => {
            (this.files = res)
            this.$loader(false)
            this.$emit('loaded')
          })
      }
      if (this.type == "projectDocuments") {
        this.projectService
          .getFilesToProject(this.$route.params.project_id)
          .then((res) => {
            this.files = res
            this.$loader(false)
            this.$emit('loaded')
          })
      }
      if (this.type == "ticket") {
        if (isNaN(parseInt(this.$route.params.id))) {
          return
        }
        this.ticketService
          .getFilesToTicket(this.$route.params.id)
          .then((res) => {
            this.files = res
            this.$loader(false)
            this.$emit('loaded')
          })
      }

    },
    async compress(file, quality, maxHeight, maxWidth) {
      return await new Promise((resolve, reject) => {
        new Compressor(file, {
          quality,
          maxHeight,
          maxWidth,
          success: resolve,
          error: reject,
        });
      });
    },
    async filesChange(fieldName, fileList) {
      // handle file changes
      this.$loader(true)
      if (!fileList.length) return;
      let data = {}
      if (this.type == 'entry') {
        data = {
          project_id: this.entry.project,
          entry_id: this.entry.id,
          template_id: this.$route.params.template,
          type: 'entry'
        };
      }
      if (this.type == 'projectDocuments') {
        data = {
          project_id: this.$route.params.project_id,
          type: 'project'
        };
      }
      if (this.type == 'ticket') {
        data = {
          ticket_id: this.$route.params.id,
          type: 'ticket'
        };
      }


      let files = [...fileList]

      for (let i = 0; i < files.length; i++) {
        let name = files[i].name
        let nameType = name.split('.')
        nameType = nameType[nameType.length - 1]
        let type = files[i].type.split('/')[1]
        let acceptedTypes = ['xlsx', 'pdf', 'xls', 'txt', 'doc', 'docx', 'csv', 'jpg', 'png', 'gif', 'jpeg', 'vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'vnd.ms-excel', 'msword', 'vnd.openxmlformats-officedocument.wordprocessingml.document']
        if (!acceptedTypes.includes(type)) {
          this.$toast('error', 'Filformat ' + nameType + ' er ikke støttet.', 'Kontakt FiberX hvis du trenger dette. Gjelder fil: ' + name)
          files.splice(i, 1)
        } else {

          // if (/^image\/\w+/.test(fileList[i].type)){
          if (['jpg', 'png', 'jpeg'].includes(type)) {
            const fullImage = await this.compress(files[i], 0.7)
            files[i] = fullImage
          }
          // return

          // files[0].
          files[i] = new File([files[i]], name);
        }

      }

      const formData = new FormData()
      formData.append("info", JSON.stringify(data))
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i])
      }
      // return

      this.entryService.uploadFiles(data, fileList, formData).then((response) => {
        this.$loader(false)
        this.$toast('success', 'Fil er lastet opp.')
        this.getFiles()
      });
    },

    downloadFiles() {
      let files = this.files;
      for (let i = 1; i < files.length; i++) {
        let source = files[i].url;
        fetch(source)
          .then((response) => response.blob())
          .then((blob) => {
            setFetching(false);
            const blobURL = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobURL;
            a.style = "display: none";

            // if (name && name.length) a.download = name;
            document.body.appendChild(a);
            a.click();
          })
          .catch(() => setError(true));
        return;
        //   const fileName = source.split('/').pop();
        // var el = document.createElement("a");
        // el.setAttribute("href", source);
        // el.setAttribute("download", fileName);
        // document.body.appendChild(el);
        // el.click();
        // el.remove();
      }
    },
  },
};
</script>

<style scoped>
.file {
  position: absolute;
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
}

.file-input label {
  position: relative;
  display: block;
  width: 15rem;
  height: 4rem;
  border-radius: 9px;
  margin: 2rem 0;
  box-shadow: 0 0 2px 1px var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  font-weight: 500;
  cursor: pointer;
}

.file-input label:hover {
  box-shadow: 0 0 5px 1px var(--main-color);

}

.file-name {
  position: absolute;
  bottom: -3.5rem;
  left: 1rem;
  font-size: 0.85rem;
  color: var(--text-color);
}

h3 {
  margin: 2rem 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

.gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

.card {
  padding: 1rem;
  width: 100%;
  overflow: hidden;
}

.file-header {
  display: flex;
  flex-direction: column;
  gap: .2rem;
  padding: 1rem;
}

.down-delete-files {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding-bottom: 2rem;
}

.title {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
}

.img {
  max-width: 100%;
  height: auto;
  border-radius: 9px;
  margin: 0 auto;
}

.img-box {
  position: relative;
}

.img-box input {
  position: absolute;
  top: 1rem;
  left: 1rem;
  scale: 1.2;
}

.log {
  margin-bottom: 1rem;
}

.mg-botm {
  margin-bottom: 3rem;
}

.icon-bin {
  height: 2rem;
}

@media (max-width: 1100px) {
  .gallery {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 700px) {
  .gallery {
    grid-template-columns: 1fr;
  }
}
</style>



