let timer
import axios from 'axios'
import { resolveTransitionHooks } from 'vue'
export default {
    async getData(context) {
        console.log("get")
        return context.dispatch('baseData')
    },

    async setCustomSort(context,payload){
        
        // return
        localStorage.setItem('customSort',JSON.stringify(payload))
        // console.log(payload)
        //Push payload..?
        let url = process.env.VUE_APP_BASEURL + "/api/v1/userpreference/customfields/1"
        let data = payload
        axios.post(`/api/v1/userpreference/customfields/1`,data).then(res=>res.data)

        context.commit('setCustomSort',{
            sort:payload
        })
    },

    async setCurrentSort(context,payload){
        
        localStorage.setItem('currentSort',JSON.stringify(payload))
        context.commit('setCurrentSort',{
            sort:payload
        })
    },



    async baseData(context, payload) {
        let url = process.env.VUE_APP_BASEURL + "/api/v1/basedata"
        const response = await fetch(url, {
            method: 'GET',
            headers: { "Content-Type": "application/json" },
        })
        const responseData = await response.json()
        if (!response.ok) {
            const error = new Error(responseData.message || 'Feil med basedata.')
            throw error;
        }

        localStorage.setItem('templateData', JSON.stringify(responseData.templates))
        localStorage.setItem('materialData', JSON.stringify(responseData.material))
        localStorage.setItem('checklistData', JSON.stringify(responseData.checklists))
        context.commit('setBasedata', {
            templates: responseData.templates,
            materials:responseData.material,
            checklists:responseData.checklists
        })


          axios.get(`/api/v1/userpreference/customfields/1`).then(res=>{
            if(!res.data){
                return
            }
            if(!res.data.data){
                return
            }
            // let preference = JSON.parse(res.data.data)
            let preference = res.data.data


            context.commit('setCustomSort',{
                sort:preference
            })


         })
        // if (!responsePref.ok) {
        //     const error = new Error(responsePref.message || 'Feil med userpref.')
        //     throw error;
        // }
        // console.log(responseDataPref)

    }



}