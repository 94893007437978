import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Projects from '../views/Projects.vue'
import UserSettings from '../views/UserSettings.vue'
import AdminSettings from '../views/AdminSettings.vue'
import Login from '../views/Login.vue'
import store from '../store/index.js'
import ProjectDetails from '../components/Project/ProjectDetails.vue'
import TemplateTable from '../views/EntryTable.vue'
import ProjectSettings from '../components/Project/ProjectSettings.vue'
import TemplateEntry from '../views/Entry.vue'
import NewProject from '../components/Project/NewProject.vue'
import ProjectDocuments from '../components/Project/ProjectDocuments.vue'
import ProjectDataManagement from '../components/Project/ProjectDataManagement'
import Ticket from '../components/Tickets/Ticket'
import ProjectTickets from '../views/ProjectTickets'
import ProjectChecklist from '../views/ProjectChecklist'

import Test from '../views/Test'

import Guide from '../views/Guide.vue'
import Tickets from '../views/Tickets.vue'
import Progress from '../views/Progress.vue'
import ProgressDash from '../views/Progress-dash.vue'
import ActivateUser from '../views/ActivateUser'
import ChangeEmail from '../views/ChangeEmail.vue'
import ResetPassword from '../views/ResetPassword.vue'
import Contact from '../views/Contact.vue'
// import CompanySettings from '../views/CompanySettings.vue'
import Materials from '../views/Materials.vue'
import SetupTables from '../views/SetupTables.vue'
import Changelog from '../views/Changelog.vue'
import Production from '../views/Production.vue'

const routes = [
  {
    path: '/admin/tables',
    name: 'Dev Tables',
    component: SetupTables
  },
  {
    path: '/',
    name: 'Projects',
    component: Projects
  },
  {
    path: '/project/checklist/:id',
    name: 'Project Checklist',
    component: ProjectChecklist
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  {
    path: '/tickets',
    name: 'Tickets',
    component: Tickets
  },
  {
    path: '/ticket/:id/:project?/:template?/:entry?',
    name: 'Ticket',
    component: Ticket
  },

  {
    path: '/project/:project/template/:template/datamanagement',
    name: 'DataManagement',
    component: ProjectDataManagement
  },
  {
    path: '/newproject',
    name: 'NewProject',
    component: NewProject
  },

  {
    path: '/template/:template/entry/:entry',
    name: 'Entry',
    component: TemplateEntry
  },

  {
    path: '/usersettings',
    name: 'UserSettings',
    component: UserSettings
  },
  {
    path: '/adminsettings',
    name: 'AdminSettings',
    component: AdminSettings
  },
  {
    path: '/project/:project/template/:template',
    name: 'TemplateTable',
    component: TemplateTable
  },
  {
    path: '/project/:project/tickets',
    name: 'ProjectTickets',
    component: ProjectTickets
  },
  {
    path: '/projectsettings/:project',
    name: 'ProjectSettings',
    component: ProjectSettings
  },
  {
    path: '/progress',
    name: 'Progress',
    component: Progress
  },
  {
    path: '/progress-dash',
    name: 'ProgressDash',
    component: ProgressDash
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: ProjectDetails
  },
  {
    path: '/materials/project/:project_id',
    name: 'Materials',
    component: Materials
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/activateuser',
    name: 'Activate User',
    component: ActivateUser
  },
  {
    path: '/project/documents/:project_id',
    name: 'ProjectDocuments',
    component: ProjectDocuments
  },
  {
    path: '/changeemail',
    name: 'Change Email',
    component: ChangeEmail
  },
  {
    path: '/resetpassword',
    name: 'Reset Password',
    component: ResetPassword
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/guide',
    name: 'Guide',
    component: Guide
  },
  {
    path: '/changelog/:changelog_id',
    name: 'Changelog',
    component: Changelog
  },
  {
    path:'/production',
    name: 'Production',
    component:Production
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})
// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes,
// })

router.beforeEach(function (to, from, next) {
  const isAuthenticated = store.getters.isAuthenticated
  const loggedOut = ['Activate User', 'Login', 'Reset Password']
  const adminRoutes = ['AdminSettings']
  let access = true
  if (to.name == 'Login' && isAuthenticated == false) {
    next()
    return
  }

  if (loggedOut.includes(to.name) && isAuthenticated) {
    access = false
  }

  if (loggedOut.includes(to.name) && !isAuthenticated) {
    next()
    return
  }

  if (isAuthenticated && access) {
    next()
    return
  }
  if (isAuthenticated && !access) {
    next({
      name: 'Projects'
    })
    return
  }

  if (to.name == 'Login' && isAuthenticated == true) {
    next({
      name: 'Projects'
    })
    return
  }

  next({
    name: 'Login'
  })
  // if(isAuthenticated && to.name!='Login'){
  //   next({
  //     name:'Login'
  //   })
  // } else {
  //   next({
  //     name:'Login'
  //   })
  // }


  // if(to.path!="/login" && !store.getters.isAuthenticated){
  //   next('/login')

  // }

  // if(authenticated && (to.name='Login')){
  //   next({name:'Projects'})
  // }

  // if(!authenticated){
  //   next({name:'Login'})
  // }
  // if(isAuthenticated) next()
  // if(!isAuthenticated)next({name:'Login'}) 
  // else next()
  // if (to.name == 'Activate User' && !isAuthenticated) next({ name: 'Activate User' })
  // if (to.name == 'Login' && isAuthenticated) next({ name: 'Projects' })
  // if (to.name == 'Login' && isAuthenticated) next({ name: 'ActivaeUser' })
  // else next()

  // if (to.name == 'Login' && store.getters.isAuthenticated) next({ name: 'Projects' })
  // if (to.name !== 'Login' && !store.getters.isAuthenticated) next({ name: 'Login' })
  // else next()
  // next()
})

export default router
