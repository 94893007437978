import axios from 'axios'
// const axios = this.$http

export default class ProjectService{
    getTemplateBlankExcel(template_id){
        // return axios.get(`/api/v1/template/${template_id}/blank-excel`).then(res=>res.data)
        return axios({
            url:`/api/v1/template/${template_id}/blank-excel`,
            method:"GET",
            responseType:"blob"
        })
    }
    exportEntries(project,templates){
        return axios({
            url:`/api/v1/export/entries/project/${project}?templates=${templates}`,
            method:"GET",
            responseType:"blob"
        })
    }
    getFilesToProject(projectId){
        return axios.get(`/api/v1/files/documents/project/${projectId}`).then(res=>res.data)
    }
    getProjectProgress(projectId){
        // projectId=40
        return axios.get(`api/v1/progress/project/${projectId}`).then(res=>res.data)
    }
    getProjects(filter){
        return axios.get(`api/v1/projects${filter}`).then(res=>res.data)
    }
    deleteProject(project){
        return axios.delete(`api/v1/project/${project}`).then(res=>res.data)
    }
    getChecklists(project,checklist){
        return axios.get(`/api/v1/project/${project}/checklists?`).then(res=>res.data)
    }
    updateChecklists(data){
        return axios.patch(`/api/v1/checklist/`,data).then(res=>res.data)
    }
    getChecklistLog(project){
        return axios.get(`/api/v1/checklist/project/${project}/log`)
    }
    importTemplateFilledExcel(data,file){
        const formData = new FormData()
		formData.append("file", file)
		formData.append("info",JSON.stringify(data))
        return axios.post(`/api/v1/template/${data.template_id}/project/${data.project_id}/filled-excel`,formData).then(res=>res.data)
    }
    importTemplateTelenor(data,file){
        const formData = new FormData()
		formData.append("file", file)
		formData.append("info",JSON.stringify(data))
        return axios.post(`/api/v1/template/${data.template_id}/project/${data.project_id}/telenor`,formData).then(res=>res.data)
    }

    getProjectProgress(project_id){
        return axios.get(`/api/v1/progress/project/${project_id}`).then(res=>res.data)
    }
    getProjectTemplateProgress(project_id,template_id){
        return axios.get(`/api/v1/progress/project/${project_id}/template/${template_id}`).then(res=>res.data)
    }
    getProject(projectId){
        return axios.get(`/api/v1/project/${projectId}`).then(res=>res.data)
    }
    getChecklistToProject(checklist_id, project_id){
        return axios.get(`/api/v1/checklist/${checklist_id}/project/${project_id}`)
    }
    patchProject(project,data){
        return axios.patch(`/api/v1/admin/project/${project}`,data).then(res=>res.data)
    }
    getChecklistObjectsToProject(checklist_type, project_id){
        return axios.get(`/api/v1/checklist-objects/${checklist_type}/project/${project_id}`).then(res=>res.data)
    }
    createChecklistObject(checklist_type,project_id,data){
        return axios.post(`/api/v1/checklist-object/type/${checklist_type}/project/${project_id}`,data).then(res=>res.data)
    }
    deleteChecklistObject(checklist_id){
        return axios.delete(`/api/v1/checklist-object/${checklist_id}`).then(res=>res.data)
    }
    updateChecklistObject(checklist_id,data){
        return axios.patch(`/api/v1/checklist-object/${checklist_id}`,data).then(res=>res.data)
    }
    createProject(project){
        return axios.post('/api/v1/project',project).then(res=>res.data)
    }
    getImports(template,project){
        return axios.get(`/api/v1/imports/template/${template}/project/${project}`).then(res=>res.data)
    }
    deleteImport(importId,template,project){
        return axios.delete(`/api/v1/import/${importId}/template/${template}/project/${project}`).then(res=>res.data)
    }
}