<template>
  <div v-if="project" class="container">

    <Header :title="headerTitle" />

    <div v-show="true" class="card">
      <h3>Legg til enkelt oppføring</h3>
      <NewEntry />
    </div>

    <div class="exp-imp-flex">
      <div class="card card--exp-imp">
        <h4>Exporter til Excel</h4>
        <button @click="exporterEntries()" class="btn btn--outline btn--icon">
          <IconDocument />Eksporter
        </button>
        <p class="log">Exporter alle oppføringene fra {{ headerTitle }}</p>
      </div>

      <div class="card card--exp-imp">
        <h4>Last ned Excel Template</h4>
        <button @click="downloadExcelTemplate()" class="btn btn--outline btn--icon">
          <IconDownload />Last ned
        </button>
        <p class="log">Last ned Excel template, for så å importere oppføringer</p>
      </div>

      <div class="card card--exp-imp">
        <h4>Importer fra Excel</h4>
        <div class="file-input">
          <input @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
            ref="myFiles" type="file" id="file" class="file">
          <label for="file">
            <IconUpload />Velg en fil
          </label>
        </div>
        <p class="log">Importer fra Excel Template, Telenor eller Kartserver</p>
      </div>
    </div>

    <div class="card">
      <h4>Tidligere importer</h4>
      <div v-for="entryImport in imports" class="imported-row">
        <div class="imported-info">
          <p>Importerte {{ entryImport.entries_imported || 0 }}/{{ entryImport.entries_imported +
            entryImport.entries_skipped
          }}</p>
          <p> av {{ entryImport.user }}</p>
          <p>{{ $dayjs(entryImport.date_created).format('DD.MM.YY HH:mm') }}
          </p>
        </div>
        <button @click="deleteImport(entryImport.id)" class="btn btn--red delete-imported btn--icon">
          <IconBin />Slett import
        </button>
      </div>
      <p class="log">Om du velger å slette en import, slettes alle oppføringer og eventuelle utførte punkter fra den
        importen</p>
    </div>
  </div>
</template>

<script>
import EntryService from '@/services/Entry';
import ProjectService from '@/services/Project';
import NewEntry from '@/components/UI/NewEntry.vue';
export default {
  components: {
    NewEntry
  },
  created() {
    this.$loader(true)
    this.entryService = new EntryService();
    this.projectService = new ProjectService();
    this.fetchData();
  },
  data() {
    return {
      data: null,
      entries: [],
      imports: null,
      project: null,
      entryService: null,

      userService: null,
      projectService: null,
      exportChoice: null,
      counter: {},
      importChoice: 'nxt',
    };
  },
  computed: {

    columns() {
      return this.$store.getters.findTemplateColumns(
        Number(this.$route.params.template)
      ).columns;
    },
    headerTitle() {
      return `${this.project.project_name} / ${this.templateName}`;
    },
    templateName() {
      return this.$store.getters.templates.find(
        (template) => template.id == this.$route.params.template
      ).name;
    },
  },
  methods: {

    deleteImport(id) {
      let confirmation = prompt("Skriv 'Slett Import' for å bekrefte.", "");
      if (confirmation == null || confirmation == "") {
        return
      }
      if (!(confirmation.toLowerCase() == "slett import")) {
        this.$toast('warning', 'Import ikke slettet')
        return
      }
      this.$loader(true)

      this.projectService
        .deleteImport(id, this.$route.params.template, this.project.id)
        .then((res) => {
          this.$loader(false)
          this.$toast(res.status, res.message)
          this.fetchData()
        })
    },
    filesChange(fieldName, fileList) {
      this.$loader(true)
      if (!fileList.length) {
        this.$loader(false); return
      }

      // if (this.importChoice == 'nxt') {
      let data = {
        project_id: this.project.id,
        template_id: this.$route.params.template,
      };
      this.projectService
        .importTemplateFilledExcel(data, fileList[0])
        .then((res) => {
          this.$loader(false)
          this.$toast(res.status, res.title, res.message)
          this.fetchData()
        })
      // }
      if (this.importChoice == 'telenor') {
        let data = {
          project_id: this.project.id,
          template_id: this.$route.params.template,
        };
        this.projectService
          .importTemplateTelenor(data, file)
          .then(function (response) {
            return response;
          })
          .then((data) => {
          })
          .catch((error) => {
          });
      }
    },
    exporterEntries() {
      this.projectService
        .exportEntries(this.project.id, this.$route.params.template)
        .then(function (response) {
          let headerLine = response.headers['content-disposition'];
          headerLine = headerLine.split('=')[1];
          let blob = new Blob([response.data], { type: 'application/xlsx' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = headerLine;
          link.click();
        })
    },
    downloadExcelTemplate() {
      this.projectService
        .getTemplateBlankExcel(this.$route.params.template)
        .then(function (response) {
          let headerLine = response.headers['content-disposition'];
          headerLine = headerLine.split('=')[1];
          let blob = new Blob([response.data], { type: 'application/xlsx' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = headerLine;
          link.click();
        });
      return;
      this.projectService
        .getTemplateBlankExcel(this.$route.params.template)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'file.xlsx');
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    goBack() {
      const project = this.$route.params.project;
      const template = this.$route.params.template;
      this.$router.push(`/project/${project}/template/${template}`);
    },
    async fetchData() {
      const project = this.$route.params.project;
      const template = this.$route.params.template;
      await this.entryService
        .getEntriesForTable(project, template)
        .then((data) => (this.entries = data));
      await this.projectService
        .getProject(project)
        .then((data) => {
          this.project = data[0]
          this.$loader(false)
        });
      await this.projectService
        .getImports(template, project)
        .then((res) => {
          this.imports = res.data
        })
    },
  },
};
</script>

<style scoped>
.card {
  gap: 3rem;
}

.exp-imp-flex {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.container {
  max-width: 110rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.card--exp-imp {
  width: 100%;
  height: 15rem;
  gap: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.card--exp-imp h4 {
  text-align: center;
}

.card--exp-imp button {
  justify-content: center;
  width: 20rem;
  height: 4rem;
  font-size: 1.8rem;
}

.imported-row {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  border-bottom: 1px solid #999;
  padding: 2rem;
}

.imported-info {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.delete-imported {
  position: absolute;
  top: 50%;
  right: 1rem;
  translate: 0 -50%;
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.file-input label {
  display: block;
  position: relative;
  width: 20rem;
  height: 4rem;
  font-size: 1.8rem;
  /* padding: 1rem 2rem; */
  /* height: 5rem; */
  border-radius: 9px;
  box-shadow: inset 0 0 0 2px var(--main-color);
  color: var(--main-color);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 200ms ease-out;
}

.file-name {
  position: absolute;
  bottom: -3.5rem;
  left: 1rem;
  font-size: 0.85rem;
  color: var(--text-color);
}

.add-entry {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
}

.add-entry :first-child {
  margin-right: 1rem;
}

.svg-icon {
  height: 2rem;
}



@media (max-width: 1100px) {
  .exp-imp-flex {
    flex-direction: column;
  }

}

@media (max-width: 810px) {
  .imported-info {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
