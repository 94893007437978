<template>
  <div ref="notDiv" class="notif-nav">


    <div class=" notif-top">
      <h1>Varsler</h1>
      <div @click="toggleNot" class="close-mobile">
        <IconClose />
      </div>
    </div>

    <div class="no-notif-box" v-if="notifications.length == 0">
      <h2 class="no-notif">Ingen varsler! </h2>
    </div>

    <div v-for="notification in notifications" class="notif-box">
      <div @click="nav(notification)" class="notif-content">
        <p class="title">{{ notification.title }}</p>
        <p>{{ notification.message }}</p>
        <p class="log">{{ notification.date_created }}</p>
      </div>
      <IconBin @click="deleteNotification(notification)" />
    </div>


    <div class="notif-btm" v-if="notifications.length > 0">
      <button @click="clearAll()" class="btn btn--red btn--icon">
        <IconBin class="icon-bin" />
        Fjern alle varsler
      </button>
    </div>


    <!-- <div class="notif-box">
      <div @click="nav(notification.url)" class="notif-content">
        <p class="title">Test Knapp</p>
        <p>Denne er en du må tykke på</p>
        <p class="log">12.12.23 12:00</p>
        <IconBin />
      </div>
      <div class="notif-btn">
        <button class="btn btn--plus">Godta</button>
        <button class="btn btn--red">Avvis</button>
      </div>
    </div> -->
  </div>
</template>

<script>

import NotificationService from '@/services/Notification';

window.onfocus = function () { document.title = "FiberX"; }


export default {
  props: ['showNot', 'close'],
  emits: ['unreadNotification', 'close'],
  data() {
    return {
      notificationService: null,
      notifications: [],
      toggleTitle: false
    }
  },
  mounted() {
    this.NotificationService = new NotificationService();
    this.getNotifications()
    setInterval(this.checkConnection, 4000);
    this.connectWebsocket()


    this.connection.onopen = ((ev) => {
      this.setWebsocket()
    })
    // while(!this.connection.ISREADY){

    // }
  },
  methods: {
    clearAll() {
      this.NotificationService.clearAll()
        .then((data) => {
          this.notifications = []
          this.$emit("unreadNotification", this.notifications.length)
          document.documentElement.classList.remove('stop-scrolling')
          document.title = 'FiberX'
          this.$emit("close")
        })
    },
    connectWebsocket() {
      console.log(process.env.VUE_APP_BASEURL)
      this.connection = new WebSocket(process.env.VUE_APP_SOCKETURL)
      // this.connection = new WebSocket("ws://192.168.50.10:3000/?a=22")
      this.connection.onmessage = ((ev) => {
        let message = ev.data
        if (message == "newNotification") {
          this.getNotifications()
        }
      })
    },
    checkConnection() {
      if (this.connection.readyState != 1) {
        // console.log('Ødelagt??')
        this.connectWebsocket()

      }
      // console.log(this.connection.readyState)
    },
    setWebsocket() {
      let userId = this.$store.getters.userId
      // userId=JSON.stringify(userId)
      this.connection.send(userId)
      // this.connection.send(JSON.stringify(userId))
    },
    getNotifications() {
      this.NotificationService.getNotifications()
        .then((data) => {
          this.notifications = data.data

          this.$emit("unreadNotification", data.data.length)
          if (data.data.length > 0) {

          }
          // console.log()
        })

      // if(this.notifications.length>0){
      // }
    },

    toggleNot() {
      document.documentElement.classList.remove('stop-scrolling')
      this.$emit("close")
    },
    nav(notification) {
      if (notification.url) {
        this.$router.push(notification.url)
        let i = this.notifications.findIndex(id => id.id == notification.id)
        this.notifications.splice(i, 1)
        this.NotificationService.readNotification(notification.id);
        this.$emit("unreadNotification", this.notifications.length)
        if (this.notifications.length == 0) {
          document.documentElement.classList.remove('stop-scrolling')
          this.$emit("close")
        }
      }
    },
    deleteNotification(notification) {
      let i = this.notifications.findIndex(id => id.id == notification.id)
      this.notifications.splice(i, 1)
      this.NotificationService.readNotification(notification.id);
      this.$emit("unreadNotification", this.notifications.length)
      if (this.notifications.length == 0) {
        document.title = "FiberX"
        document.documentElement.classList.remove('stop-scrolling')
        this.$emit("close")
      }
    }
  }

}
</script>


<style scoped>
.no-notif-box {
  display: flex;
  justify-content: center;
  opacity: .5;
}

.icon-close {
  height: 3rem;
}

.icon-close:hover {
  cursor: pointer;
  color: var(--main-color);
}

.btn--icon .icon-bin {
  height: 1.6rem;
}

.no-notif {
  font-size: 2rem;
  font-style: italic;
  color: var(--log-color);
}

.notif-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  padding: 4rem 2rem;
}

.notif-box .icon-bin {
  position: absolute;
  top: 1rem;
  right: 2.5rem;
  height: 2rem;
  padding: 0;
  color: var(--red-color);
}

.notif-nav {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateX(200%);
  height: 100vh;
  position: fixed;
  top: 0rem;
  right: 0rem;
  width: 50rem;
  background: var(--bg-light-color);
  z-index: 777;
  transition: all 300ms ease-in-out;
  overflow: scroll;
}

.notif-box {
  position: relative;
  padding: 2rem 0;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  font-size: 1.4rem;
}

.notif-content {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  padding-left: 2rem;
  cursor: pointer;
  max-width: 90%;
}

.title {
  font-size: 1.8rem;
  padding-bottom: 1rem;
}

.notif-box i {
  padding: 1.2rem;
  align-self: center;
}

.notif-btn {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-right: 1rem;
}

.notif-btn button {
  scale: 0.9;
}

.notif-btm {
  margin: 2rem;
  padding-bottom: 8rem;
}

.notif-btm button {
  width: 100%;
  justify-content: center;
}

.notif-box:hover {
  background-color: var(--border-color);
}

.close-mobile:hover .icon {
  stroke: var(--main-color);
}

.notif-nav::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width:800px) {
  .notif-nav {
    width: 100%;
    margin-bottom: 5rem;
  }
}
</style>
