<template>
  <div class="ticket-top">
    <button v-if="view == 'grid'" @click="toggleView" class="btn btn--outline btn--icon">
      <IconMenu /> Bytt
      visning
    </button>
    <button v-if="view == 'table'" @click="toggleView" class="btn btn--outline btn--icon">
      <IconCard />Bytt
      visning
    </button>
  </div>
  <div class="tab-box">
    <button v-for=" button  in  filters " :key="button" class="btn--tabs"
      :class="{ 'tabs-active': filter == button.query }" @click="changeFilter(button.filter)">
      {{ button.label }}
    </button>
  </div>
  <div class="table-overflowx" v-if="view == 'table'">
    <table>
      <thead>
        <tr>
          <th @click="changeSort('id')">ID <span v-html="arrow('type')"></span></th>
          <th @click="changeSort('type')">Oppgave <span v-html="arrow('type')"></span></th>
          <th @click="changeSort('project_name')">Prosjekt <span v-html="arrow('project_name')"></span></th>
          <th @click="changeSort('lead')">Ansvarlig <span v-html="arrow('lead ')"></span></th>
          <th @click="changeSort('description')">Beskrivelse <span v-html="arrow('description')"></span></th>
          <th @click="changeSort('status')">Status <span v-html="arrow('status')"></span></th>
          <th @click="changeSort('date_created')">Dato opprettet <span v-html="arrow('date_created')"></span></th>
          <th @click="changeSort('priority')">Prioritet <span v-html="arrow('priority')"></span></th>
        </tr>
      </thead>
      <tbody>
        <tr @click=" this.$emit('click', ticket)" v-for=" ticket  in   sortedTickets()  " :key="ticket.id">
          <td>{{ ticket.id }}</td>
          <td>{{ ticketType(ticket.type) }}</td>
          <td>{{ ticket.project_name }}</td>
          <td>{{ formatUsers(ticket.lead) }}</td>
          <td>{{ ticket.description }}</td>
          <td>{{ ticketStatus2.find((x) => x.id == ticket.status).name }}</td>
          <td>{{ $dayjs(ticket.date_created).format('DD MMM YY') }}</td>
          <td :class="priColorTable(ticket.priority)">{{ priority(ticket.priority) }}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div v-else class="grid grid--ticket">
    <div v-for="ticket in tickets" :key="ticket.id" class="card" :class="priColor(ticket.priority)"
      @click="this.$emit('click', ticket)">
      <h2>{{ ticketType(ticket.type) }}</h2>
      <h4>{{ ticket.project_name }}</h4>
      <p><span>Ansvarlig:</span> {{ formatUsers(ticket.lead) }}</p>
      <p><span>Status:</span> {{ ticketStatus2.find((x) => x.id == ticket.status).name }}</p>
      <p><span>Prioritet:</span> {{ priority(ticket.priority) }}</p>
      <p><span>Opprettet:</span> {{ $dayjs(ticket.date_created).format('DD MMM YY') }}</p>
      <br>
      <p><span>Beskrivelse:</span> {{ descriptionLength(ticket.description) }}</p>
    </div>
  </div>
</template>

<script>
import UserService from '@/services/User'
import TicketService from '@/services/Ticket';
export default {
  mounted() {
    this.checkMode()
    this.ticketService = new TicketService();
    // this.ticketTypes=this.$store.getters.ticketTypes
    // this.ticketTypes='fuck this'
    // this.fetchData()
    if (this.mode == 'entry') {
      this.tickets = this.existingTickets;
    } else {
      this.getTickets();
    }
    this.userService = new UserService()
    this.getUsers()
  },
  props: ['existingTickets', 'mode', 'id'],
  computed: {},
  emits: ['click', 'loaded'],
  methods: {
    getUsers() {
      this.userService.getAllUsers()
        .then((res) => {
          this.users = res
        })

    },
    formatUsers(users) {
      if (!users) { return }
      let usersArr = this.parseUsers(users)
      if (!usersArr) { return }
      let userString = ''
      for (let i = 0; i < usersArr.length; i++) {
        userString += usersArr[i].name
        if (i !== (usersArr.length - 1)) {
          userString += ', '
        }
      }
      return userString
    },
    descriptionLength(description) {

      if (description.length > 50) {
        description = description.split(' ')
        return description.splice(0, 30).join(" ") + " ..."
      }
      return description
    },
    arrow(field) {
      if (field == this.sort && this.sortDir == true) {
        return '&darr;'
      }
      if (field == this.sort && this.sortDir == false) {
        return '&uarr;'
      }
    },
    parseUsers(teamLeaders) {
      if (teamLeaders == '') { return }
      if (!teamLeaders) { return }
      teamLeaders = teamLeaders.toString()
      teamLeaders = teamLeaders.split(',')
      if (!this.users) { return }
      // return teamLeaders
      let names = []
      for (let i = 0; i < teamLeaders.length; i++) {
        if (this.users.find((x) => x.id == teamLeaders[i])) {
          names.push({
            id: teamLeaders[i],
            name: this.users.find((x) => x.id == teamLeaders[i]).full_name
          })
        }
      }
      return names
    },
    changeSort(field) {
      this.sort = field
      if (field == this.sort) {
        this.sortDir = !this.sortDir
      }
      this.sortedTickets()
    },
    sortedTickets() {

      if (!this.sort) { return this.tickets }
      let tickets = JSON.parse(JSON.stringify(this.tickets))
      let direction = 1
      if (!tickets) { return }
      if (this.sortDir) { direction = -1 }
      for (let i = 0; i < tickets.length; i++) {
        if (!tickets[i][this.sort]) {
          tickets[i][this.sort] = ''
        }
      }
      let sorted = tickets.sort((a, b) => (a[this.sort] > b[this.sort]) ? direction : direction * -1)
      return sorted

    },
    priColor(priority) {
      switch (priority) {
        case 1:
          return 'pri--low'
        case 2:
          return 'pri--mid'
        case 3:
          return 'pri--high'
        default:
          break;
      }
    },
    priColorTable(priority) {
      switch (priority) {
        case 1:
          return 'pri--low2'
        case 2:
          return 'pri--mid2'
        case 3:
          return 'pri--high2'
        default:
          break;
      }
    },
    checkMode() {
      if (localStorage.getItem('ticketview')) {
        this.view = localStorage.getItem('ticketview');
      } else {
        this.view = 'grid';
      }
    },
    toggleView() {
      if (this.view == 'grid') {
        this.view = 'table';
        localStorage.setItem('ticketview', 'table');
      } else {
        this.view = 'grid';
        localStorage.setItem('ticketview', 'grid');
      }
    },
    priority(pri) {
      switch (pri) {
        case 1:
          return 'Lav';
        case 2:
          return 'Middels';
        case 3:
          return 'Høy';
        default:
          break;
      }
    },
    ticketType(type) {
      if (!type) {
        return;
      }
      return this.$store.getters.ticketTypes.find((x) => x.id == type).name;
    },
    changeFilter(filter) {
      if (this.filter == filter) {
        return
      }


      if (filter == 'assigned') {
        this.filter = '?assigned=1&closed=0'
        if (this.mode == "entry") { this.filter += "&entry=" + this.id }
        this.$loader(true)
        this.getTickets()
      }

      if (filter == 'closed') {
        this.filter = '?closed=1'
        if (this.mode == "entry") { this.filter += "&entry=" + this.id }
        this.$loader(true)
        this.getTickets()
      }

      if (filter == 'active') {
        this.filter = '?closed=0'
        if (this.mode == "entry") { this.filter += "&entry=" + this.id }
        this.$loader(true)
        this.getTickets()
      }


    },
    getTickets() {
      let filter = ''
      if (this.filter) {
        filter = this.filter
      }
      this.ticketService
        .getTickets(filter)
        .then((res) => {
          this.tickets = res
          this.$emit('loaded')
          this.$loader(false)
        })

    }
    //   return new Promise((resolve) => {
    //     this.ticketService.getTickets().then((res) => {
    //       this.tickets = res;
    //       resolve('resolved');
    //       this.$emit('loaded')
    //     });
    //   });
    // },
  },
  data() {
    return {
      tickets: null,
      filter: '?assigned=1&closed=0',
      sort: 'priority',
      sortDir: true,
      lastSort: null,
      users: null,
      userService: null,
      filters: [
        { label: 'Tildelte', filter: 'assigned', query: '?assigned=1&closed=0' },
        { label: 'Aktive', filter: 'active', query: '?closed=0' },
        { label: 'Fullførte', filter: 'closed', query: '?closed=1' },
      ],
      ticketStatus2: this.$store.getters.ticketStatus2,
      view: 'grid',
    };
  },
};
</script>

<style scoped>
.ticket-top {
  margin: 2rem 0;

}

thead tr:hover {
  cursor: pointer;
  color: var(--main-color);
  background: var(--table-border-color)
}

.card {
  max-width: 50rem;
  gap: 0.6rem;
  cursor: pointer;
}

.grid--ticket {
  grid-template-columns: repeat(3, 1fr);
  column-gap: 3rem;
  row-gap: 2rem;
}

p span {
  font-weight: 500;
}

.pri--low {
  box-shadow: 0 0 0 1px var(--green-color);
}

.pri--low:hover {
  box-shadow: 0 0 0 2px var(--green-color);
}

.pri--low2 {
  background: hsla(88, 100%, 50%, 0.19);
}


.pri--mid {
  box-shadow: 0 0 0 1px var(--main-color);
}

.pri--mid:hover {
  box-shadow: 0 0 0 2px var(--main-color);
}

.pri--mid2 {
  background: hsla(46, 100%, 50%, 0.19);
}

.pri--high {
  box-shadow: 0 0 0 1px var(--red-color);
}

.pri--high:hover {
  box-shadow: 0 0 0 2px var(--red-color);
}

.pri--high2 {
  background: hsla(0, 100%, 50%, 0.19);
}


@media (max-width: 1000px) {
  .grid--ticket {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 700px) {
  .grid--ticket {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .card {
    max-width: none;
  }
}
</style>
