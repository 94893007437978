<template>
  <div class="input">
    <label for="number-field">{{ label }}</label>
    <input type="number" pattern="[0–9]*" @input="$emit('update:modelValue', $event.target.value)" inputmode="decimal" :value="modelValue" :disabled="isEnabled"
       id="number-field" />
    <div v-if="log" class="log">
      {{ log.name }}
      {{ log.date }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit('input', this.content);
    },
  },
  computed: {
    isEnabled() {
      if (this.type == 'dyamic') {
        return false;
      }
      if (this.type == 'static' && this.edit == false) {
        return true;
      } else {
        return false;
      }
    },
  },
  emits:['update:modelValue'],
  props: ['modelValue','value', 'label', 'data', 'log', 'type', 'edit'],
};
</script>

<style>

</style>
