<template>
  <div class="search">

    <div class="search-top">
      <div>
        <h1>Søk</h1>
        <p class="log">Du kan søke på navn, skap, adresser og epost </p>
      </div>
      <div @click="toggleSearch" class="close-mobile">
        <IconClose />
      </div>
    </div>
    <div class="search-box-flex">
      <input v-model="searchTerm" ref="search" spellcheck="false" autocorrect="off" autocomplete="off" autofill="off"
        class="search-box" type="search" placeholder="Søk" id="searchBox" />
    </div>

    <div v-if="searchResults" class="search-res">
      <div v-if="searchResults.linjearbeid && searchResults.linjearbeid.length > 0">
        <h4>Linjearbeid</h4>
        <div class="results" v-for="item in searchResultsFilter(1)" :key="item.id">
          <div @click="clickItem(item, 2)" class="result-item">
            <p>Punkt fra: <span> {{ item.punkt_fra }}</span></p>
            <p>Punkt til: <span> {{ item.punkt_til }}</span></p>
            <p>Prosjekt: <span> {{ item.project_name }}</span></p>
          </div>
        </div>
      </div>

      <div v-if="searchResults.kundeprofil && searchResults.kundeprofil.length > 0">
        <h4>Kundekabel</h4>
        <div class="results" v-for="item in searchResultsFilter(2)" :key="item.id">

          <div @click="clickItem(item, 8)" class="result-item">
            <p>Navn: <span> {{ item.navn }}</span></p>
            <p>Epost: <span> {{ item.epost }}</span></p>
            <p>Adresse: <span> {{ item.adresse }}</span></p>
            <p>Prosjekt: <span> {{ item.project_name }}</span></p>
          </div>
        </div>

      </div>

      <div v-if="searchResults.grunnrett && searchResults.grunnrett.length > 0">
        <h4>Grunnrett</h4>
        <div class="results" v-for="item in searchResultsFilter(5)" :key="item.id">

          <div @click="clickItem(item, 3)" class="result-item">
            <p>Navn: <span> {{ item.navn }}</span></p>
            <p>Epost: <span> {{ item.epost }}</span></p>
            <p>Adresse: <span> {{ item.adresse }}</span></p>
            <p>Prosjekt: <span> {{ item.project_name }}</span></p>
          </div>
        </div>

      </div>

      <div v-if="searchResults.koblingspunkt && searchResults.koblingspunkt.length > 0
        ">
        <h4>Koblingspunkt</h4>
        <div class="results" v-for="item in searchResultsFilter(3)" :key="item.id">
          <div @click="clickItem(item, 1)" class="result-item">
            <p>Punkt: <span> {{ item.punkt_navn }}</span></p>
            <p v-if="item.punkt_ferdig_punkt_navn"> Punkt ferdig navn: <span> {{ item.punkt_ferdig_punkt_navn
            }}</span>
            </p>
            <p>Prosjekt: <span> {{ item.project_name }}</span></p>
          </div>
        </div>
      </div>

      <div v-if="searchResults.projects && searchResults.projects.length > 0">
        <h4>Prosjekt</h4>
        <div class="results" v-for="item in searchResultsFilter(4)" :key="item.id">
          <span @click="clickItem(item, 100)">{{ item.project_name }} - Prosjektleder: {{ item.project_leader }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EntryService from '@/services/Entry';
export default {
  props: ['isShown'],
  mounted: function () {
    this.entryService = new EntryService();
    // document.documentElement.classList.add('stop-scrolling')
    this.focusInput()
  },
  watch: {
    $route() {
      this.searchResults = {
        linjearbeid: [],
        koblingspunkt: [],
        kundeprofil: [],
      };
      this.searchTerm = null
    },
    isShown() {
      this.searchTerm = ""
      this.focusInput()
    },
    searchTerm(newTerm) {
      if (!newTerm) { return }
      if (newTerm.length == 0) {
        this.searchResults = {
          linjearbeid: [],
          koblingspunkt: [],
          kundeprofil: [],
        };
      }
      if (newTerm.length > 2) {
        this.search();
      }
    },
  },
  data() {
    return {
      searchTerm: null,
      entryService: null,
      searchResults: {
        linjearbeid: [],
        koblingspunkt: [],
        kundeprofil: [],
      },
    };
  },
  methods: {
    focusInput() {
      const input = this.$refs.search;
      this.$refs.search.focus();
    },
    clickItem(item, template) {
      if (template == 100) {
        this.$router.push('/project/' + item.id)
        return
      }
      this.$router.push(`/template/${template}/entry/${item.id}`);
      this.toggleSearch()
    },
    toggleSearch() {
      this.searchTerm = ""
      document.documentElement.classList.remove('stop-scrolling')
      this.$emit("close")
    },
    searchResultsFilter(type) {
      //Sett opp switch case
      if (type == 1) {
        return this.searchResults.linjearbeid;
      }
      if (type == 2) {
        return this.searchResults.kundeprofil;
      }
      if (type == 3) {
        return this.searchResults.koblingspunkt;
      }
      if (type == 4) {
        return this.searchResults.projects
      }
      if (type == 5) {
        return this.searchResults.grunnrett
      }
    },
    search() {
      if (this.searchTerm.length == 0) {
        this.searchResults = {
          linjearbeid: [],
          koblingspunkt: [],
          kundeprofil: [],
        };
      }
      if (this.searchTerm.length > 2) {
        this.entryService
          .search(this.searchTerm)
          .then((res) => (this.searchResults = res));
      }
    },
  },
};
</script>

<style scoped>
.search {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transform: translateX(200%);
  position: fixed;
  overflow: scroll;
  top: 0rem;
  right: 0rem;
  height: 100vh;
  width: 50rem;
  background: var(--bg-light-color);
  border: 1px solid var(--border-color);
  z-index: 1111;
  transition: all 300ms ease-in-out;
}

.search-top {
  height: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 4rem 2rem;
}

.search-box-flex {
  display: flex;
  justify-content: center;
  padding: 0 2rem;
}

.search-box {
  width: 100%;
  border: 2px solid var(--border-color);
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--h-color);
  cursor: pointer;
}

.close-mobile {
  top: 2.5rem;
  right: 2rem;
  cursor: pointer;
}

.close-mobile:hover .icon-close {
  stroke: var(--main-color);

}

.close-mobile:hover .icon-close {
  stroke: var(--main-color);

}

.search-res {
  padding: 2rem;
  padding-bottom: 8rem;

}

.results {
  padding: 1rem;
  border-bottom: 1px solid var(--border-color);
  cursor: pointer;
  width: 100%;
}

.results:hover {
  background: var(--border-color);
}

.result-item {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.result-item span {
  font-weight: 500;
}

h4 {
  margin-top: 1.5rem;
}

.search::-webkit-scrollbar {
  display: none;
}

/* @media only screen and (hover: none) and (max-width: 800px) { */
@media only screen and (max-width: 800px) {
  .search {
    width: 100%;
  }

  .search-res {
    margin-bottom: 5rem;
  }
}
</style>
