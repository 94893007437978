<template>
    <div v-for="field in fields" :key="field.id">
        <component :is="field.component" v-model="entry[field.dataName]" :data="entry[field.dataName]" :label="field.label"
            :options="field.options" :edit="true" :default="true" :type="field.type">
        </component>
    </div>
    <div class="add-entry ">
        <button @click="addEntry('single')" class="btn btn--outline btn--icon">
            <IconPlus />Legg til
        </button>
        <button @click="addEntry('multiple')" class="btn btn--outline btn--icon">
            <IconChevronRight2 />Legg til flere
        </button>
    </div>
    <p class="log">For å legge til flere oppføringer etter hverandre trykk på 'Legg til flere',
        trykker du på 'Legg til' går du rett til oppføringen
    </p>
</template>

<script>
import CheckboxField from '@/components/DataTypes/CheckboxField.vue';
import TextField from '@/components/DataTypes/TextField.vue';
import NumberField from '@/components/DataTypes/NumberField.vue';
import DropdownField from '@/components/DataTypes/DropdownField.vue';
import LinkField from '@/components/DataTypes/LinkField.vue';
import SimpleCheckboxField from '@/components/DataTypes/SimpleCheckboxField.vue';
import MaterialField from '@/components/DataTypes/MaterialField.vue';
import StatusField from '@/components/DataTypes/StatusField.vue';
import TextBoxField from '@/components/DataTypes/TextBoxField.vue';
import EntryService from '@/services/Entry'

export default {
    components: {
        TextField,
        CheckboxField,
        DropdownField,
        LinkField,
        NumberField,
        SimpleCheckboxField,
        MaterialField,
        StatusField,
        TextBoxField,
    },
    data() {
        return {
            entry: {},
            entryService: null,
        }
    },
    created() {
        this.entryService = new EntryService
    },
    methods: {
        addEntry(type) {
            this.$loader(true)
            let entry = {}
            entry.fields = this.entry
            entry.project = this.$route.params.project
            const template = this.$route.params.template
            this.entryService
                .createEntry(template, entry)
                .then((res) => {
                    this.$loader(false)
                    this.$toast(res.status, res.message)
                    if (type == 'single') {
                        this.$router.push(`/template/${template}/entry/${res.entry_id}`)
                    }
                    this.entry = {}
                })
        }
    },
    computed: {
        fields() {
            const template = this.$route.params.template
            let filter = []
            if (template == 1) {
                filter = [1, 3, 4]
            }
            if (template == 2) {
                filter = [14, 15, 22]
            }
            if (template == 8) {
                filter = [13, 23, 24, 25, 27, 28, 29, 30]
            }
            if (template == 3) {
                filter = [60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71]
            }

            let sorted = this.$store.getters.templateFields(Number(this.$route.params.template))
            sorted = sorted.filter((x) => filter.includes(x.id))

            return sorted
        },
    }
}

</script>

<style scoped>
.input {
    width: 80%;
}

.add-entry {
    display: flex;
    gap: 1rem;
}

.svg-icon {
    height: 2rem;
}

@media (max-width: 810px) {
    .input {
        width: 100%;
    }
}
</style>