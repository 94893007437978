<template>
  <div class="container">
    <div class="card">
      <h2>Feedback</h2>
      <div class="p-content">
      </div>
      <textarea name="feedback" v-model="feedback" rows="6" />
      <p><span v-if="feedback">{{ feedback.length }}</span><span v-else>0</span> / 1000</p>
      <button @click="submitFeedback()" class="btn btn--outline btn--icon m-top">
        <IconSend />Send
        inn
      </button>
    </div>
    <div class="card">
      <h2>Kontakt oss </h2>
      <div class="p-content">

        <p>Du kan ta kontakt med oss på e-post om du lurer på noe :)</p>
        <p>Er det en kritisk feil, ta kontakt på SMS eller telefon</p>

        <p>Har du forslag eller funnet feil i FiberX, bruk skjemaet ovenfor</p>
      </div>
      <div class="contact">
        <div class="flex-col-1">
          <p>E-post</p>
          <a href="mailto:system@fiberx.no">system@fiberx.no</a>
        </div>
        <div class="flex-col-1">
          <p>Tlf</p>
          <a href="tel:46645264">466 45 264</a>
        </div>
      </div>
    </div>





  </div>
</template>

<script>
import SocialService from '@/services/Social'
export default {
  mounted() {
    this.socialService = new SocialService
  },
  data() {
    return {
      socialService: null,
      feedback: null
    }
  },
  methods: {
    submitFeedback() {
      if (!this.$validateText(this.feedback)) {
        this.$toast('warning', 'Fyll ut beskjed')
        return
      }
      if (this.feedback.length > 1000) {
        this.$toast('warning', 'For lang melding, send heller en e-post :)')
        return
      }

      this.$loader(true)
      this.socialService
        .postFeedback({ message: this.feedback })
        .then((res) => {
          this.$loader(false)
          this.$toast(res.status, res.message)
          if (res.status == 'success') {
            this.feedback = null
          }
        })

      //TODO!!!
      //Loader på, send inn, loader avv
    }
  }
}

</script>

<style scoped>
.container {
  max-width: 70rem;
}

.card {
  margin-bottom: 3rem;
}

.p-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 2rem 0;
}

button {
  display: flex;
  white-space: nowrap;
  align-items: center;
}

textarea {
  max-width: 50rem;
  margin-bottom: 2rem;
}

button {
  max-width: 15rem;
  font-size: 1.8rem;
}

.icon-send {
  height: 2rem;
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contact a {
  color: var(--main-color);
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}
</style>
