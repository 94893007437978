<template>
  <Header title="Materiell"/>
  <div v-if="materialSummaries">
    <div class="tab-scroll">
      <div class="tab-box">
        <button v-for="catFilter in materialCategories" class="btn--tabs" @click="cat = catFilter"
          :class="{ 'tabs-active': (cat == catFilter) }">{{ catFilter }}</button>
      </div>
    </div>
    <table v-if="(materials)">
      <thead>
        <tr>
          <th class="background">Navn</th>
          <th class="background">Antall</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="material in filteredMaterials(cat)" :key="material.id">

          <td>{{ material.material_friendly_name || material.material_name }}</td>
          <td>
            <div class="material-count" v-if="material &&
              materials &&
              materialSummaries &&
              materialSummaries.find((x) => x.material_id == material.id)">
              <!-- Knapp minus -->
              <button class="btn btn--minus"
                @click="materialSummaries[materialSummaries.findIndex((x) => x.material_id == material.id)].newQuantity--, this.emitChanges()">
                -
              </button>
              <input :id="material.id+'txt'" @input="this.emitChanges()"
                v-model="materialSummaries[materialSummaries.findIndex((x) => x.material_id == material.id)].newQuantity"
                type="number" />
              <!-- Knapp pluss -->
              <button class="btn btn--plus"
                @click="materialSummaries[materialSummaries.findIndex((x) => x.material_id == material.id)].newQuantity++, this.emitChanges()">
                +
              </button>
            </div>

            <div v-else class="material-count">
              <button @click="adjustTmpValue(material, -1)" class="btn btn--minus">-</button>
              <input :id="material.id+'txt'" @input="this.emitChanges()" v-model="material.tmpValue" placeholder="0" type="number" />
              <button @click="adjustTmpValue(material, 1)" class="btn btn--plus">+</button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import MaterialService from '@/services/Material';
export default {
  props: ['entry', 'showAll', 'disableSpinner'],
  emits: ['changes'],
  watch: {

  },
  data() {
    return {
      cat: 'Alle',
      materials: [],
      changes: false,
      materialService: null,
      materialSummaries: null,
      materialLog: null,
      filterCustomer: [42, 51, 52, 53, 58],
      filterConnectionPoint: [13, 14, 15, 16],
      filterLinje: [42, 44, 45, 59, 60],
      copy: null,
      materialCategories: [
        'Alle',
        'Fiber tilbehør',
        'Fiber kabel',
        'Kummer',
        'Opphengsmateriell',
        'Rør',
        'Skap'
      ]
    };
  },
  mounted: async function () {
    if (!this.disableSpinner) { this.$loader(true) }
    this.materialService = new MaterialService();
    this.fetchData();
  },
  methods: {
    emitChanges() {
      //Dum måte, bare kall hvis noe endres. Gjør denne litt smartere senere vel..
      this.$emit("changes")
    },
    adjustTmpValue(material, quantity) {
      this.emitChanges()//Tmp fix.
      //Ser etter materialdid, og legger til en tmpvalue for å legge til nyeste quantity.
      let i = this.materials.findIndex((x) => x.id == material.id)
      if (i == -1) { return }
      if (this.materials[i].tmpValue) {
        this.materials[i].tmpValue += quantity
      } else {
        this.materials[i].tmpValue = quantity
      }
      //Oppdater / push til summary, hvis + / - knappen er brukt. Hvis tekstboks er brukt, ikke gjør det..
      //Etterpå, loop igjennom matr hvor tmpvalues finnes, og legg til i matr summary, hvis ikke allerede der..
      //Usikker på bedre måte å gjøre det. Legge til med engang bytter tekstboks, og fjerner fokus. Veldig "jarring"
      let newSummary = { material_id: material.id, newQuantity: this.materials[i].tmpValue, oldQuantity: 0, quantity: this.materials[i].tmpValue }
      this.materialSummaries.push(newSummary)
    },


    filteredMaterials(cat) {
      //Hvis category ikke er "Alle", filtrer basert på category.
      if (cat != "Alle") {
        return this.materials.filter(matr => matr.category == cat)
      }
      //Hvis showAll, viser alle. Forskjell på Entry forside, som bare viser "relevante" filtrerte, 
      //mens matr fane viser alle matr.
      if (this.showAll) {
        return this.materials;
      }
      let filter = [];
      switch (Number(this.$route.params.template)) {
        case 8:
          filter = this.filterCustomer;
          break;
        case 1:
          filter = this.filterConnectionPoint;
        case 2:
          filter = this.filterLinje;
        default:
          break;
      }


      //Ta med matr som er utenfor listen, men allerede brukt
      let filter2 = []
      for (let i = 0; i < this.materialSummaries.length; i++) {
        filter2.push(this.materialSummaries[i].material_id)
      }
      filter = [...filter, ...filter2]

      let mat = this.materials;
      mat = mat.filter((x) => filter.includes(x.id));
      return mat;
    },
    updateMaterials() {
      //Sjekk om noen matr har tmp value, og ikke finnes i matr summary.
      //Slett tmp value, og push de til matr summary i så fall.
      for (let i = 0; i < this.materials.length; i++) {
        if (this.materials[i].tmpValue) {
          //Finnes, sjekk om summary også finnes.
          const index = this.materialSummaries.findIndex(x => x.material_id == this.materials[i].id)

          //Hvis index er -1, finnes ikke i summaries array. Legg til :-) er i>-1, så finnes den i summary.
          if (index == -1) {
            let newSummary = {
              material_id: this.materials[i].id,
              newQuantity: this.materials[i].tmpValue,
              oldQuantity: 0,
              quantity: this.materials[i].tmpValue
            }
            this.materialSummaries.push(newSummary)
          }
        }
      }


      // return
      this.$loader(true)
      let summaries = this.materialSummaries
      let changedSummaries = []
      for (let i = 0; i < summaries.length; i++) {
        let oldQuantity = summaries[i].entryQuantity || 0;
        let newQuantity = summaries[i].newQuantity;
        let change = newQuantity - oldQuantity;
        let data = {
          change: change,
          materialId: summaries[i].material_id,
        };
        if (data.change == 0) {
        } else {
          //Pushet endring til objekt, oppdater nå gammel til ny quantity,
          //Så den ikke gjør dette igjen (TicketID220)
          changedSummaries.push(data)
          this.materialSummaries[i].oldQuantity=this.materialSummaries[i].newQuantity
          this.materialSummaries[i].entryQuantity=this.materialSummaries[i].newQuantity
          this.materialSummaries[i].quantity=this.materialSummaries[i].newQuantity
        }
      }
      if (changedSummaries.length == 0) {
        this.$toast('warning', 'Ingen endringer å lagre')
        this.$loader(false)
        return
      }

      console.log(changedSummaries)
      // return
      this.materialService
        .updateMaterialUsage(
          this.entry.project,
          this.$route.params.template,
          this.entry.id,
          changedSummaries
        )
        .then((res) => {
 
          console.log(this.materialSummaries)
          this.$toast(res.status, res.message)
          this.$loader(false)
          // this.fetchData()
        });

    },
    async fetchData() {
      await Promise.all([
        this.fetchMaterials(),
        this.getMaterialSummaries(),
        this.getMaterialLogs(),
      ]).then((message) => {
        this.calculateQuantities();
        this.copy = JSON.parse(JSON.stringify(this.materialSummaries));
        // this.changesFunc()
      });
    },
    calculateQuantities() {
      for (let i = 0; i < this.materialSummaries.length; i++) {
        let log = this.materialLog.filter(
          (thing) => thing.material_id == this.materialSummaries[i].material_id
        );
        if (log.length == 0) {
          this.materialSummaries[i].thisEntry = 0;
        }
        let quantity = log.reduce(function (sum, number) {
          let updatedSum = sum + number.quantity;
          return updatedSum;
        }, 0);
        this.materialSummaries[i].newQuantity = quantity;
        this.materialSummaries[i].entryQuantity = quantity;
      }
    },
    entryMaterial(id) {
      if (!this.materialLog) {
        return 0;
      }
      let log = this.materialLog.filter((thing) => thing.material_id == id);
      if (log.length == 0) {
        return 0;
      }
      let quantity = log.reduce(function (sum, number) {
        let updatedSum = sum + number.quantity;
        return updatedSum;
      }, 0);

      return quantity;
    },

    fetchMaterials() {
      this.materials = this.$store.getters.materials;
      return;

    },
    getMaterialLogs() {
      return new Promise((resolve) => {
        this.materialService
          .getMaterialLogsToEntry(
            this.entry.project,
            this.$route.params.template,
            this.entry.id
          )
          // .then((res) => (this.materialLog = res))
          .then((res) => {
            this.materialLog = res;
            resolve('resolved');
          });
      });
    },
    getMaterialSummaries() {
      return new Promise((resolve) => {
        this.materialService
          .getMaterialSummaries(this.entry.project)
          .then((res) => {
            this.materialSummaries = res;
            resolve('ok');
            this.$loader(false)

          });
      });
    },
    changesFunc() {
      if (JSON.stringify(this.materialSummaries) == JSON.stringify(this.copy)) {
        this.$emit("changes", false)
      } else {
        this.$emit("changes", true)

      }
      return
    },
  },
};
</script>

<style scoped>
.mat-top {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
}

.material-count {
  display: flex;
  gap: 1rem;
}

.material-count input {
  width: 5rem;
}

tr>td {
  cursor: default;
}
</style>
