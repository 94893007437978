<template>
  <div class="input">
    <label :for="label">{{ label }}</label>
    <input :class="{ edit: checkStatic() }" type="text" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" :disabled="isEnabled"
      :id="label" />
    <!-- <input :class="{ edit: checkStatic() }" type="text" v-bind="$attrs" @input="handleInput" :disabled="isEnabled"
      :id="label" /> -->
    <div v-if="log" class="log">
      {{ log.name }}
      {{ log.date }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: this.value,
      defaultState: null
    };
  },
  methods: {
    checkStatic() {
      if (this.type == 'static' && this.edit == true && !this.default) {
        return 'edit'
      }
      return
    },
    handleInput(e) {
      this.$emit('input', this.content);
    },
  },
  computed: {
    isEnabled() {
      if (this.type == 'dyamic') {
        return false;
      }
      if (this.type == 'static' && this.edit == false) {
        return true;
      } else {
        return false;
      }
    },
  },
  emits:['update:modelValue'],
  props: ['modelValue','value', 'label', 'data', 'log', 'type', 'edit', 'default'],
};
</script>

<style scoped>
.edit {
  border: 1px solid var(--main-color);
}
</style>
