import axios from 'axios'
// const axios = this.$http

export default class UserService{
    getAllUsers(){
        return axios.get('/api/v1/admin/all-users').then(res=>res.data)
    }
    sendInvite(data){
        return axios.post('/api/v1/admin/sendinvite',data).then(res=>res.data)
    }
    forgottenPassword(data){
        return axios.post('/api/v1/user/forgottenpassword',data).then(res=>res.data)
    }
    resetPassword(data){
        return axios.post('/api/v1/user/resetpassword',data).then(res=>res.data)
    }
    changePassword(data){
        return axios.post('/api/v1/user/changepassword',data).then(res=>res.data)
    }
    updateUser(data){
        return axios.patch('/api/v1/user',data).then(res=>res.data)
    }
    deleteUser(data){
        return axios.delete('/api/v1/user',{data:data}).then(res=>res.data)
    }
    activateUser(data){
        return axios.post('/api/v1/user/activate',data).then(res=>res.data)
    }
    getPendingInvitations(){
        return axios.get('/api/v1/user/activation/pending').then(res=>res.data)
    }
    deleteInvitation(id){
        return axios.delete(`/api/v1/user/invitation/${id}`).then(res=>res.data)
    }
    resendInvite(id){
        return axios.get(`/api/v1/user/invite/resend/${id}`).then(res=>res.data)
    }
    changeEmail(data){
        return axios.post(`/api/v1/user/changemail`,data).then(res=>res.data)
    }
    confirmEmailChange(data){
        return axios.post(`/api/v1/user/changeemail/confirm`,data).then(res=>res.data)
    }
    setUserPreference(data,type){
        return axios.post(`api/v1/userpreference/customfields/${type}`,data).then(res=>res.data)
    }
}