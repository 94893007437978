<template>
  <div class="container">

    <Header title="Produksjon" />

    <h3 class="m-top">1: Velg ett eller flere prosjekt</h3>
    <div class="input--checkbox m-top">
      <input true-value=true v-model="allProjectsSelected" type="checkbox" id="all-projects">
      <label for="all-projects">Velg alle prosjekter</label>
    </div>


    <div class="input">
      <div v-if="allProjects && allProjects.length > 0 && !allProjectsSelected" class="input">
        <DropdownMultiple id="projects" @input="selectedProjects = $event" v-model="selectedProjects"
          :data="selectedProjects" :options="allProjects" label="project_name" />
      </div>
    </div>
    <h3 class="m-top">2: Velg en template</h3>
    <div class="flex-row-1">
      <button :class="{'btn--active':template==1}" @click="getColumns(1)" class="btn btn--outline">Koblingspunkt</button>
      <button :class="{'btn--active':template==2}" @click="getColumns(2)" class="btn btn--outline">Linjearbeid</button>
      <button :class="{'btn--active':template==3}" @click="getColumns(3)" class="btn btn--outline">Grunnrett</button>
      <button :class="{'btn--active':template==8}" @click="getColumns(8)" class="btn btn--outline">Kunde</button>
    </div>
    <h3 class="m-top">3: Velg fra og til dato</h3>
    <div class="m-top input-date">
      <div class="input">
        <label for="dateStart">Startdato</label>
        <input v-model="dateStart" type="date" id="dateStart" />
      </div>
      <div class="input">
        <label for="dateEnd">Sluttdato</label>
        <input v-model="dateEnd" type="date" id="dateEnd" />
      </div>
    </div>
    <div class="date-btns">
      <div class="flex-row-1">
        <button @click="setDate(-1, 'week')" class="btn btn--text-btn btn--week">-1 Uke</button>
        <button @click="setDate(0, 'week')" class="btn btn--text-btn btn--week">Denne Uken</button>
        <button @click="setDate(1, 'week')" class="btn btn--text-btn btn--week">+1 Uke</button>
      </div>
      <div class="flex-row-1">
        <button @click="setDate(-1, 'month')" class="btn btn--text-btn btn--week">-1 Mnd</button>
        <button @click="setDate(0, 'month')" class="btn btn--text-btn btn--week">Denne Mnd</button>
        <button @click="setDate(1, 'month')" class="btn btn--text-btn btn--week">+1 Mnd</button>
      </div>
    </div>

    <h3 class="m-top">4: Velg felter</h3>
    <div class="input m-top m-btm">
      <div class="input--checkbox m-btm">
        <input @click="toggleAll()" v-model="allSelected" id="check-all" type="checkbox">
        <label for="check-all">Velg alle felter</label>
      </div>
      <div class="input--checkbox" v-for="field in allFields">
        <!-- {{field}} -->
        <input v-if="isDisabled()" :disabled="isDisabled(field.is_hidden)" type="checkbox" true-value="0"
          v-model="field.is_hidden" name="" id="">
        <input v-else="isDisabled()" :disabled="isDisabled(field.is_hidden)" type="checkbox" :value="field.id"
          false-value="0" v-model="selectedFields" name="" :id="field.id">
        <label :for="field.id"> {{ field.label }}</label>
      </div>
    </div>
    <button @click="getReport()" class="btn btn--outline btn--icon">
      <IconCheck />Utfør
    </button>

    <!-- {{ rawData }} -->


    <div v-if="showTable" class="table-overflowx">

      <table>
        <thead>
          <tr>
            <th class="name-tr">Felt</th>
            <th class="num-tr">Ja</th>
            <th class="num-tr">Utgår</th>
            <th class="num-tr">Nei</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="field in selectedFields">
            <td>{{ allFields.filter(x => x.id == field)[0].label }}</td>
            <td :class="{ 'noQuantity': parseDataField(field, 1) == 0 }">{{ parseDataField(field, 1) }}
            </td>
            <td :class="{ 'noQuantity': parseDataField(field, 2) == 0 }">{{ parseDataField(field, 2) }}
            </td>
            <td :class="{ 'noQuantity': parseDataField(field, 0) == 0 }">{{ parseDataField(field, 0) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</template>
  
<script>
import ReportService from '@/services/Report'
import ProjectService from '@/services/Project';
import Chart from '../components/UI/Chart'
export default {
  components: {
    Chart
  },
  mounted() {
    this.reportService = new ReportService
    this.projectService = new ProjectService
    this.getColumns(1)
    // this.markAll()
    this.fetchProjects()
    // this.getReport()
    this.setDate(0, 'week')
    // this.dateStart=new Date().toJSON().slice(0, 10);
  },
  data() {
    return {
      allFields: null,
      showTable: false,
      allSelected: false,
      allProjectsSelected:false,
      sort: 0,
      dateStart: '2022-01-01',
      dateEnd: '2022-01-01',
      currentSortArray: null,
      template: 2,
      selectedFields: [],
      rawData: [],
      allProjects: [],
      selectedProjects: [],
      projectService: null,
      allSelected: false,
      // availableFieldsConnectionPoint:[2,4,20,21]
    }
  },
  methods: {
    
    addAllProjects() {
      // console.log('ee?')
      this.selectedProjects = []
      this.allProjects.map(x => this.selectedProjects.push(x.id))
    },
    fetchProjects() {
      let filter = '?all'
      this.projectService
        .getProjects(filter)
        .then((response) => {
          (this.allProjects = response)
          this.$loader(false)
        });
    },
    setDate(inc, time) {

      if (inc == 0 && time == "week") {
        this.dateStart = this.$dayjs().day(1).format('YYYY-MM-DD')
        this.dateEnd = this.$dayjs(this.dateStart).day(7).format('YYYY-MM-DD')
        return
      }
      if (inc == 0 && time == "month") {
        this.dateStart = this.$dayjs().startOf('month').format('YYYY-MM-DD')
        this.dateEnd = this.$dayjs().endOf('month').format('YYYY-MM-DD')
        return
      }

      if (time == 'month') {
        this.dateStart = this.$dayjs(this.dateStart).add(inc, time).format('YYYY-MM-DD')
        this.dateEnd = this.$dayjs(this.dateStart).endOf('month').format('YYYY-MM-DD')
      } else {
        this.dateStart = this.$dayjs(this.dateStart).add(inc, time).format('YYYY-MM-DD')
        this.dateEnd = this.$dayjs(this.dateEnd).add(inc, time).format('YYYY-MM-DD')
      }

    },
    chooseClass(quantity) {
      // if(quantity)
    },
    parseDataField(field, action) {
      let name = this.allFields.filter(x => x.id == field)[0].dataName
      // return name
      let i = this.rawData.findIndex(x => x.field == name && x.action == action)
      if (i >= 0) {
        return this.rawData[i].quantity
      } else {
        return 0
      }

      return field
    },
    toggleAll() {
      if (this.allSelected) {
        this.selectedFields = []
      } else {
        this.selectedFields = this.allFields.map(x => x.id)
      }
    },
    markAll() {
      this.selectedFields = this.allFields.map(x => x.id)
    },
    isDisabled() {
      return false
    },
    getReport() {
      if (this.allProjectsSelected) {
        this.allProjects.map(x => this.selectedProjects.push(x.id))
      }
      this.showTable = true
      let data = {
        fields: this.selectedFields,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        template: this.template,
        projects: this.selectedProjects

      }
      this.reportService.getReport(data).then((res) => {
        this.rawData = res.data
        // (console.log(res))
      })
    },
    getColumns(template) {
      this.showTable = false
      this.allSelected = false
      this.template = template
      this.selectedFields = []
      let fields = this.$store.getters.templateFields(template);

      let components = ['CheckboxField', 'SimpleCheckboxField']
      this.allFields = fields.filter(x => components.includes(x.component))

      // );
    },
  },
};
</script>
  
<style scoped>

/* Flyttes til global for aktiv knapp? */
.btn--active{
  color: var(--border-color);
  background: var(--main-color);
}



.noQuantity {
  color: grey;
  font-style: italic;
}

.flex-row-1 {
  flex-wrap: wrap;
  margin: 1rem 0;
}

tbody tr:hover {
  cursor: default;
}

.table-overflowx {
  max-width: 40rem;
  margin: 2rem 0;


}

.num-tr {
  width: 5rem;
}

.input {
  max-width: 20rem;
}

.input-date {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
}

.input-date input {
  width: 13rem;
}

.btn--week {
  padding: .6rem;
  width: 10rem;
}

@media (max-width: 360px) {

  .flex-row-1 {
    flex-direction: column;
  }

  .date-btns {
    display: flex;
    flex-direction: row;
    gap: 2rem;
  }

}
</style>
  



  Skitbra!!! 

  
  SELECT field,action,  COUNT(action) AS doneCnt 
FROM template_logs
where project=68
GROUP BY field, action