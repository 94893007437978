<template>
  <div class="project-table table-overflowx ">
    <table v-if="projects">
      <thead>
        <tr>
          <th @click="changeSort('project_name')" class="th--proj-name">Prosjekt <span
              v-html="arrow('project_name')"></span></th>

          <th @click="changeSort('project_leader')" class="th--proj-leader">Prosjektleder <span
              v-html="arrow('project_leader')"></span></th>
          <!-- 
          <th @click="changeSort('service_delivery')" class="th--days-left">Dager igjen <span
              v-html="arrow('deadline')"></span>
          </th> -->
          <th @click="changeSort(date.data)" v-for="date in dates">
            {{ date.label }} <span v-html="arrow(date.data)"></span>
          </th>

          <!-- <th @click="changeSort('service_delivery')" class="th-last">Første tjenesteleveranse <span
            v-html="arrow('deadline')"></span>
          </th>
          <th v-if="true" @click="changeSort('service_delivery')" class="th-last">Teknisk ferdig <span
            v-html="arrow('technically_finished')"></span>
          </th> -->
          <th class="progress-table">Fremgang</th>


        </tr>
      </thead>
      <tbody>
        <tr @click="$router.push('/project/' + project.id)" v-for="project in sortedProjects()" :key="project.id">
          <td>{{ project.project_name }}</td>
          <td>
            {{ formatUsers(project.project_leader) }}
          </td>


          <td v-for="date in dates">
            <div v-if="date.data == date.preference">
              <p class="green-txt" v-if="false && date.data == 'service_delivery' && project.service_delivered == 1">TL
                Startet</p>
              <!-- <p class="green-txt" v-else-if="project[date.data] == 1">Prosjekt ferdig</p> -->
              <p v-else>{{ parseDate(project[date.data]) }}</p>
              <!-- {{parseDate(project[date.data])}} -->
            </div>
            <div v-if="date.data !== date.preference">

              <p class="green-txt" v-if="date.data == 'service_delivery' && project.service_delivered == 1">TL Startet</p>
              <p v-else :class="daysLeftClass(getDaysLeft(project[date.data], false))">
                {{ getDaysLeft(project[date.data], true) }}
                <!-- nedtelling -->
                <!-- {{project[date.data]}} -->
                <!-- {{date.data}} -->
              </p>
            </div>
          </td>



          <!-- <td>{{ parseDate(project.service_delivery) }}</td> -->

          <td>
            <div v-if="project.progress && project.progress" class="prog-bar-container">
              <span>{{ calculatePercentage(project.progress.totalProgress) }}</span>
              <div :style="'width:' + calculatePercentage(project.progress.totalProgress, 'css')" class="prog-bar-bar"
                v-if="project.progress">
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ProjectService from '@/services/Project';
export default {
  data() {
    return {
      sort: null,
      sortDir: true,
      lastSort: null,
      rawProgress: null,
      progressTemplate: {},
      projectService: null,
      dates: [
        {
          data: 'service_delivery',
          preference: 'service_delivery',
          label: 'Første tjenesteleveranse'
        },
        {
          data: 'service_delivery',
          preference: 'service_delivery_c',
          label: 'Første tjenesteleveranse'
        },
        {
          data: 'technically_finished',
          preference: 'technically_finished',
          label: 'Teknisk ferdig'
        },
        {
          data: 'technically_finished',
          preference: 'technically_finished_c',
          label: 'Teknisk ferdig'
        },
        {
          data: 'deadline',
          preference: 'deadline',
          label: 'Ferdigstilling'
        },
        {
          data: 'deadline',
          preference: 'deadline_c',
          label: 'Ferdigstilling'
        }

      ]
    };
  },
  computed: {
    templates() {
      return this.project.templates.split(',');
    },
  },
  mounted() { },
  created() {
    this.projectService = new ProjectService();
    this.filterDates()
  },
  methods: {
    filterDates() {
      let preferences = this.$store.getters.showProjectTableView
      console.log(preferences)
      let newDates = []
      // if (!preferences || Object.keys(preferences).length == 0) {
      //   newDates.push(this.dates[0])
      //   newDates.push(this.dates[2])
      //   this.dates = newDates
      //   return
      // }
      for (let i = 0; i < this.dates.length; i++) {
        // console.log("lo")
        if (preferences[this.dates[i].preference]) {
          console.log("yep")
          newDates.push(this.dates[i])
        } else {
          console.log("nop")

        }
      }
      this.dates = newDates
    },
    calculatePercentage(progress, type) {
      if (!progress) {
        return ''
      }
      const sum = progress.yes + progress.no + progress.na
      const finished = progress.yes + progress.na
      if (isNaN(Math.floor(finished / sum * 100))) {
        if (type == 'css') {
          return '0%'
        }
        return "Ingen oppføringer"
      }
      return Math.floor(finished / sum * 100) + "%"
    },
    arrow(field) {
      if (field == this.sort && this.sortDir == true) {
        return '&darr;'
      }
      if (field == this.sort && this.sortDir == false) {
        return '&uarr;'
      }
    },
    changeSort(field) {
      this.sort = field
      if (field == this.sort) {
        this.sortDir = !this.sortDir
      }
      this.sortedProjects()
    },
    sortedProjects() {
      if (!this.sort) { return this.projects }
      let projects = JSON.parse(JSON.stringify(this.projects))
      let direction = 1
      if (this.sortDir) { direction = -1 }
      for (let i = 0; i < projects.length; i++) {
        if (!projects[i][this.sort]) {
          projects[i][this.sort] = ''
        }
      }
      let sorted = projects.sort((a, b) => (a[this.sort] > b[this.sort]) ? direction : direction * -1)
      return sorted
    },
    templateName(template) {
      const templateId = template;
      return this.$store.getters.templates.find(
        (template) => template.id == templateId
      ).name;
    },
    daysLeftClass(daysLeft) {
      if (daysLeft > 7) {
        return 'green-date';
      }
      if (daysLeft > 0) {
        return 'orange-date';
      }
      if (daysLeft < 0) {
        return 'red-date';
      }
      if (daysLeft == 'I dag') {
        return 'red-date';
      }
    },
    formatUsers(users) {
      if (!users) { return }
      let usersArr = this.parseUsers(users)
      if (!usersArr) { return }
      let userString = ''
      for (let i = 0; i < usersArr.length; i++) {
        userString += usersArr[i].name
        if (i !== (usersArr.length - 1)) {
          userString += ', '
        }
      }
      return userString
    },
    parseUsers(teamLeaders) {
      if (teamLeaders == '') { return }
      if (!teamLeaders) { return }
      teamLeaders = teamLeaders.toString()
      teamLeaders = teamLeaders.split(',')
      if (!this.users) { return }
      // return teamLeaders
      let names = []
      for (let i = 0; i < teamLeaders.length; i++) {
        if (this.users.find((x) => x.id == teamLeaders[i])) {
          names.push({
            id: teamLeaders[i],
            name: this.users.find((x) => x.id == teamLeaders[i]).full_name
          })
        }
      }
      return names
    },
    parseDate(date) {
      if (!date) {
        return
      }
      return this.$dayjs(date).format('DD MMM YY');
    },
    getDaysLeft(date, string) {
      if (!date) return;
      // return date
      const datee = this.$dayjs(date);
      const today = this.$dayjs().startOf('day');
      const days = datee.diff(today, 'days');
      let txt = '';
      if (Math.abs(days) == 1) {
        txt = ' dag';
      } else {
        txt = ' dager';
      }
      if (parseInt(days) > 0) {
        txt += ' igjen';
      }
      if (days == 0) return 'I dag';
      if (days < 0) {
        txt += ' siden';
      }
      if (string == true) {
        return `${Math.abs(days)} ${txt}`;
      }
      return days;
    },
    progressExist(template) {
      if (this.progressTemplate[template]) {
        return this.progressTemplate[template];
      } else {
        this.getTemplateProgress(template);
        return this.progressTemplate[template];
        // return 100
      }
    },
    async getTemplateProgress(template) {
      let progress = await this.$http
        .get(`/progress/project/${this.project.id}/template/${template}`)
        .then((value) => {
          const values = value.data.data;
          let yes = values[0]['Ja'];
          let na = values[0]['Utgår'];
          let no = values[0]['Nei'];
          let percentage = (yes + na) / (yes + na + no);
          percentage = Math.floor(percentage * 100);
          this.progressTemplate[template] = percentage;
        });
      return progress;
    },
    random() {
      return Math.floor(Math.random() * 100);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
  },
  props: ['projects', 'users'],
};
</script>

<style scoped>
table {
  width: 100%;
}

th {
  min-width: 15rem;
}

.th--proj-name {
  min-width: 10rem;
}

.th--proj-leader {
  min-width: 15rem;
}

.progress-table {
  min-width: 18rem;
  text-align: center;
}

.prog-bar-container {
  height: 2.5rem;
}

.prog-bar-container span {
  top: 10%;
}
</style>
