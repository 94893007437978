import axios from 'axios'
export default {
    // async getDaata(context) {
    //     return context.dispatch('baseData')
    // },
      async getUserPreferences(context){
        return context.dispatch('userPreferences')
      },

      async setUiPreferencesProjectView(context,payload){
        localStorage.setItem('uiPreferencesProjectView',JSON.stringify(payload))

        context.commit('setUiPreferencesProjectView',{
            uiPreferencesProjectView:payload
        })
    },



    async userPreferences(context,payload){
        console.log("masse IO")
        axios.get(`/api/v1/userpreference/customfields/2`).then(res=>{
            if(!res.data){
                return
            }
            if(!res.data.data){
                return
            }

            // let uiPreferences = JSON.parse(res.data.data)
            let uiPreferences = res.data.data
            localStorage.setItem("uiPreferencesProjectView",JSON.stringify(uiPreferences))
            context.commit("setUiPreferencesProjectView",{
                uiPreferencesProjectView:uiPreferences
            })

         })
        return 
    },



}