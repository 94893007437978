<template>
  <div v-if="true || modelValue" class="input">
    <label for="select">{{ label }}</label>
    <select :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" name="" id="select">
      <option disabled value="">Velg kabel</option>
      <option v-for="material in getMaterials" :key="material.id" :value="material.id">
        {{ material.material_friendly_name || material.material_name }}
      </option>
    </select>
    <div v-if="log" class="log">
      {{ log.name }}
      {{ log.date }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: this.value,
      matr: ['ab'],
    };
  },
  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', this.content);
    },
  },
  computed: {
    getMaterials() {
      return this.$store.getters.materialsByCategory(1);
    },
    isEnabled() {
      if (this.type == 'dyamic') {
        return false;
      }
      if (this.type == 'static' && this.edit == false) {
        return true;
      } else {
        return false;
      }
    },
  },
  emits: ['update:modelValue'],
  props: [
    'modelValue',
    'options',
    'value',
    'label',
    'data',
    'log',
    'type',
    'edit',
  ],
};
</script>

<style scoped></style>
