<template>
    <div class="container">
        <Header title="Hjelp" />

        <div class="guide-box m-top">
            <div @click="toggleExpand('roles')" class="card--guide">
                <h4>Roller</h4>
                <div class="icon">
                    <IconChevronDown v-if="!show.includes('roles')" />
                    <IconChevronUp v-else />
                </div>
            </div>
            <Transition name="fade">
                <div v-if="show.includes('roles')" class="card--guide-expand" id="roles">
                    <div class="guide-sub">
                        <p class="guide-header">Administrator</p>
                        <ul>
                            <li>Administrator kan opprette og deaktivere brukere</li>
                        </ul>
                    </div>
                    <div class="guide-sub">
                        <p class="guide-header">Prosjektleder</p>
                        <ul>
                            <li>Se alle prosjekter</li>
                            <li>Oprette / slette / endre alle prosjekter</li>
                            <li>Legge til brukerer i prosjekt</li>
                        </ul>
                    </div>
                    <div class="guide-sub">
                        <p class="guide-header">Bruker</p>
                        <ul>
                            <li>Kan kun se egene prosjekter</li>
                        </ul>
                    </div>
                    <div class="guide-sub">
                        <p class="guide-header">Teamleder (velges pr prosjekt)</p>
                        <ul>
                            <li>Beskrivelse kommer...</li>
                        </ul>
                    </div>
                </div>
            </Transition>
        </div>

        <div class="guide-box">
            <div @click="toggleExpand('fremgang')" class="card--guide">
                <h4>Fremgang</h4>

                <div class="icon">
                    <IconChevronDown v-if="!show.includes('fremgang')" />
                    <IconChevronUp v-else />
                </div>
            </div>
            <Transition name="fade">
                <div v-if="show.includes('fremgang')" class="card--guide-expand">
                    <p class="guide-sub">Fremgang blir beregnet ut i fra spesifikke felter</p>
                    <div class="guide-sub">
                        <p class="guide-header">Linjearbeid</p>
                        <ul>
                            <li>Ryddet område</li>
                            <li>Droppkabler skjøtet</li>
                        </ul>
                    </div>
                    <div class="guide-sub">
                        <p class="guide-header">Montørarbeid</p>
                        <ul>
                            <li>Grus fyllt</li>
                            <li>Endepropp</li>
                        </ul>
                    </div>
                    <div class="guide-sub">
                        <p class="guide-header">Dokumentasjon</p>
                        <ul>
                            <li>Bilder til Telenor</li>
                            <li>Merking av rør</li>
                            <li>Merkeing av kabler</li>
                            <li>Bilder brett</li>
                        </ul>
                    </div>
                </div>
            </Transition>
        </div>

        <div class="guide-box">
            <div @click="toggleExpand('filer')" class="card--guide">
                <h4>Bilder / Dokumenter</h4>

                <div class="icon">
                    <IconChevronDown v-if="!show.includes('filer')" />
                    <IconChevronUp v-else />
                </div>

            </div>
            <Transition name="fade">
                <div v-if="show.includes('filer')" class="card--guide-expand">
                    <p class="guide-sub">Støttede formater er JPG, PNG, PDF, Excel</p>
                </div>
            </Transition>
        </div>

        <div class="guide-box">
            <div @click="toggleExpand('materiell')" class="card--guide">
                <h4>Materiell</h4>

                <div class="icon">
                    <IconChevronDown v-if="!show.includes('materiell')" />
                    <IconChevronUp v-else />
                </div>

            </div>
            <Transition name="fade">
                <div v-if="show.includes('materiell')" class="card--guide-expand">
                    <div class="flex-p">
                        <p>Materiell som er tiltenkt templaten kommer på forsiden til hver oppføring.</p>
                        <p>Øvrig materiell kan føres på
                            'Materiell' fanen</p>
                        <p>Oppsummering for hele prosjektet kan sees fra prosjektoversikt, og eksporteres som
                            Excel fil.</p>
                    </div>
                </div>
            </Transition>
        </div>

        <div class="guide-box">
            <div @click="toggleExpand('tickets')" class="card--guide">
                <h4>Oppgaver</h4>

                <div class="icon">
                    <IconChevronDown v-if="!show.includes('tickets')" />
                    <IconChevronUp v-else />
                </div>

            </div>
            <Transition name="fade">
                <div v-if="show.includes('tickets')" class="card--guide-expand">
                    <div class="flex-p">
                        <p>Oppgaver kan opprettes fra 'Oppgaver' siden, fra Prosjektet, eller fra oppføringer.</p>
                        <p>Skal oppgaven linkes mot et prosjekt, må den opprettes fra prosjektsiden.</p>
                        <p>Skal oppgaven linkes mot en oppføring, må den opprettes fra 'Oppgaver' fanen inn på den
                            aktuelle
                            oppføringen.</p>
                    </div>
                </div>
            </Transition>
        </div>

        <div class="guide-box">
            <div @click="toggleExpand('export')" class="card--guide">
                <h4>Eksport / Import</h4>

                <div class="icon">
                    <IconChevronDown v-if="!show.includes('export')" />
                    <IconChevronUp v-else />
                </div>

            </div>
            <Transition name="fade">
                <div v-if="show.includes('export')" class="card--guide-expand">
                    <div class="flex-p">
                        <p>Beskrivelse kommer...</p>
                    </div>
                </div>
            </Transition>
        </div>

        <div class="guide-box">
            <div @click="toggleExpand('filter-table')" class="card--guide">
                <h4>Filtrere tabeller</h4>

                <div class="icon">
                    <IconChevronDown v-if="!show.includes('filter-table')" />
                    <IconChevronUp v-else />
                </div>

            </div>
            <Transition name="fade">
                <div v-if="show.includes('filter-table')" class="card--guide-expand">
                    <div class="flex-p">
                        <p>Trykk på "Filter" over tabellen til høyre</p>
                        <p>Velg så hvilket felter du vil bruke under "Filter 1" eller "Filter 2". Standard viser alle</p>
                        <p>Lagre</p>
                        <p>Nå kan du bytte mellom visningene du har lagret, på alle enhetene dine</p>
                    </div>
                </div>
            </Transition>
        </div>

        <div class="guide-box">
            <div @click="toggleExpand('pwa')" class="card--guide">
                <h4>Installere FiberX som egen "App"</h4>

                <div class="icon">
                    <IconChevronDown v-if="!show.includes('pwa')" />
                    <IconChevronUp v-else />
                </div>

            </div>
            <Transition name="fade">
                <div v-if="show.includes('pwa')" class="card--guide-expand">
                    <p class="m-btm"> Om det ikke kommer opp en boks med knapp for å innstallere, eller du har lukket den,
                        kan du innstallere med metodene nedenfor</p>
                    <ul>
                        <li>
                            <p>Chrome:</p>
                            <ul>
                                <li>Trykk på
                                    <svg class="icon icon-color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                        <path
                                            d="M17 14L22 9L20.6 7.6L18 10.2V3H16V10.2L13.4 7.6L12 9L17 14M23 14V16C23 17.1 22.1 18 21 18H14V20H16V22H8V20H10V18H3C1.9 18 1 17.1 1 16V4C1 2.9 1.9 2 3 2H12V4H3V16H21V14H23Z" />
                                    </svg>
                                    i søkeboksen.
                                </li>
                                <li>Trykk enten på på "Innstaller"</li>
                            </ul>
                        </li>

                        <li>
                            <p>Microsoft Edge</p>
                            <ul>
                                <li>Trykk på
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        stroke-width="1.5" stroke="currentColor" data-slot="icon" class="icon icon-flip">
                                        <path stroke-linecap="round" stroke-linejoin="round"
                                            d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
                                    </svg>
                                    i søkeboksen
                                </li>
                                <li>Klikk "Innstallere FiberX"</li>
                            </ul>
                        </li>

                        <li>
                            <p>Chrome Android:</p>
                            <ul>
                                <li>Klikk på
                                    <IconDotsVertical /> i nettleseren
                                </li>
                                <li>Så "Innstaller App"
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>Safari og Chrome iOS/iPadOS:</p>
                            <ul>
                                <li>Trykk på
                                    <IconShare />
                                    og scroll ned til "Legg til på Hjem-skjerm"
                                </li>
                                <li>Trykk "Add" på neste side</li>
                            </ul>
                        </li>

                        <li>
                            <p>Safari:</p>
                            <ul>
                                <li>Trykk på
                                    <IconShare />
                                    og klikk "Legg til i Dock"
                                </li>
                                <li>Trykk "Legg til" i vinduet som åpner seg</li>
                            </ul>

                        </li>
                    </ul>
                    <p class="m-btm">I de fleste nettlesere på PC og Mac kan du klikke på ikonet i søkeboksen, som regel på
                        høyre
                        side, for å installere
                    </p>
                    <p>Trenger du hjelp, ta <a href="#/contact">kontakt</a></p>
                </div>
            </Transition>
        </div>
        <div class="guide-box">
            <div @click="toggleExpand('version')" class="card--guide">
                <h4>Tvinge oppdatering</h4>

                <div class="icon">
                    <IconChevronDown v-if="!show.includes('version')" />
                    <IconChevronUp v-else />
                </div>

            </div>
            <Transition name="fade">
                <div v-if="show.includes('version')" class="card--guide-expand">
                    <div class="flex-p">
                        <p>I de fleste tilfeller holder det å logge ut av brukeren din og lukk nettleser/app</p>
                        <p>Dersom det ikke fungerer, kan du slette data for nettsted. Det er forskjellig fremgangsmåte i de
                            ulike nettleserene </p>
                    </div>
                </div>
            </Transition>
        </div>


    </div>
</template>

<script>
export default {
    data() {
        return {
            show: []
        }
    },
    methods: {
        toggleExpand(menu) {
            if (this.show.includes(menu)) {
                let ind = this.show.findIndex(x => x == menu)
                this.show.splice(ind, 1)
            } else {
                this.show.push(menu)
            }
        }
    }
}

</script>

<style scoped>
.container {
    max-width: 70rem;
}

.guide-box {
    margin-bottom: 2rem;
}

.card--guide {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--bg-light-color);
    padding: 1.8rem;
    border-radius: 9px;
    transition: all 300ms ease;
    cursor: pointer;
    /* white-space: nowrap; */
}

.card--guide h4 {
    margin-bottom: 0;
}

.guide-header {
    margin-bottom: 0.3rem;
    font-weight: 600;
}

.card--guide-expand {
    border-radius: 0 0 9px 9px;
    padding: 2.2rem 1.2rem;
    background: var(--bg-light-color);
    translate: 0 -0.6rem;
}

.guide-sub {
    margin-bottom: 2rem;
    margin-left: 1rem;
}

ul {
    list-style: none;
    padding-left: 1rem;
    margin-bottom: 2rem;
}

ul li ul li {
    margin-bottom: 0.2rem;
}

ul p {
    margin-bottom: 1rem;
}

.flex-p {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 1rem;
}

i {
    font-size: 1.5rem;
    font-weight: 300;
}

.icon {
    height: 2rem;
    color: var(--text-color);
}

.icon-color {
    fill: var(--text-color);
}

.icon-flip {
    transform: scaleY(-1);
}

.icon-share,
.icon-dots-vertical {
    height: 2rem;
}
</style>
