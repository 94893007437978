<template>
    <div class="container">
        <Header title="Dev Table" />

        <div class="setup-box">
            <h5>Template</h5>
            <div class="setup-btns">
                <button @click="template = 1" class="btn btn--outline">Koblingspunkt 1</button>
                <button @click="template = 2" class="btn btn--outline">Linjearbeid 2</button>
                <button @click="template = 3" class="btn btn--outline">Grunnrett 3</button>
                <button @click="template = 8" class="btn btn--outline">Kunde 8</button>
            </div>
            <!-- {{ template }} -->
            <h5>Type</h5>
            <div class="setup-btns">
                <button @click="type = 'tabell'" class="btn btn--outline">Kolonner / Tabell</button>
                <button @click="type = 'entry'" class="btn btn--outline">Entry</button>
            </div>
            <!-- {{ type }} -->
            <p>Template: <span>{{ template }}</span>, Type: <span>{{ type }}</span></p>
            <h5>Fetch</h5>
            <div class="setup-btns">
                <button @click="fetchData" class="btn btn--outline">Hent </button>
                <button @click="updateAll(data)" class="btn btn--outline">Oppdater </button>
            </div>
        </div>

        <div v-if="data && type == 'tabell'">
            <h2 class="table-h">Eksisterende</h2>
            <div class="table-overflowx">
                <table>
                    <thead>
                        <tr>
                            <th>field</th>
                            <th>header</th>
                            <th>type</th>
                            <th>order</th>
                            <th>is_hidden</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in data">
                            <td>{{ row.field }}</td>
                            <td><input class="input" v-model="row.header"></td>
                            <td>
                                <select required v-model="row.type" name="" id="">
                                    <option value=""></option>
                                    <option value="check">Check</option>
                                    <option value="material">Materiell</option>
                                    <option value="status">Status</option>
                                    <option value="progress">Progress</option>
                                </select>
                            </td>
                            <td class="count-flex">
                                <button @click="changeOrder(row, 1.5)"
                                    class="btn btn--outline btn--count">&UpArrow;</button>
                                <span class="count">{{ row.order }}</span>
                                <button @click="changeOrder(row, -1.5)"
                                    class="btn btn--outline btn--count">&DownArrow;</button>
                            </td>
                            <td class="td-check"><input class="input-switch" type="checkbox" true-value="1" false-value="0"
                                    v-model="row.is_hidden">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h2 class="table-h">Legg til</h2>
            <div class="table-overflowx">
                <table>
                    <thead>
                        <tr>
                            <th>Label</th>
                            <th>Komponent</th>
                            <th>Data_name</th>
                            <th>Add</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in info">
                            <td>{{ row.label }}</td>
                            <td>{{ row.component }}</td>
                            <td>{{ row.data_name }}</td>
                            <td class="td-check"><button class="btn btn--outline btn--count" @click="addRow(row)">+</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
  
<script>
import UserService from '@/services/User'
import { mapGetters } from 'vuex';
import axios from 'axios'
export default {
    data() {
        return {
            template: null,
            type: '',
            types: ['', 'check', 'material', 'status', 'progress'],

            data: null,
            info: null,

            pin: null,
            formValid: true,
            password1: '',
            password2: '',
            userService: null,
            userData: {
                email: null,
                pass1: null,
                pass2: null,
                fornavn: null,
                etternavn: null,
            },
            isLoading: false,
            error: null,
        };
    },
    mounted() {
        this.pin = this.$route.query.pin;
        this.email = this.$route.query.email;
        this.userService = new UserService
    },

    methods: {
        sortData() {
            // // return
            this.data.sort(function (a, b) {
                return a.order - b.order;
            });

            // return
            for (let i = 0; i < this.data.length; i++) {
                this.data[i].order = i + 1
            }

        },
        async updateAll(data) {

            await axios.post(`/api/v1/admin/setup/updatetemplate`, data)
                .then(res => console.log(res))
        },
        changeOrder(row, order) {
            // return
            let data = this.data
            // let i =data.indexOf(data=>data.id==row.id)
            // let i =data.findIndex(data=>data.id==row.id)
            let i = data.findIndex(data => data.order == row.order)

            if (i == -1) {
                return
            }
            this.data[i].order = parseInt(this.data[i].order) + order
            // this.data[i].order=3
            this.sortData()
        },
        addRow(row) {
            this.data.push({
                template_id: row.template_id,
                field: row.data_name,
                header: row.label,
                order: this.data[this.data.length - 1].order + 1
            })
        },
        async fetchData() {
            if (this.type == 'tabell') {

                await axios.get(`/api/v1/admin/setup/template/${this.template}'/type/${this.type}`)
                    .then(res => {
                        this.data = res.data.columns
                        this.info = res.data.info

                        this.sortData()
                    })
                // .then()
                //  this.data=res
                // return axios.get(`/api/v1/template/${templateId}/entry/${entryId}`).then(res => res.data)
                // this.data=res
                // console.log(res)


            }
        },

    },
};
</script>
  
<style scoped>
.container {
    max-width: 170rem;
}

.setup-box {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2rem;
}

h5 {
    font-size: 1.8rem;
    margin-top: .5rem;
}

.setup-btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.table-h {
    margin: 2rem 0;
}

.input {
    width: 18rem;
}


select {
    width: 12rem;
}

.count-flex {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.count {
    padding: 1rem;
    border: 1px solid var(--border-color);
    border-radius: 9px;
    width: 4rem;
    text-align: center;
}

.btn--count {
    padding: .3rem 1.5rem;
    font-size: 1.8rem;
    font-weight: 300;
}

.td-check {
    width: 5rem;
    text-align: center;
}
</style>
  