// plugins/i18n.js
import { EventBus } from '@/assets/event-bus.js'
export default {
    install: (app, options) => {
      // inject a globally available $translate() method
      app.config.globalProperties.$loader = (state) => {
        // retrieve a nested property in `options`
        // using `key` as the path
        EventBus.emit('loader', {
          state:state
      });
      return
        // return 'okid'
        return key.split('.').reduce((o, i) => {
          if (o) return o[i]
        }, options)
      }
    }
  }