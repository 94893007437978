<template>
  <div v-if="deferredPrompt" class="prompt-container">
    <div class="prompt-box">
      <img :src="imageUrl()">
      <div class="prompt-right">
        <button @click="install" id="custom-install-button" class="btn btn--install">Installer app</button>
        <div @click="dismiss" class="close-prompt">
          <IconClose />
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      deferredPrompt: null
    }
  },
  created() {

    if (localStorage.getItem('pwaPrompt')) {
      let isDismissed = localStorage.getItem('pwaPrompt')
      if (isDismissed) {
        this.deferredPrompt = null
      } else {
      }
    } else {
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });

    }

    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },

  methods: {
    async dismiss() {
      this.deferredPrompt = null;
      localStorage.setItem('pwaPrompt', true)
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    imageUrl() {
      let images = require.context('../../assets/', false, /\.png$/)
      let darkmode = window.matchMedia('(prefers-color-scheme: dark)').matches
      if (darkmode) {
        return images('./Darkmode500.png')
      } else {
        return images('./Lightmode500.png')
      }
    },
  }
}


  ;


</script>
  
<style scoped>
.prompt-container {
  width: 70%;
  position: fixed;
  bottom: 5%;
  left: 50%;
  translate: -50% 0;
  background: var(--bg-light-color);
  border-radius: 25px;
  padding: 1rem 3rem;
}

.prompt-ios {
  width: 70%;
  position: absolute;
  bottom: 10%;
  left: 50%;
  translate: -50% 0;
  background: var(--bg-light-color);
  border-radius: 25px;
  padding: 1rem 3rem;
}

.prompt-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prompt-right {
  display: flex;
  gap: 3rem;
  flex-direction: row;
  align-items: center;
}

.close-prompt {
  cursor: pointer;
}

img {
  height: 3rem;
}

.btn--install {
  color: var(--text-color);
  text-transform: uppercase;
  font-weight: 500;
  background: var(--accent-color);
  border-radius: 25px;
}


@media (max-width: 800px) {
  .prompt-container {
    width: 90%;
  }
}

@media (hover: hover) and (max-width: 550px) {
  .prompt-container {
    bottom: 10%;
  }
}

@media (max-width: 410px) {
  .prompt-container {
    padding: 1rem 1rem;
  }

  .prompt-right {
    gap: 1rem;
  }
}

@media (max-width: 330px) {
  .prompt-container {
    padding: .5rem .5rem;
  }

  .prompt-right {
    gap: 0;
  }

  button {
    scale: .8;
  }

  img {
    scale: .8;
  }
}
</style>