export default {
    templateColumns: (state) => (id) => {
        id=Number(id)
        let columns = state.templates.find(thing => thing.id == id).columns
        return columns
        return state.templates.find(thing => thing.id === id).columns

    },
    templateFields: (state) => (id) => {
        return state.templates.find(thing => thing.id === id).fields
    },
    customSort: (state) => {
        return state.customSort
    },
    currentSortArray: (state) => {
        return state.currentSort
    },
    columnsByTemplateAndSort: (state) => (template, sort) => {

        let allCustoms = state.customSort

        let i = -1
        for (let j = 0; j < allCustoms.length; j++) {
            if (allCustoms[j].template == template && allCustoms[j].sort == sort) {
                i = j
            }
        }
        if (i > -1) {

            return allCustoms[i].columns
        } else {
            
        }
        return []
        return [0]
        return [1]
        return [30, 31]

    },
    currentSort: (state) => (template) => {
        let a = state.currentSort.find(thing => thing.template == template)
        if (!a) {
            return 0
        }
        if (!a) {
            return 0
        } else {
            return a.sort
        }
        return 1
    },
    templateColumnsCustom: (state) => (id, custom) => {
        id=Number(id)
        let columns = state.templates.find(thing => thing.id === id).columns
        if (custom == 0) {
            columns = columns.filter((columns) => columns.is_hidden == false)

        } else {
            columns = columns.filter((columns) => custom.includes(columns.id))

        }

        return columns

    },
    baseTemplates: (state) => {
        return state.templates
    },
    baseChecklists: (state) => {
        return state.checklists
    },
    templateBoxes: (state) => (id) => {
        return state.templates.find(thing => thing.id === id).boxes
    },
    materialsByCategory: (state) => (id) => {
        return state.materials.filter(thing => thing.category == 'Fiber kabel')
        return state.materials
    },
    ticketTypes: (state) => {
        return state.ticketType
    },
    ticketStatus2: (state) => {
        return state.ticketStatus2
    },
    ticketType: (state) => (id) => {
        return state.ticketType.find(thing => thing.id === id)
    },
    materials: (state) => {
        return state.materials
    },
    materialName: (state) => (id) => {
        return state.materials.find(thing => thing.id === id)
    }
}