let timer

export default {
    async login(context, payload) {
       return context.dispatch('auth', {
            ...payload,
            mode: 'login'
        })
    },
    async signup(context, payload) {
       return context.dispatch('auth', {
            ...payload,
            mode: 'signup'
        })
    },
    async auth(context, payload) {
        const mode = payload.mode
        let url = process.env.VUE_APP_BASEURL+"/api/v1/loginUser"
        if (mode === 'signup') {
            url = process.env.VUE_APP_BASEURL+"/api/v1/registerUser"
        }
        const response = await fetch(url, {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                email: payload.email,
                password: payload.password,
            })
        })
        .catch((err)=>{
            const error ={message: 'Fikk ikke kontakt med server'}
            throw error
        })
        const responseData = await response.json()

        if (!response.ok) {
            // const error = 'Feil med auth'
            const error = new Error(responseData.message || 'Feil med auth.')
            throw error;
        }
        const expiresIn = +responseData.expiresIn*1000
        const expirationDate = new Date().getTime()+expiresIn
        localStorage.setItem('tokenExpiration',expirationDate)
        localStorage.setItem('token',responseData.token)
        localStorage.setItem('userId',responseData.id)
        localStorage.setItem('firstName',responseData['first_name'])
        localStorage.setItem('lastName',responseData['last_name'])
        localStorage.setItem('email',responseData['email'])
        localStorage.setItem('role',responseData['role'])
        localStorage.setItem('companyName',responseData['company_name'])
       timer = setTimeout(function(){
            context.dispatch('autoLogout')
        },expiresIn)

        context.commit('setUser', {
            token: responseData.token,
            userId: responseData.id,
            firstName: responseData['first_name'],
            lastName: responseData['last_name'],
            email: responseData['email'],
            role: responseData['role'],
            companyName:responseData['company_name'],
            tokenExpiration: responseData.expirationDate
        })
        // $router.push('/')
    },
    tryLogin(context){
        const token = localStorage.getItem('token')
        const userId = localStorage.getItem('userId')
        const firstName = localStorage.getItem('firstName')
        const lastName = localStorage.getItem('lastName')
        const email = localStorage.getItem('email')
        const role = localStorage.getItem('role')
        const tokenExpiration = localStorage.getItem('tokenExpiration')
        const companyName = localStorage.getItem('companyName')

        const currentSort = JSON.parse(localStorage.getItem('currentSort'))
        const customSort = JSON.parse(localStorage.getItem('customSort'))

        //Putt ett annet sted...?
        const templates = JSON.parse(localStorage.getItem('templateData'))
        const materials = JSON.parse(localStorage.getItem('materialData'))
        const checklists = JSON.parse(localStorage.getItem('checklistData'))

        const uiPreferencesProjectView = JSON.parse(localStorage.getItem('uiPreferencesProjectView'))


        ////////////////////////////

        const expiresIn=+tokenExpiration- new Date().getTime()

        if(expiresIn<0){
            return;
        }

        timer = setTimeout(function(){
            context.dispatch('autoLogout');
        },expiresIn)

        if(customSort){
            context.commit('setCustomSort',{
                sort:customSort
            })
        }
        if(currentSort){
            context.commit('setCurrentSort',{
                sort:currentSort
            })
        }

        if(uiPreferencesProjectView){
            context.commit('setUiPreferencesProjectView',{
                uiPreferencesProjectView:uiPreferencesProjectView
            })
        }

        //Putt ett annet sted..?
        if(templates){
            context.commit('setBasedata', {
                templates: templates,
                materials:materials,
                checklists:checklists
            })
        }
        ////////////////////////////

        if(token&&userId&&firstName&&lastName){
            context.commit('setUser',{
                token:token,
                userId:userId,
                firstName:firstName,
                lastName:lastName,
                email:email,
                companyName:companyName,
                role:role,
                tokenExpiration:tokenExpiration,
                customSort:customSort,
                currentSort:currentSort,
                uiPreferencesProjectView:uiPreferencesProjectView
            })
        }
    },
    autoLogout(context){
        context.dispatch('logout')
        context.commit('setAutoLogout')
    },
    logout(context) {
        localStorage.removeItem('token')
        localStorage.removeItem('userId')
        localStorage.removeItem('firstName')
        localStorage.removeItem('email')
        localStorage.removeItem('role')
        localStorage.removeItem('lastName')
        localStorage.removeItem('tokenExpiration')
        localStorage.removeItem('customSort')
        localStorage.removeItem('companyName')
        localStorage.removeItem('uiPreferences')
        localStorage.removeItem('uiPreferencesProjectView')

        console.log(context)
        clearTimeout(timer)
        context.commit('clearPreferences',{})
        context.commit('setUser', {
            token: null,
            userId: null,
            firstName: null,
            email: null,
            role: null,
            lastName: null,
            tokenExpiration: null,
            companyName: null,
   
        })
    }

}