<template>
  <div class="container">
    <Header :title="checkTitle()" />
    <div class="mat-top">
      <button @click="exportMaterial()" class="btn btn--outline btn--icon">
        <IconDownload />
        Eksporter forbruk
      </button>

    </div>


    <Header class="mat-top" title="Materiell" />
    <div class="table-overflowx">
      <table v-if="materialSummaries && materialLogs">
        <thead>
          <tr>
            <th>Materiallnavn</th>
            <th class="table-small">Brukt</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="summary in materialSummaries" :key="summary.id">
            <td>{{ summary.material_name }}</td>
            <td v-if="materialLogs" class="table-small">
              {{ calculateUsed(summary.material_id, 'out') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <Header class="mat-top" title="Logg" />
    <div class="table-overflowx">
      <table class="material-log-table" v-if="materialLogs">
        <thead>
          <tr>
            <th>Materiallnavn</th>
            <th>Kommentar</th>
            <th>Bruker</th>
            <th class="table-small">Antall</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in materialLogs" :key="log.id">
            <td>{{ log.material_name }}</td>
            <td>{{ log.comment }}</td>
            <td>{{ log.name }}</td>
            <td class="table-small">{{ log.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import MaterialService from '@/services/Material';
export default {
  created() {
    this.materialService = new MaterialService();
    this.fetchMaterials();
    this.getMaterialSummaries();
    this.getMaterialLogs();
    // this.checkProjectName()
  },

  methods: {
    checkTitle() {
      if (localStorage.getItem('projectInfo')) {
        let project = JSON.parse(localStorage.getItem('projectInfo'))
        if (project.id == this.$route.params.project_id) {
          return `${project.name} / Materialer`
        }
      }
      return 'Materialer'
    },

    exportMaterial() {
      this.$loader(true)
      this.materialService
        .exportMaterialUsage(this.$route.params.project_id)
        .then(function (response) {
          let headerLine = response.headers['content-disposition'];
          headerLine = headerLine.split('=')[1];
          let blob = new Blob([response.data], { type: 'application/xlsx' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = headerLine;
          link.click();
        });
      this.$loader(false)
    },
    calculateUsed(materialId, operation) {
      let log = this.materialLogs.filter((a) => a.material_id == materialId);
      let quantity = 0;
      for (let i = 0; i < log.length; i++) {
        if (operation == 'out') {
          if (log[i].quantity > 0) {
            quantity += log[i].quantity;
          }
        }
        if (operation == 'in') {
          if (log[i].quantity < 0) {
            quantity += log[i].quantity;
          }
        }
      }
      return Math.abs(quantity);
    },
    getMaterialLogs() {
      this.materialService
        .getMaterialLogs(this.$route.params.project_id)
        .then((res) => (this.materialLogs = res));
    },
    fetchMaterials() {
      this.materialService.getMaterials().then((res) => (this.materials = res));
    },
    getMaterialSummaries() {
      this.materialService
        .getMaterialSummaries(this.$route.params.project_id)
        .then((res) => (this.materialSummaries = res));
    },
    myUploader(event) {
      const file = event.files[0];
      let data = {
        project_id: this.$route.params.project_id,
      };
      this.materialService
        .uploadMaterialsExcel(data, file)
        .then(function (response) {
        });
    },
  },
  data() {
    return {
      loading: false,
      materials: null,
      materialSummaries: null,
      materialService: null,
      materialLogs: null,
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 180rem;
}

.mat-top {
  margin: 2rem 0;
}

.icon-download {
  height: 2rem;
}

table {
  font-size: 1.2rem;
}

.table-small {
  width: 2rem;
  text-align: end;
}

tr>th {
  width: 10rem;
  cursor: default;
}

tr>td {
  cursor: default;
}
</style>
