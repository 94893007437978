<template>
    <div class="container">
        <Header class="m-btm" title="Changelog" />

        <div class="card">
            <h3 class="update-version">V1.1.4</h3>
            <div class="update-info">
                <p>Fikset feil med oppgaver som ikke har noen til leads/ansvarlige.</p>
            </div>
        </div>
        <div class="card">
            <h3 class="update-version">V1.1.3</h3>
            <div class="update-info">
                <p>Lagt til mulighet for å velge hvilke datoer som vises på kort og i tabellen på prosjektsiden</p>
            </div>
        </div>
        <div class="card">
            <h3 class="update-version">V1.1.2</h3>
            <div class="update-info">
                <p>Fikset feil med justering av ferdigstillingsdato, krasjet tidligere. (Ticket ID227)</p>
            </div>
        </div>

        <div class="card ">
            <h3 class="update-version">V1.1.1</h3>
            <div class="update-info">
                <p>Fikset en feil med materiellføring, hvis man "Lagret", endret antall, også "Lagret" en gang til, uten å
                    refreshe siden først. (Ticket ID220)</p>
            </div>
        </div>

        <div class="card">
            <h3 class="update-version">V1.1</h3>
            <div class="update-info">
                <p>Varsler: Dette er under utvilking, og det vil komme mer funksjonalitet
                    fremover. Nå får du varsler om du har fått tildelt en ny oppgave, og om denne har en oppdatering. </p>
                <p>Filtering på oppgaver: Du vil nå se de som er tildelt deg i en egen fane. </p>
                <p>Produksjon: Ny del hvor du får en rapport på hva som er gjort på prosjektene. Grafer og excel eksport
                    kommer.</p>
                <p>Fremgang dashbord: En enklerere fremgangsvisning. Du finner den inne på Administrator</p>
                <p>Fjernet "dager igjen" når Tjeneste levering har startet</p>
                <p>Fargetema kan overstyres, fra "Min profil"</p>
                <p>Det er nå samme 'Lagre' knapp på materiell og oppføringen</p>
                <p>Diverse feilrettinger og forbedringer </p>
            </div>
        </div>

        <div class="card">
            <h3 class="update-version">V1.0</h3>
            <div class="update-info">
                <p>Det er lagt til rette for å kunne filtrere de feltene man har behov for i tabellene</p>
                <p>Bilder blir åpnet i ny fane </p>
                <p>Diverse feilrettinger og forbedringer </p>
            </div>
        </div>

        <div class="card">
            <h3 class="update-version">V0.1.5C</h3>
            <div class="update-info">
                <p>Diverse feilrettinger og forbedringer </p>
            </div>
        </div>

    </div>
</template>

<script>
</script>

<style scoped>
.container {
    max-width: 70rem;
}

.card {
    gap: 1rem;
    margin-bottom: 2rem;
}

.update-info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-left: 1rem;
}
</style>
