// plugins/i18n.js
import { EventBus } from '@/assets/event-bus.js'
export default {
    install: (app, options) => {
      // inject a globally available $translate() method
      app.config.globalProperties.$toast = (type,title,message) => {
        // retrieve a nested property in `options`
        // using `key` as the path
        EventBus.emit('notify', {
          type: type,
          title: title,
          message: message,
          action: 'close'
      });
      return
        // return 'okid'
      }
    }
  }