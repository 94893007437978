import axios from 'axios'

export default class EntryService{
    getContacts(){
        return axios.get(`/api/v1/contacts`).then(res=>res.data)
    }
    postContact(data){
        return axios.post('/api/v1/contact',data).then(res=>res.data)
    }
    deleteContact(id){
        return axios.delete(`/api/v1/contact/id/${id}`).then(res=>res.data)
    }
    updateContact(id,contact){
        return axios.put(`/api/v1/contact/id/${id}`,contact).then(res=>res.data)
    }
    postFeedback(data){
        return axios.post('/api/v1/feedback',data).then(res=>res.data)
    }

}
