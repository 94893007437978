<template>
  <div class="card card--hover">

    <div class="proj-card-top">
      <h3 id="title">
        {{ project.project_name }}
      </h3>
      <p v-if="project.project_leader_name" class="proj-lead">
        <span>Prosjektleder:</span>
      <p v-for="user in parseUsers(project.project_leader)">
        {{ user.name }}
      </p>
      </p>
    </div>
    <div class="proj-card-btm">
      <div v-if="project.project_finished == 1">
        <p class="green-txt">Prosjekt ferdig</p>
      </div>

      <div v-else>
        <!--Viser Datoer -->
        <!-- Object inneholder key og navn -->
        <div class="card-dates" v-for="date in dates">
          <div v-if="project[date.data] && this.$store.getters.showDateCard([date.data])" class="footer-title">
            <p>{{ date.label }}</p>
            <div class="proj-date">
              <p class="green-txt" v-if="project.service_delivered && date.data == 'service_delivery' == 1">TL Startet</p>
              <p v-else-if="project[date.data]" :class="daysLeftClass(getDaysLeft(project[date.data], false))">
                {{ getDaysLeft(project[date.data], true) }}
              </p>
              <p class="log">
                {{ parseDate(project[date.data]) }}
              </p>
            </div>
          </div>
        </div>
      </div>


    </div>
    <div class="proj-card-footer">
      <div v-if="project.progress && project.progress" class="proj-card-prog-container">
        <span>{{ calculatePercentage(project.progress.totalProgress) }}</span>
        <div :style="'width:' + calculatePercentage(project.progress.totalProgress, 'css')" class="proj-card-prog-bar"
          v-if="project.progress">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectService from '@/services/Project';
export default {
  data() {
    return {
      dates: [
        {
          data: 'service_delivery',
          label: 'Første tjenesteleveranse'
        },
        {
          data: 'technically_finished',
          label: 'Teknisk ferdig'
        },
        {
          data: 'deadline',
          label: 'Ferdigstillingsdato'
        }
      ],
      rawProgress: null,
      progressTemplate: {},
      projectService: null,
    };
  },
  computed: {
    templates() {
      return this.project.templates.split(',');
    },
  },
  created() {
    this.projectService = new ProjectService();
    // this.getProjectProgress();
    this.filterDates()
  },
  methods: {
    filterDates() {
      let preferences = this.$store.getters.showProjectCardView
      console.log(preferences)
      let newDates = []
      if (!preferences) {
        this.dates = newDates
        return
      }
      for (let i = 0; i < this.dates.length; i++) {
        if (preferences[this.dates[i].data]) {
          console.log("yep")
          newDates.push(this.dates[i])
        } else {
          console.log("nop")

        }
      }
      this.dates = newDates
    },
    calculatePercentage(progress, type) {
      if (!progress) {
        return ''
      }

      const sum = progress.yes + progress.no + progress.na
      const finished = progress.yes + progress.na
      if (isNaN(Math.floor(finished / sum * 100))) {
        if (type == 'css') {
          return '0%'
        }
        return "Ingen oppføringer"
      }
      return Math.floor(finished / sum * 100) + "%"
    },
    templateName(template) {
      const templateId = template;
      return this.$store.getters.templates.find(
        (template) => template.id == templateId
      ).name;
    },
    daysLeftClass(daysLeft) {
      if (daysLeft > 7) {
        return 'green-date';
      }
      if (daysLeft > 0) {
        return 'orange-date';
      }
      if (daysLeft < 0) {
        return 'red-date';
      }
      if (daysLeft == 'I dag') {
        return 'red-date';
      }
    },
    parseDate(date) {
      return this.$dayjs(date).format('DD MMM YY');
    },
    getDaysLeft(date, string) {
      if (!date) return;
      // return date
      const datee = this.$dayjs(date);
      const today = this.$dayjs.utc().startOf('day');
      const days = datee.diff(today, 'days');
      let txt = '';
      if (Math.abs(days) == 1) {
        txt = ' dag';
      } else {
        txt = ' dager';
      }
      if (parseInt(days) > 0) {
        txt += ' igjen';
      }
      if (days == 0) return 'I dag';
      if (days < 0) {
        txt += ' siden';
      }
      if (string == true) {
        return `${Math.abs(days)} ${txt}`;
      }
      return days;
    },
    progressExist(template) {
      if (this.progressTemplate[template]) {
        return this.progressTemplate[template];
      } else {
        this.getTemplateProgress(template);
        return this.progressTemplate[template];
      }
    },
    parseUsers(teamLeaders, maxLength) {
      if (teamLeaders == '') { return }
      if (!teamLeaders) { return }
      teamLeaders = teamLeaders.toString()
      teamLeaders = teamLeaders.split(',')
      if (!this.users) { return }
      let names = []
      for (let i = 0; i < teamLeaders.length; i++) {
        if (names.length == maxLength) { break }
        if (this.users.find((x) => x.id == teamLeaders[i])) {
          names.push({
            id: teamLeaders[i],
            name: this.users.find((x) => x.id == teamLeaders[i]).full_name
          })
        }
      }
      return names
    },
    getProjectProgress() {

      this.projectService
        .getProjectProgress(this.project.id)
        .then((result) => (this.rawProgress = result));
    },
    async getTemplateProgress(template) {
      let progress = await this.$http
        .get(`/progress/project/${this.project.id}/template/${template}`)
        .then((value) => {
          const values = value.data.data;
          let yes = values[0]['Ja'];
          let na = values[0]['Utgår'];
          let no = values[0]['Nei'];
          let percentage = (yes + na) / (yes + na + no);
          percentage = Math.floor(percentage * 100);
          this.progressTemplate[template] = percentage;
        });
      // return percentage
      return progress;
      // return 89
    },
    random() {
      return Math.floor(Math.random() * 100);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
  },
  props: ['project', 'users'],
};
</script>

<style scoped>
.card {
  min-height: 16rem;
  max-height: 36rem;

  max-width: 40rem;
  min-width: 30rem;
  justify-content: space-between;
  cursor: pointer;
}

.proj-card-top {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}

.proj-lead {
  display: flex;
  flex-direction: column;
}

.proj-lead span {
  font-weight: 600;
}

.proj-card-btm {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.card-dates {
  margin-bottom: .5rem;
}

.proj-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.log {
  justify-self: flex-end;
  font-size: 1.2rem;
}

.proj-card-prog-container {
  position: relative;
  height: 2.8rem;
  border-radius: 9px;
  border: 1px solid var(--border-color);
  margin-top: 1rem;
}

.proj-card-prog-container span {
  position: absolute;
  top: 20%;
  right: 50%;
  translate: 50% 0;
  white-space: nowrap;
}

.proj-card-prog-bar {
  background: var(--green-progress);
  border-radius: 9px;
  height: 100%;
}

@media (max-width: 1550px) {
  .card {
    max-width: 100%;
  }
}
</style>
