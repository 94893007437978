<template>
    <Transition name="slide">
        <div @click="postChanges()" v-if="changesExist == true && hideSave == false" class="popup">
            <span class="btn--icon">
                <IconSave /> Lagre
            </span>
        </div>
    </Transition>


    <div v-if="data" class="container">
        <Header :title="data[0].project_name + ' / Sjekklister'" />


        <div class="container-proj-check m-top">

            <div class="card" v-for="list in data">
                <h3>{{ checklists.find(x => x.checklist_name == list.checklist).name }}</h3>
                <div v-for="field in checklists.find(x => x.checklist_name == list.checklist).fields">
                    <component :label="field.label" :data="list[field.data_name]" v-model="list[field.data_name]"
                        :options="options" :log="logEntry(field.data_name)" :is="field.component">
                    </component>
                </div>
            </div>


            <!-- <div v-if="logs"> -->

            <div v-if="logs.length > 0" class="card">
                <h3>Log</h3>
                <div v-for=" log in logs" :key="log.if" class="log-box">
                    <p>
                        <span>{{ log.user }}</span>: <span>{{ dataName(log.field, log.action, "field",
                            log.checklist)
                        }}</span> -> <span>{{ dataName(log.field, log.action, "action", log.checklist) }}</span>
                    </p>
                    <p>{{ $dayjs(log.created_at).format('DD.MMMYY, HH:mm') }}</p>
                </div>
            </div>
            <!-- </div> -->


        </div>



    </div>
    <div v-show=false>
        {{ changes() }}
    </div>
</template>


<script>
import SimpleCheckboxField from '@/components/DataTypes/SimpleCheckboxField.vue';
import CheckboxField from '@/components/DataTypes/CheckboxField.vue';
import StatusField from '@/components/DataTypes/StatusField.vue';
import TextBoxField from '@/components/DataTypes/TextBoxField.vue';
import ProjectService from '@/services/Project'
export default {
    mounted() {
        this.projectService = new ProjectService
        this.fetchData()
        this.changes()
        this.fetchLog()
    },
    data() {
        return {
            projectService: null,
            data: null,
            checklists: this.$store.getters.baseChecklists,
            copy: null,
            logs: null,
            changesExist: false,
            options: [
                { id: 0, name: 'Ikke påbegynt' },
                { id: 1, name: 'Sendt' },
                { id: 2, name: 'Under arbeid' },
                { id: 3, name: 'Godkjent' },
                { id: 4, name: 'Avslått' },
                { id: 5, name: 'Utgår' }
            ],
            hideSave: false
        }
    },
    methods: {
        dataName(name, rawAction, value, checklist) {
            // return checklist//FF_soknad
            // // return rawAction //1, 2, 0
            // return
            let action = ''

            //Sjekk hvilken sjekklistetemplate som er brukt
            let checklistTemplate = this.checklists.find(x => x.id == checklist)
            if (!checklistTemplate) { return }
            let fieldName = checklistTemplate.fields.find(x => x.data_name == name)
            if (value == "field") {
                return fieldName.label
            }

            if (fieldName.component == 'CheckboxField') {
                switch (parseInt(rawAction)) {
                    case 0:
                        return 'Nei'
                    case 1:
                        return 'OK'
                    case 2:
                        return 'Utgår'
                    default:
                        break;
                }
            }
            if (fieldName.component == 'StatusField' && fieldName.options == "application") {
                return this.options.find(x => x.id == rawAction).name
            }
            if (fieldName.component == 'TextBoxField') {
                return '"' + rawAction + '""'
            }

            if (value == 'action') {
                return action
            }

            return
            let fields = this.fields;
            let search = fields.find((thing) => thing.dataName == name);
            let label = "";
            //   let action = "";
            if (search && search.label) {
                label = search.label;
            } else {
                switch (name) {
                    case "prioritert":
                        label = "Prioritert";
                        action = rawAction == 1 ? "Ja" : "Nei";
                        break;
                    case "utgaar":
                        label = "Utgår";
                        action = rawAction == 1 ? "Ja" : "Nei";
                        break;

                    default:
                        label = name;
                        break;
                }
            }
            //Sjekker hvis Felttype er static/dynamic/annet (Rå data, skal ikke konverteres)
            if (search && ["TextField", "DropdownField"].includes(search.component)) {
                action = rawAction;
            } else if (search && ["CheckboxField", "SimpleCheckboxField"].includes(search.component)) {
                // action='fuckwit'
                const choices = ["Nei", "Ja", "Utgår"];
                action = choices[parseInt(rawAction)];
            }
            if (value == "action") {
                return action;
            } else {
                return label;
            }

        },
        postChanges() {
            this.$loader(true)
            let checklists = this.changes()
            this.projectService
                .updateChecklists(checklists)
                .then((res) => {
                    this.$toast(res.status, res.message)
                    this.$loader(false)
                    this.fetchData()
                    this.fetchLog()
                })
        },
        changes() {
            this.changesExist = false
            let data = this.data;
            let copy = this.copy;
            let changes = [];
            if (!data) { return }
            for (let i = 0; i < data.length; i++) {

                for (let j = 0; j < Object.keys(data[i]).length; j++) {

                    if (Object.values(data[i])[j] != Object.values(copy[i])[j]) {
                        if ((changes.findIndex(x => x.table == data[i].checklist)) == -1) {
                            changes.push({
                                project: this.$route.params.id,
                                checklistId: this.checklists.find(x => x.checklist_name == data[i].checklist).id,
                                table: data[i].checklist,
                                id: data[i].id,
                                changes: {}
                            })
                        }
                        this.changesExist = true
                        let index = changes.findIndex(x => x.table == data[i].checklist)
                        changes[index].changes[Object.keys(data[i])[j]] = Object.values(data[i])[j]

                    }
                }
            }
            return changes;
            return [1]
        },
        listTitle(list) {
            return list
            const listName = Object.keys(list)
            return listName
        },
        fetchLog() {
            this.projectService
                .getChecklistLog(this.$route.params.id)
                .then((res) => {
                    this.logs = res.data
                })
        },
        logFunction(log) {
            this.rawLog = log;
            let entryLog = {};
            for (let i = 0; i < log.length; i++) {
                if (!entryLog[log[i].field]) {
                    let dato = this.$dayjs(log[i].created_at).format('DD.MMMYY, HH:mm');
                    let logObject = {
                        action: log[i].action,
                        user: log[i].user,
                        date: dato,
                    };
                    entryLog[log[i].field] = logObject;
                }
            }

            this.log = entryLog;
        },
        logEntry(entry) {
            if (!this.logs) { return }
            let rawLog = this.logs.find(x => x.field == entry)
            if (rawLog) {
                let log = {
                    name: rawLog.user,
                    date: this.$dayjs(rawLog.created_at).format('DD.MM.YY HH:mm')
                }
                return log
            }
            return
            return 'oki'

        },

        logExist(field) {
            if (this.log && this.log[field]) {
                return {
                    name: this.log[field].user,
                    date: this.log[field].date,
                };
            } else {
                return null;
            }
        },
        fetchData() {
            this.projectService
                .getChecklists(this.$route.params.id)
                .then((res) => {
                    this.data = res.data
                    this.copy = JSON.parse(JSON.stringify(this.data))
                })
        }
    },
    components: {
        SimpleCheckboxField,
        CheckboxField,
        StatusField,
        TextBoxField
    },
    beforeRouteLeave(to, from, next) {
        if (this.changes().length) {
            const answer = window.confirm(
                "Vil du virkelig forlate siden? Du har ulagrede endringer!"
            );
            if (answer) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    },
}
</script>


<style scoped>
.container {
    max-width: 120rem;
}

.container-proj-check {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
}

.card {
    gap: 2rem;
    padding: 2rem 3rem;
}

.input {
    width: 20rem;
}

.log-box {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: 1px solid var(--border-color);
    padding: 1rem;
    border-radius: 9px;
}

.log-box span {
    font-weight: 500;
}

textarea {
    width: 90%;
}

@media (max-width: 880px) {
    .container-proj-check {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}
</style>