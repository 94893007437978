export default {
    state() {
        return {
  
        }
    },
    getters: {
        findTemplateFields: (state) => (id) => {
            return state.templates.find(thing => thing.id === id)
        },
        findTemplateColumns: (state) => (id) => {
            return state.tables.find(thing => thing.id === id)
        }
    }
};