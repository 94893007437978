<template>
  <div class="input">
    <label :for="label">{{ label }}</label>

    <select :id="label" @input="$emit('update:modelValue', $event.target.value)" :value="modelValue">
      <!-- <option v-show="false" value="">Velg status</option> -->
      <option :value="option" v-for="option in options">
        {{ option }}
      </option>
    </select>
    <div v-if="log" class="log">
      {{ log.name }}
      {{ log.date }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit('update:modelValue', this.content);
      // this.$emit('input', this.content);
    },
  },
  computed: {
    isEnabled() {
      if (this.type == 'dynamic') {
        return false;
      }
      if (this.type == 'static' && this.edit == false) {
        return true;
      } else {
        return false;
      }
    },
  },
  emits:['update:modelValue'],
  props: [
    'modelValue',
    'options',
    'value',
    'label',
    'data',
    'log',
    'type',
    'edit',
  ],
};
</script>

<style scoped>

</style>
