<template>
  <div class="container">
    <div class="login-box">
      <h2 class="heading--login">Nytt passord</h2>

      <div class="input">
        <label for="password1">Nytt passord</label>
        <input id="password1" v-model="password1" type="password" placeholder="Min. 8 tegn" autocomplete="new-password" />
      </div>
      <div class="input">
        <label for="password2">Gjenta passord</label>
        <input id="password2" v-model="password2" type="password" placeholder="Min. 8 tegn" autocomplete="new-password" />
        <p class="log">Passord må ha minst 8 tegn, minst en stor og en liten bokstav, og et tall. </p>
      </div>
      <div>
        <button @click="updatePassword()" class="btn btn--full btn--icon">
          <IconCheck />
          Sett nytt passord
        </button>
      </div>
    </div>


  </div>
</template>

<script>
import UserService from '@/services/User'
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      pin: null,
      formValid: true,
      password1: '',
      password2: '',
      userService: null,
      userData: {
        email: null,
        pass1: null,
        pass2: null,
        fornavn: null,
        etternavn: null,
      },
      isLoading: false,
      error: null,
    };
  },
  mounted() {
    this.pin = this.$route.query.pin;
    this.email = this.$route.query.email;
    this.userService = new UserService
  },
  computed: {
    ...mapGetters(['allstate', 'isAuthenticated']),
  },
  methods: {
    updatePassword() {
      if (this.password1 !== this.password2) {
        this.$toast('error', 'Passord er ikke like!')
        return
      }
      if (!this.$validatePassword(this.password1)) {
        this.$toast('error', 'Passord møter ikke alle kriterer')
        return
      }

      const data = {
        password: this.password1,
        email: this.email,
        pin: this.pin
      }

      this.userService
        .resetPassword(data)
        .then((res) => {
          if (res.status == 'warning') {
            this.$toast(res.status, res.message)
          }
          if (res.status == 'success') {
            this.$toast(res.status, res.message)
            let userData = {
              password: this.password1,
              email: this.email
            }
            try {
              this.$store.dispatch('login', userData)
                .then((res) => {
                  this.$router.push('/');
                })
            } catch (err) {
              this.invalid.push(err.message)
            }
          }
        })

    },
    async submitLogin() {
      this.formValid = true;
      let userData = this.userData;
      if (userData.email === '') {
        this.formValid = false;
        return;
      }
      //Send http
      this.isLoading = true;

      try {
        await this.$store.dispatch('login', userData);
        this.$router.push('/');
      } catch (err) {
        this.error = err.message || 'Feil med registrering';
      }

      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  position: relative;
  height: 100vh;
  background: linear-gradient(0deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(253, 187, 45, 1) 100%);
}

.login-box {
  position: absolute;
  top: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
  width: 60rem;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 12px;
  background: rgba(100%, 100%, 100%, 0.1);
}

.input {
  width: 75%;
}

.log {
  color: var(--text-color);
}

.input input {
  height: 3rem;
  width: 100%;
  font-size: 1.8rem;
}

.input label {
  font-size: 1.8rem;
}

.btn--full {
  font-size: 2rem;
}


@media (max-width: 800px) {
  .login-box {
    width: 80%;
  }
}

@media (max-width: 550px) {

  .login-box {
    width: 95%;
  }

  .heading--login {
    font-size: 2.4rem;

  }

  .input {
    width: 95%;
  }
}

@media (prefers-color-scheme: dark) {


  .input input {
    background-color: #e8e8e8;
    color: #444;
    border: none;
  }

}
</style>
