<template>
  <Navbar v-if="this.$store.getters.isAuthenticated" />
  <router-view :key="$route.fullPath" />
  <Footer v-if="!this.$store.getters.isAuthenticated" />
  <Toast />
  <Loader />
  <Prompt />
</template>

<script>
import './assets/tableStyle.css';
import './assets/style.css';
import './assets/pagelayout.css';
import Navbar from './layout/Navbar.vue';
import Prompt from '@/components/UI/Prompt.vue'
export default {
  components: {
    Navbar,
    Prompt,
  },
  created() {
    this.$store.dispatch('tryLogin');
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout;
    },
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/login');
      }
    },
  },
};

const appHeight = () => {
  const doc = document.documentElement
  doc.style.setProperty("--app-height", `${window.innerHeight}px`)
}
window.addEventListener("resize", appHeight)
appHeight()
</script>

<style>
#app {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  /* margin-top: 0; */
  /* min-height: 100vh; */
}
</style>
