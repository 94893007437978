<template>
  <div v-if="projects" class="container">
    <Header title="Prosjekter" :button="button" />
    <!-- <ProjectOverview type="assigned" /> -->
    <div class="m-top">
      <button v-if="view == 'table'" class="btn btn--outline btn--icon" @click="toggleView">
        <IconCard />Bytt
        visning
      </button>
      <button v-if="view == 'grid'" class="btn btn--outline btn--icon" @click="toggleView">
        <IconMenu />Bytt
        visning
      </button>
    </div>

    <div class="tab-box">
      <button v-for="button in filters()" :key="button" class="btn--tabs"
        :class="{ 'tabs-active': filter == button.filter }" @click="changeFilter(button.filter)">
        {{ button.label }}
      </button>
    </div>

    <div v-if="view == 'grid'">
      <div class="grid--project">
        <div v-for="project in filteredProjects" :key="project.id">
          <div>
            <ProjectCard @click="$router.push('/project/' + project.id)" :users="users" :project="project" :key=key />
          </div>
        </div>
        <div v-if="filteredProjects.length == 0">
          <p>Du har ingen tildelte prosjekter</p>
        </div>
      </div>
    </div>

    <div v-if="view == 'table'">
      <div v-if="filteredProjects.length == 0">
        <p>Du har ingen tildelte prosjekter</p>
      </div>
      <ProjectTable v-else :projects="filteredProjects" :users="users" :key=key />
    </div>

    <div class="date-settings">
      <button @click="toggleCustomizeMenu()" class="btn btn--text-btn btn--icon">
        <IconSetting />Tilpass datoer
      </button>
    </div>

    <Transition name="fade">
      <div ref="dateCard" v-if="showCustomize && uiPreferenceCustom" class="card">

        <h4>Datoer for kort</h4>
        <div class="input--checkbox">
          <input class="input-check" v-model="uiPreferenceCustom.projectCard.service_delivery" type="checkbox"
            id="f-tl-card">
          <label for="f-tl-card">Første
            tjenesteleveranse</label>
        </div>
        <div class="input--checkbox">
          <input class="input-check" v-model="uiPreferenceCustom.projectCard.deadline" type="checkbox" id="f-card">
          <label for="f-card">Ferdig</label>
        </div>
        <div class="input--checkbox">
          <input class="input-check" v-model="uiPreferenceCustom.projectCard.technically_finished" type="checkbox"
            id="tf-card">
          <label for="tf-card">Teknisk ferdig</label>
        </div>
        <hr>
        <h4 class="m-top">Datoer for tabell</h4>

        <div class="input--checkbox">
          <input class="input-check" v-model="uiPreferenceCustom.projectTable.service_delivery" type="checkbox"
            id="ftl-tab">
          <label for="ftl-tab">Første tjenesteleveranse</label>
        </div>

        <div class="input--checkbox">
          <input class="input-check" v-model="uiPreferenceCustom.projectTable.service_delivery_c" type="checkbox"
            id="f-ned">
          <label for="f-ned">Første tjenesteleveranse nedtelling</label>
        </div>
        <div class="input--checkbox">
          <input class="input-check" v-model="uiPreferenceCustom.projectTable.deadline" type="checkbox" id="done-tab">
          <label for="done-tab">Ferdig</label>
        </div>
        <div class="input--checkbox">
          <input class="input-check" v-model="uiPreferenceCustom.projectTable.deadline_c" type="checkbox"
            id="done-count-tab">
          <label for="done-count-tab">Ferdig nedtelling</label>
        </div>
        <div class="input--checkbox">
          <input class="input-check" v-model="uiPreferenceCustom.projectTable.technically_finished" type="checkbox"
            id="tf-tab">
          <label for="tf-tab">Teknisk ferdig</label>
        </div>
        <div class="input--checkbox">
          <input class="input-check" v-model="uiPreferenceCustom.projectTable.technically_finished_c" type="checkbox"
            id="tf-count-tab">
          <label for="tf-count-tab">Teknisk ferdig nedtelling</label>
        </div>

        <div>
          <button @click="saveUiPreference()" class="btn btn--outline btn--icon m-top">
            <IconCheck />Lagre
          </button>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import {ref} from 'vue'
import ProjectService from '@/services/Project';
import ProjectCard from '../components/Project/ProjectCard.vue';
import ProjectTable from '../components/Project/ProjectTable.vue';
import UserService from '@/services/User'
import { filter } from 'lodash';
export default {
  computed: {
    userRole() {
      return this.$store.getters.userRole
    },

    filteredProjects() {
      const filter = this.filter;
      switch (filter) {
        case 'archived':
          break;

        default:
          break;
      }
      return this.projects;
    },
  },
  methods: {
    toggleCustomizeMenu() {
      this.showCustomize = !this.showCustomize;
      this.getPreferences();
      // const example = ref(null);
      // this.$refs.example.$el.scrollIntoView({ behavior: 'smooth' });
      if(this.showCustomize==true){
        this.$nextTick(() => this.$refs.dateCard.scrollIntoView({behavior:'smooth'}))
        // this.$nextTick(()=>this.$refs.dateCard.scrollTo({top:100}))
        // this.$nextTick(() => this.$refs.example.scrollIntoView({behavior:'smooth'}))

      }
      // this.$nextTick(() => this.$refs.example.showCurrent(command))

    },
    saveUiPreference() {
      let preference = this.uiPreferenceCustom
      console.log(preference)

      if (preference.projectCard) {
        Object.keys(preference.projectCard).forEach(function (key) {
          if (preference.projectCard[key] === false) {
            delete preference.projectCard[key]
          }
        })
      }
      if (preference.projectTable) {
        Object.keys(preference.projectTable).forEach(function (key) {
          if (preference.projectTable[key] === false) {
            delete preference.projectTable[key]
          }
        })
      }

      // return
      this.userService.setUserPreference(preference, 2)
      this.$store.dispatch('setUiPreferencesProjectView', preference)
      // this.$store.setUiPreferencesProjectView(preference)
      // this.$store.setUiProjectView(preference)
      this.key++
      this.showCustomize = false
    },

    filterF() {
      // return this.projects
    },
    changeFilter(filter) {
      this.filter = filter;
      switch (filter) {
        case 'archived':
          this.fetchProjects('?archived=1');
          break;
        case 'all':
          this.fetchProjects('?all=1');
          break;
        case 'finished':
          this.fetchProjects('?finished=1');
          break;
        case 'assigned':
          this.fetchProjects();
          break;
        default:
          break;
      }
      // if(filter=="archived"){
      //   this.fetchProjects("")
      // } else {
      //   this.fetchProjects()
      // }
    },
    checkMode() {
      if (localStorage.getItem('projectview')) {
        this.view = localStorage.getItem('projectview');
      } else {
        this.view = 'grid';
      }
    },
    toggleView() {
      if (this.view == 'grid') {
        this.view = 'table';
        localStorage.setItem('projectview', 'table');
      } else {
        this.view = 'grid';
        localStorage.setItem('projectview', 'grid');
      }
    },
    fetchProjects(filter) {
      // if(!filter){let filter=""}
      if (filter == undefined) {
        filter = '?finished=0';
      }
      // this.$http
      // .get('api/v1/projects' + filter)
      this.projectService
        .getProjects(filter)
        .then((response) => {
          (this.projects = response)
          this.$loader(false)
          this.getProgress()
        });
    },
    getUsers() {
      this.userService.getAllUsers()
        .then((res) => {
          this.users = res
        })

    },
    filters() {
      let filters = [{ label: 'Mine', filter: 'assigned' },
      { label: 'Arkiverte', filter: 'archived' },
      { label: 'Ferdigstilte', filter: 'finished' }
      ]
      if (this.$role(1)) {
        filters.push({ label: 'Alle', filter: 'all' })
      }
      return filters
      // { label: 'Alle', filter: 'all' } ]
    },
    getProgress() {
      for (let i = 0; i < this.projects.length; i++) {
        this.projectService
          .getProjectProgress(this.projects[i].id)
          .then((res) => {
            const projectIndex = this.projects.findIndex(project => project.id == this.projects[i].id)
            this.projects[projectIndex].progress = res
          })
      }
    },
    getPreferences() {
      if (localStorage.getItem('uiPreferencesProjectView')) {
        this.uiPreferenceCustom = JSON.parse(localStorage.getItem('uiPreferencesProjectView'))
      } else {
        // this.uiPreferenceCustom = this.$store.getters.showAll
      }
    }
  },
  mounted() {
    this.$loader(true)
    this.fetchProjects();
    this.getUsers()
    this.checkMode();
    this.getPreferences();
  },
  created() {
    this.projectService = new ProjectService();
    this.userService = new UserService()
  },
  data() {
    return {
      uiPreferenceCustom: null,
      // uiPreferenceCustom: { projectCard: {}, projectTable: {} },
      showCustomize: false,
      mode: 'Alle Prosjekter',
      userService: null,
      projects: null,
      view: 'table',
      users: null,
      filter: 'assigned',
      key: 0,
      button: {
        label: 'Nytt prosjekt',
        icon: 'IconPlus',
        link: '/newproject',
        role: 1
      },
    };
  },
  components: {
    ProjectCard, ProjectTable
  },
};
</script>
<style scoped>
.m-top .btn {
  justify-content: center;
}

.container {
  max-width: 180rem;
}

.grid--project {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
}


.tab-box {
  margin-bottom: 1rem;
}

.date-settings {
  display: flex;
  justify-content: center;
  margin: 3rem 0;
}

@media (max-width: 1550px) {
  .grid--project {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1160px) {
  .grid--project {
    grid-template-columns: repeat(2, 1fr);
  }
}



@media (max-width: 780px) {
  .grid--project {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}
</style>
