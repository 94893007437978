import axios from 'axios'
export default{
    setUser(state, payload){
        state.token = payload.token
        state.userId = payload.userId,
        state.firstName = payload.firstName
        state.lastName = payload.lastName
        state.email = payload.email
        state.role = payload.role
        state.companyName = payload.companyName
        state.tokenExpiration = payload.tokenExpiration
        this.$http.defaults.headers["AuthToken"] = payload.token;
        axios.defaults.headers["AuthToken"] = payload.token;
        state.didAutoLogout=false
    },
    setAutoLogout(state){
        state.didAutoLogout=true
    }
}

