<template>
    <div class="pos-rel">
        <div class="dropdown-box">
            <div class="badge" v-for="user in entries">
                <p>{{ user[label] }}</p>
                <IconClose @click="removeEntry(user)" />
            </div>
            <div class="dropdown-input">
                <input @keyup.backspace="removeBackspace()" @keydown.tab="closeContactform()" @focus="openDrop()"
                    @change="filter = search" v-model="search" type="text" :id="id">
            </div>
        </div>
        <div class="search-box" v-show="showDrop">
            <div @click="chooseEntry(entry)" class="search-list" v-for="entry in filtered()">{{ entry[label] }}</div>
            <div class="search-no-res" v-if="filtered().length == 0"><span>Ingen treff...</span></div>
        </div>
    </div>
    <div v-if="showDrop" @click="closeContactform()" class="close"></div>
</template>

<script>

export default {
    mounted() {
        if (!this.data) { return }
        let a = []
        if (typeof (this.data) === 'object') {
            for (let i = 0; i < Object.keys(this.data).length; i++) {
                a.push(Object.values(this.data)[i])
            }
        }
        if (!Array.isArray(this.data)) {
            let a = this.data.split(",")
            a = this.data.split(",").map(Number)
            // this.data=a
            // return
            // this.data=[1,2]
            if (this.data.length == 1) {
                a.push(this.data)
            }
            if (this.data.split(",") > 1) {
                this.data = this.data.split(",")
            }

            return
            // let a = [38]
            // console.log(a)
        } else {
            let a = this.data
        }
        if (this.data && this.data.length > 0 && this.options && this.options.length > 0) {
            this.entries = this.options.filter(x => a.includes(x.id))
            // this.entries = this.options.filter(x => this.data.includes(x.id))
        }

    },
    methods: {
        removeBackspace() {
            return
            if (this.search.length == 0) { this.entries.pop() }
        },
        removeEntry(user) {
            let index = this.entries.findIndex(x => x == user)
            if (index > -1) {
                this.entries.splice(index, 1)
                this.$emit('input', this.entries.map(a => a.id));
            }
        },
        handleInput(e) {
            this.$emit('input', this.entries.map(a => a.id));
        },
        closeContactform() {
            this.showDrop = false
        },
        openDrop() {
            let filtered = this.options.filter(x => x[this.label].toLowerCase().includes(this.search.toLowerCase()))
            filtered = filtered.filter(x => !this.entries.includes(x))
            if (filtered.length == 0) {
                this.showDrop = false
            } else {
                this.showDrop = true
            }
        },
        chooseEntry(entry) {
            this.entry = entry[this.label]
            this.entry = entry
            this.content = entry.id
            this.entries.push(this.entry)
            this.$emit('input', this.entries.map(a => a.id))

            let filtered = this.options.filter(x => x[this.label].toLowerCase().includes(this.search.toLowerCase()))
            filtered = filtered.filter(x => !this.entries.includes(x))
            if (filtered.length == 0) {
                this.showDrop = false
            }
        },
        filtered() {
            if (!this.options) { return }
            let filter = this.search
            if (filter == "" && this.options) {
                return this.options.filter(x => !this.entries.includes(x))
            }
            let filtered = this.options.filter(x => x[this.label].toLowerCase().includes(this.search.toLowerCase()))
            filtered = filtered.filter(x => !this.entries.includes(x))
            return filtered
            if (filtered.length > 0) { return filtered }
            if (filtered.length == 0) { return false }
        }
    },
    data() {
        return {
            entries: [],
            search: '',
            content: this.value,
            showDrop: false,
        }
    },

    name: 'Input',
    props: ["modelValue", 'label', 'data', 'value', 'name', 'options', 'type', 'placeholder', 'id'],
    emits: ['blur', 'update:search', 'input'], // change added
}
</script>

<style scoped>
.dropdown-box {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: var(--input-bg-color);
    border: 1px solid var(--border-color);
    border-radius: 9px;
    padding: 0.2rem 0;
}

.dropdown-input {
    display: flex;
    flex-grow: 1;
}

.dropdown-input input {
    border: none;
    background: none;
    background: url('../../assets/Chevron2.png') 98% / 2rem no-repeat var(--input-bg-color);
    width: 100%;

}

.dropdown-input input:focus-visible {
    outline: -webkit-focus-ring-color none;
}

.badge {
    display: flex;
    align-items: center;
    gap: 0.9rem;
    color: var(--text-color);
    border: 1px solid var(--border-color);
    border-radius: 9px;
    background: var(--bg-color);
    padding: 0.1rem 0.2rem;
    margin: 0.4rem;
    font-size: 1.4rem;
}

´.icon-close:hover {
    color: var(--main-color);
    cursor: pointer;
}

.search-box {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    z-index: 25;
    overflow-y: scroll;
    max-height: 15rem;
    width: 100%;
    position: absolute;
    background: var(--bg-color);
    border: 1px solid var(--border-color);
    font-size: 1.6rem;
    padding: .5rem 1rem;
}

.search-list {
    padding: .5rem;
}

.search-list:hover {
    background-color: var(--input-bg-color);
    color: var(--main-color);
    cursor: pointer;
}

.search-no-res {
    font-style: italic;
}
</style>