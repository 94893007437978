<template>
  <div class="container">
    <div class="login-box">
      <h1 class="heading--login">Ny bruker</h1>
      <div class="input">
        <label for="fname">Fornavn<span class="req">*</span></label>
        <input required v-model="userData.firstName" id="fname" type="text" autocomplete="given-name" />
      </div>
      <div class="input">
        <label for="lname">Etternavn<span class="req">*</span></label>
        <input required v-model="userData.lastName" id="lname" type="text" autocomplete="family-name" />
      </div>
      <div class="input">
        <label for="email">Epost<span class="req">*</span></label>
        <input required v-model="userData.email" id="email" type="email" disabled />
      </div>
      <div class="input">
        <label for="password1">Passord<span class="req">*</span></label>
        <input required placeholder="Minst 8 tegn" v-model="userData.pass1" id="password1" type="password"
          autocomplete="new-password" />
      </div>
      <div class="input">
        <label for="password2">Gjenta passord<span class="req">*</span></label>
        <input required placeholder="Minst 8 tegn" v-model="userData.pass2" id="password2" type="password"
          autocomplete="new-password" />
        <p class="log password-tip">Passord må være minst 8 tegn, inneholde minst en stor og en liten bokstav og
          inneholde
          minst ett tall</p>
      </div>


      <div class="input input-pin">
        <label for="pin2">PIN</label>
        <input v-model="pin" id="pin2" type="password" />
      </div>

      <div class="login-btn">
        <button @click="submitLogin" class="btn btn--full btn--login btn--icon">
          <IconCheck />Opprett bruker
        </button>
      </div>

      <div class="password-error" v-if="invalid.length > 0">
        <p v-for="error in invalid">
          {{ error }}
        </p>
      </div>

      <img :src="imageUrl()" />
    </div>
  </div>
</template>

<script>
import UserService from '@/services/User';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      pin: null,
      invalid: [],
      formValid: true,
      userData: {
        email: null,
        pass1: null,
        pass2: null,
        firstName: null,
        lastName: null,
        pin: null,
      },
      isLoading: false,
      error: null,
    };
  },
  mounted() {
    this.userData.pin = this.$route.query.pin;
    this.userData.email = this.$route.query.email;
  },
  created() {
    this.userService = new UserService()
  },
  computed: {
    ...mapGetters(['allstate', 'isAuthenticated']),
  },
  methods: {
    imageUrl() {
      let images = require.context('../assets/', false, /\.png$/)
      let darkmode = window.matchMedia('(prefers-color-scheme: dark)').matches
      if (darkmode) {
        return images('./Darkmode500.png')
      } else {
        return images('./Lightmode500.png')
      }
    },
    async tryLogin(user) {
      try {
        await this.$store.dispatch('login', user)
        this.$router.push('/')
      } catch (err) {
      }
    },
    async submitLogin() {
      this.invalid = []
      let userData = this.userData;
      if (userData.email === '') {
        this.invalid.push('Feil e-post')
      }
      if (this.userData.pass1 != this.userData.pass2) {
        this.invalid.push('Passord er ikke like')
      }

      if (!this.$validateText(this.userData.firstName)) {
        this.invalid.push('Fyll ut fornavn')
      }
      if (!this.$validateText(this.userData.lastName)) {
        this.invalid.push('Fyll ut etternavn')
      }
      if (!this.$validatePassword(this.userData.pass1)) {
        this.invalid.push('Passord møter ikke alle kriterier')
      }

      if (this.invalid.length > 0) {
        return
      }

      //Send http
      this.$loader(true);
      this.userService
        .activateUser(this.userData)
        .then((res) => {
          let user = {
            email: this.userData.email,
            password: this.userData.pass1
          }
          this.$toast(res.status, res.message)
          this.$loader(false)
          this.tryLogin(user)

        })
        .catch((err) => {
          this.$loader(false)
          this.$toast('error', 'En feil har oppstått, prøv igjen eller kontakt FiberX')
        })
      // try {
      //   await this.$store.dispatch('login', userData);
      //   this.$router.push('/');
      // } catch (err) {
      //   this.error = err.message || 'Feil med registrering';
      // }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  background: linear-gradient(0deg,
      rgba(34, 193, 195, 1) 0%,
      rgba(253, 187, 45, 1) 100%);
  padding-top: 0;
}

.login-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  gap: 2.4rem;
  width: 60rem;
  height: 67rem;
  border-radius: 12px;
  background: rgba(100%, 100%, 100%, 0.1);

}

.heading--login {
  margin-top: -1.5rem;
}

.login-box img {
  height: 3rem;
  margin-top: 1rem;
}

.input {
  gap: 0.8rem;
  width: 75%;
}

.input input {
  width: 100%;
  font-family: inherit;
  padding-left: 0.5rem;
  border: none;
  border-radius: 9px;
}

.login-btn {
  display: flex;
  width: 75%;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.btn--login {
  justify-content: center;
  font-size: 1.8rem;
  font-weight: 600;
  text-transform: uppercase;
  width: 100%;

}

.btn--login:hover {
  background: hsl(39, 100%, 49%);
}

.btn--login:active {
  background: hsl(39, 100%, 49%);
  box-shadow: none;
}

.input-pin {
  display: none;
}

.password-tip {
  color: var(--text-color);
  font-size: 1.6rem;
}

@media (prefers-color-scheme: dark) {
  .input input {
    background-color: #e8e8e8;
    color: #444;
    border: none;
  }
}
</style>
