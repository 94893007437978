<template>
  <div class="container">

    <div class="">
      <p>TEST TOAST</p>
      <button @click="testF" class="btn btn--outline">Promt</button>
      <button @click="testFF" class="btn btn--outline">fillpref</button>
    </div>






    <br><br>
    {{ uiPreference }}
    <br>
    <br>


    projectfields:[
    {"id":1,
    "data":"technically_finished",
    "type:"normal",
    "show":true,
    "label":"Teknisk ferdig"

    },
    {"id":2,
    "data":"technically_finished",
    "type":"countdown",
    "show":true,
    "label":"Teknisk ferdig"
    },
    {"id":3,
    "data":"service_delivery",
    "type":"normal",
    "show":true,
    "label":"Første tjenesteleveranse"
    },
    {"id":4,
    "data":"service_delivery",
    "type":"countdown",
    "show":true,
    "label":"Første tjenesteleveranse"
    },
    {"id":5,
    "data":"deadline",
    "type":"normal",
    "show":true,
    "label":"Ferdig"
    },
    {"id":6,
    "data":"deadline",
    "type":"countdown",
    "show":true,
    "label":"Ferdig"
    }

    ]

    <br><br>
    <br><br>


    this.$store.getters.userId
    {{ this.$store.getters.showAll }}
    <div class="">
      <input v-model="testval">
      <br>
      Template = {{ template }}, custom sort
      <br>
      Custom Columns Array : {{ customColumnsArr }}
      <br>
      Du har valgt sortering nummer : {{ currentSort }}
      <br>
      <button class="btn btn--full" @click="send()">Default </button>
      <button class="btn btn--full" @click="test(0)">Default </button>
      <button class="btn btn--full" @click="chooseCurrentSort(1)">1</button>
      <button class="btn btn--full" @click="chooseCurrentSort(2)">2</button>
      <br>
      <br>
      <button class="btn btn--full" @click="saveCustom()">Lagre</button>
      <br>
      <div>

        <div v-for="column in allColumns" :key="column.id">
          <input :disabled="currentSort == 0" v-model="customColumnsArr" :value="column.id" type="checkbox">{{
            column.header
          }}
          {{ column.id }}
        </div>
        <br>
        <br>
        <br>
        <div v-for="column in customColumns">
          <input type="checkbox">{{ column.header }} {{ column.id }}
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import DropdownMultiple from '@/components/UI/DropdownMultiple'
import UserService from '@/services/User';
import IconDownload from '@/components/Icons/IconDownload';
import IconSearch from '@/components/Icons/IconSearch';
import IconNotification from '@/components/Icons/IconNotification';
import IconMenu from '@/components/Icons/IconMenu';
// import UserService from '@/services/User';
export default {
  created() {
    this.userService = new UserService()
    this.fetchData();
    this.fetchAllColumns()
    this.getCustomSortedColumns()
    // this.testFarge()

    this.getDat()
    // this.uiPreference=JSON.parse(localStorage.getItem('uiPreferencesProjectView'))

    // this.connection = new WebSocket("ws://192.168.50.10:3000/?a=22")
    this.connection = new WebSocket("ws://192.168.50.10:3000/?a=22")
    this.connection.onmessage = function (event) {
      console.log(event.data)
    }


  },

  methods: {
    getDat() {
      if (localStorage.getItem('uiPreferencesProjectView')) {
        this.uiPreference = JSON.parse(localStorage.getItem('uiPreferencesProjectView'))
        let preference = this.uiPreference
      }
    },


    test(e) {
      axios.get("/testws/" + this.testval)
    },
    send() {
      let userId = this.$store.getters.userId
      // userId=JSON.stringify(userId)
      this.connection.send(userId)
      // this.connection.send(JSON.stringify(userId))
    },
    saveCustom() {

      //Hvis current sort er 0, avbryt (default sort, ikke nødvendig å lagre)
      if (this.currentSort == 0) { return }

      //Hent objekt først (store?)
      let sort = this.$store.getters.customSort

      let obj = {
        template: this.template,
        sort: this.currentSort,
        columns: this.customColumnsArr
      }

      let i = -1

      for (let j = 0; j < sort.length; j++) {
        console.log('loop' + j)
        if (sort[j].template == this.template && sort[j].sort == this.currentSort) {
          i = j
        }
      }

      if (i > -1) {
        console.log('Finnes allerede')
        sort[i] = obj
      } else {
        console.log('Finnes ikke')
        sort.push(obj)
      }

      this.$store.dispatch('setCustomSort', sort)

    },
    chooseCurrentSort(sort) {
      document.title = "Hei"
      return
      let obj = { template: this.template, sort: sort }
      let currentSort = this.$store.getters.currentSort(this.template)
      let currentSortArr = this.$store.getters.currentSortArr

      if (!currentSortArr) { currentSortArr = [] }
      let i = currentSortArr.findIndex(x => x.template == this.template)
      console.log(currentSortArr)
      if (i > -1) {
        console.log('Finnes')
      } else {
        console.log('Finnes ikke')
        currentSortArr.push(obj)
      }
      this.$store.dispatch('setCurrentSort', currentSortArr)
      this.getCustomSortedColumns(sort)
      this.currentSort = sort
      return
    },
    fetchAllColumns() {
      this.allColumns = this.$store.getters.templateColumns(this.template);
    },
    getCustomSortedColumns(sort) {

      sort = this.$store.getters.currentSort(this.template)
      this.currentSort = sort
      // console.log('Valgt sort : ' + sort)
      // console.log(sort)
      if (sort == 0) {
        this.customColumns = this.$store.getters.templateColumnsCustom(this.template, 0)
        // console.log(this.customColumns)
      } else {
        //Jeg har SORT ID, hent SORT ARRAY....
        sort = this.$store.getters.columnsByTemplateAndSort(this.template, sort)
        this.customColumns = this.$store.getters.templateColumnsCustom(this.template, sort)
        this.customColumnsArr = sort
        return
      }
    },
    download() {
      //??
      // return axios({
      // url:`/api/v1/template/8/blank-excel`,
      // method:"GET",
      // responseType:"blob"
      //     })
      //     return
      // return axios({   url:`/api/v1/template/8/blank-excel`,
      //         method:"GET",
      //         responseType:"blob"})
      return axios({
        url: `/test`,
        method: "GET",
        responseType: "blob"
      })
        .then(function (response) {
          let headerLine = response.headers['content-disposition'];
          console.log(headerLine)
          headerLine = headerLine.split('=')[1];
          // headerLine = headerLine;
          console.log(response)
          console.log(headerLine)
          let blob = new Blob([response.data], { type: 'application/jpg' });
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = headerLine;
          link.click();
        });
    },
    notif() {
      this.$toast('success', 'haei ieei ei ei eieieieiei', 'sdf asdf asdf asf i kke i i reasd or er erasd gjewkfsadf asdf dsfasdf dsfsdf sdfs')
      this.$toast('success', 'Kort tittel', 'Kort melding')
      this.$toast('success', 'Bare tittel')
    },
    toggleD() {
      document.documentElement.classList.toggle("dark-mode", true);
    },
    imageUrl() {
      let images = require.context('../assets/', false, /\.png$/)
      let darkmode = window.matchMedia('(prefers-color-scheme: dark)').matches
      if (darkmode) {
        return images('./Darkmode500.png')
      } else {
        return images('./Lightmode500.png')
      }
    },
    appendFile() {
      let images = require.context('@/assets/', false, /\.css$/)
      //  <link rel="apple-touch-icon" href="./img/icons/favicon-120x120.png" sizes="120x120">
      let file = document.createElement('link');
      file.rel = 'stylesheet';
      document.head.appendChild(file)
    },
    async fetchData() {
      await this.userService
        .getAllUsers()
        .then((data) => {
          this.users = data
        })
      // this.$http.get("/getprojects").then((res) => (this.projects = res.data));

    },
    emailBlur() {
    },
    onRootLostFocus() {
      this.$nextTick(() => this.showSearch = false)
    },
    choose(country) {
      this.search = country
      this.showSearch = false
    },
    filter(e) {
    },
    filtered() {
      let filter = this.search
      if (filter == "") {
        return this.countries
      }
      return this.countries.filter(x => x.includes(filter))
    },

    testFF() {
      let a = this.$store.uiPreference
      console.log(a)
    },
    testF() {


      this.$toast('success', 'hello' + Math.random())
      this.$toast('warning', 'hello' + Math.random())
      this.$toast('error', 'hello' + Math.random())


    },
  },
  data() {
    return {
      
      projectTable: {
        technically_finished: true,
        technically_finished_c: true,
        service_delivery: true,
        service_delivery_c: true,
        service_delivery: true,
        service_delivery_c: true,
      },
      template: 2,
      sampleSort: [],
      allColumns: [],
      customColumnsArr: [],
      customColumns: [],
      currentSort: 0,
      choosenUsers: [26, 1],
      cols: 30,
      button: {
        label: 'Ny oppgave',
        icon: 'pi-plus',
        link: '/ticket/new',
      },
      mode: 'list',
      isSubmitting: false,
      users: null,
      showSearch: false,
      search: '',
      testval: 1,
      userService: null,
      ticketService: null,
      searchTerm: null,
      priorities: [
        { name: 'Høy', id: 3 },
        { name: 'Middels', id: 2 },
        { name: 'Lav', id: 1 },
      ],
      createNew: true,
      newTicket: {},
      entries: [],
      projects: [],
      ticketTypes: this.$store.getters.ticketTypes,
      ticketStatus2: this.$store.getters.ticketStatus2,
      baseTemplates: this.$store.getters.baseTemplates,
    };
  },
  components: {
    DropdownMultiple,
    IconDownload,
    IconSearch,
    IconNotification,
    IconMenu
  },
};
</script>

<style scoped>
/*test tobi*/
[data-theme="dark"] {
  --navbar-color: hsl(1, 100%, 50%, 100%);

  .container {
    background: pink !important;
    font-size: 50px;
  }

}


.container {
  padding-top: 6rem;

}

.padd {
  margin: 5rem 0;
}

input {
  width: 17rem;
}

.card {
  width: 17rem;
  overflow-y: scroll;
  max-height: 15rem;
  /* width:10rem; */
  position: absolute;
  background: #444;
}

.grid-test {
  display: grid;
  grid-template-columns: repeat(30, 20rem);

}

.grid-test2 {
  height: 50rem;
  overflow-x: auto;
  width: 80%;
}

.t2 {
  text-align: center;
}

.row {
  grid-template-columns: repeat(20, 5rem);
  background: red;
}

.first-column {
  background: #bbb;
  /* position:sticky; */
  position: sticky;
  inset-inline-start: 0;
  z-index: 1;
}

.t3 {
  position: sticky;
  /* top:0; */
  inset-block-start: 0;
  z-index: 2
}

.header {

  width: 100%;
  background-color: #bbb;
  padding: 1rem;
}

.hover {}

.hover:hover {
  background-color: #555;
  color: red;
}

.prompt-container {
  width: 70%;
  position: fixed;
  bottom: 5%;
  left: 50%;
  translate: -50% 0;
  background: var(--bg-light-color);
  border-radius: 25px;
  padding: 1rem 3rem;
}

.prompt-ios {
  width: 70%;
  position: absolute;
  bottom: 1%;
  left: 50%;
  translate: -50% 0;
  background: var(--bg-light-color);
  border-radius: 25px;
  padding: 1rem 3rem;
}

.prompt-box {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 4rem;

}

i {}

.close-prompt {
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 2rem;
  translate: 0 -50%;
}

img {
  height: 3rem;
}

.btn--install {
  color: var(--text-color);
  text-transform: uppercase;
  font-weight: 500;
  background: var(--accent-color);
  border-radius: 25px;
}


@media (max-width: 800px) {
  .prompt-container {
    width: 90%;
  }
}

@media (max-width: 410px) {
  .prompt-container {
    padding: 1rem 1rem;
  }
}

@media (hover: hover) and (max-width: 550px) {
  .prompt-container {
    bottom: 20%;
  }


}
</style>
