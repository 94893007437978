<template>
  <div class="container">

    <Header v-if="project" :title="project.project_name" :button="headerButton" />
    <div class="m-top" v-if="project">
      <div class="proj-details-grid">
        <div @click="routeToProjectTemplate(template.id)" v-for="template in assignedTemplates" :key="template">
          <div class="card card--proj-details">
            <p class="proj-temp-name">{{ template.name }}</p>
            <div class="proj-card-btm">
              <div v-if="project.progress && project.progress" class="prog-bar-container">
                <span>{{ calculatePercentage(project.progress, '', template.id) }}</span>
                <div :style="'width:' + calculatePercentage(project.progress, 'css', template.id)" class="prog-bar-bar"
                  v-if="project.progress">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Header class="m-top" v-if="project" title="Diverse" />
    <div>
      <div v-if="project" class="proj-details-grid m-top">
        <div @click="$router.push('/materials/project/' + project.id)" class="card card--proj-details">
          <p class="proj-temp-name">Materiell</p>
        </div>
        <div @click="this.$router.push(`/project/${this.project.id}/tickets?project_name=${this.project.project_name}`);"
          class="card card--proj-details">
          <p class="proj-temp-name">Oppgaver</p>
        </div>
        <div @click="this.$router.push(`/project/documents/${this.project.id}`)" class="card card--proj-details">
          <p class="proj-temp-name">Dokumenter</p>
        </div>
        <div v-if="project.checklists !== ''" @click="this.$router.push(`/project/checklist/${this.project.id}`)"
          class="card card--proj-details">
          <p class="proj-temp-name">Sjekkliste</p>
          <div class="proj-card-btm">
            <div v-if="project.progress && project.progress" class="prog-bar-container">
              <span>{{ calculatePercentage(project.progress, '', 'checklist') }}</span>
              <div :style="'width:' + calculatePercentage(project.progress, 'css', 'checklist')" class="prog-bar-bar"
                v-if="project.progress">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Header class="m-top" v-if="project" title="Prosjekt informasjon" />
    <div class="card card--proj-info m-top">
      <h4>Datoer</h4>
      <div v-if="project && project.service_delivery" class="proj-info-dates">
        <div class="proj-info-list">
          <p>Første tjenesteleveranse</p>
          <p class="green-txt" v-if="project.service_delivered == 1">TL Startet</p>
          <p v-else :class="daysLeftClass(getDaysLeft(project.service_delivery, false))"> {{
            getDaysLeft(project.service_delivery,
              true)
          }}</p>
        </div>
        <p>{{ parseDate(project.service_delivery) }}</p>
      </div>
      <div v-if="project && project.technically_finished" class="proj-info-dates">
        <div class="proj-info-list">
          <p>Teknisk ferdig</p>
          <p :class="daysLeftClass(getDaysLeft(project.technically_finished, false))"> {{
            getDaysLeft(project.technically_finished,
              true)
          }}</p>
        </div>
        <p>{{ parseDate(project.technically_finished) }}</p>
      </div>
      <div v-if="project && project.deadline" class="proj-info-dates">
        <div class="proj-info-list">
          <p>Ferdigstilt</p>
          <p :class="daysLeftClass(getDaysLeft(project.deadline, false))"> {{ getDaysLeft(project.deadline,
            true)
          }}</p>
        </div>
        <p>{{ parseDate(project.deadline) }}</p>
      </div>
      <div v-if="project && users && project.team_leaders" class="proj-teamlead">
        <h4>Teamleder(e)</h4>
        <div class="proj-info-list">
          <p v-for="user in parseUsers(project.team_leaders)">
            {{ user.name }}</p>
        </div>
      </div>
      <div v-if="project && project.project_leader_name" class="proj-teamlead">
        <h4>Prosjektleder(e)</h4>
        <div class="proj-info-list">
          <p v-for="user in parseUsers(project.project_leader)">
            {{ user.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectService from '@/services/Project';
import UserService from '@/services/User'
export default {
  computed: {
    headerButton() {
      return {
        label: 'Prosjekt innstillinger',
        icon: 'IconSetting',
        role: this.overrideRole(),
        link: '/projectsettings/' + this.project.id,
      };
    },



    assignedTemplates() {
      if (!this.project.templates) {
        return false;
      }
      const projectTemplates = this.project.templates.split(',');
      let templates = [];
      for (let i = 0; i < projectTemplates.length; i++) {
        templates.push({
          id: projectTemplates[i],
          name: this.templates.find((temp) => temp.id == projectTemplates[i])
            .name,
        });
      }
      // templates = templates.sort((a,b)=>a.id<b.id)
      templates.sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
      return templates;
    },
    templates() {
      return this.$store.getters.templates;
    },
    internalChecklists() {
      return this.$store.getters.checklists;
    },
    internalChecklistsObjects() {
      return this.$store.getters.checklistsObjects;
    },
  },

  methods: {
    overrideRole() {
      //Uffda....
      let teamLeaders = null
      if (this.project && this.project.team_leaders) {
        teamLeaders = this.project.team_leaders
      } else {
        return 0
      }
      let userId = this.$store.getters.userId
      teamLeaders = teamLeaders.split(',')
      if (teamLeaders.includes(userId)) {
        return
      }

      return 0

    },
    getUsers() {
      this.userService.getAllUsers()
        .then((res) => {
          this.users = res
        })

    },
    parseUsers(teamLeaders) {
      teamLeaders = teamLeaders.toString()
      teamLeaders = teamLeaders.split(',')
      if (!this.users) { return }
      // return teamLeaders
      let names = []
      for (let i = 0; i < teamLeaders.length; i++) {
        if (this.users.find((x) => x.id == teamLeaders[i])) {
          names.push({
            id: teamLeaders[i],
            name: this.users.find((x) => x.id == teamLeaders[i]).full_name
          })
        }
      }
      return names
    },

    daysLeftClass(daysLeft) {
      if (daysLeft > 7) {
        return 'green-date';
      }
      if (daysLeft > 0) {
        return 'orange-date';
      }
      if (daysLeft < 0) {
        return 'red-date';
      }
      if (daysLeft == 'I dag') {
        return 'red-date';
      }
    },
    parseDate(date) {
      if (!date) { return }
      return this.$dayjs(date).format('DD MMM YY')
    },
    getDaysLeft(date, string) {
      if (!date) return;
      const datee = this.$dayjs(date);
      const today = this.$dayjs().startOf('day');
      const days = datee.diff(today, 'days');
      let txt = '';
      if (Math.abs(days) == 1) {
        txt = ' dag';
      } else {
        txt = ' dager';
      }
      if (parseInt(days) > 0) {
        txt += ' igjen';
      }
      if (days == 0) return 'I dag';
      if (days < 0) {
        txt += ' siden';
      }
      if (string == true) {
        return `${Math.abs(days)} ${txt}`;
      }
      return days;
    },
    getProjectProgress() {
      this.projectService
        .getProjectProgress(this.project.id)
        .then((result) => (this.project.progress = result));
    },
    calculatePercentage(progress, type, template) {
      if (!progress) {
        return ''
      }
      // if(!progress.yes){
      //   return 0
      // }
      let templateName = null
      if (template == 'checklist') {
        templateName = 'checklist'
      } else {
        templateName = this.$store.getters.baseTemplates.find(x => x.id == template).template_name
        // console.log(templateName)
      }
      progress = progress[templateName]
      const sum = progress.yes + progress.no + progress.na
      const finished = progress.yes + progress.na
      if (isNaN(Math.floor(finished / sum * 100))) {
        if (type == 'css') {
          return '0%'
        }
        return "Ingen oppføringer"
      }
      return Math.floor(finished / sum * 100) + "%"
    },
    goBack() {
      const project = this.$route.params.id;
      this.$router.push(`/`);
    },
    assignedChecklists() {
      return
      if (!this.project.checklists) return;
      const projectChecklists = this.project.checklists.split(',');
      let checklists = [];
      for (let i = 0; i < projectChecklists.length; i++) {
        checklists.push({
          id: projectChecklists[i],
          name: this.internalChecklists.find(
            (temp) => temp.id == projectChecklists[i]
          ).name,
          progress: 0,
          visible: false,
        });
        this.checklists = checklists;
      }
    },
    assignedChecklistsObjects() {
      return
      if (!this.project.checklists_objects) return;
      const projectChecklistsObjects =
        this.project.checklists_objects.split(',');
      let checklistsObjects = [];
      for (let i = 0; i < projectChecklistsObjects.length; i++) {
        checklistsObjects.push({
          id: projectChecklistsObjects[i],
          name: this.internalChecklistsObjects.find(
            (temp) => temp.id == projectChecklistsObjects[i]
          ).name,
          progress: 0,
          visible: false,
        });
        this.checklistsObjects = checklistsObjects;
      }
    },
    progress(id) {
      const progress = this.assignedChecklists[0].progress;
      if (progress == -1) {
        return 0;
      }
    },
    updateProgress(value) {
      this.checklists[0].progress = value;
    },
    routeToProjectTemplate(template) {
      this.$router.push(`/project/${this.project.id}/template/${template}`);
    },
    random() {
      return Math.floor(Math.random() * 100);
    },
    fetchChecklists() {
      // this.$http
      //   .get("/getchecklists/" + this.$route.params.id)
      //   .then((response) => (this.checklists = response.data));
      // const checklistId =
      // this.ProjectService
      // .getChecklistToProject(checklistId,projectId)
    },
    progressExist(template) {
      if (this.progressTemplate[template]) {
        return this.progressTemplate[template];
      } else {
        this.getTemplateProgress(template);
        return this.progressTemplate[template];
        // return 100
      }
    },
    fetchProject() {
      this.projectService
        .getProject(this.$route.params.id)
        .then((response) => {
          this.project = response[0]
          this.$loader(false)
          localStorage.setItem('projectInfo', JSON.stringify({ id: this.project.id, name: this.project.project_name }))
        })

        .then((response) => this.assignedChecklists())
        .then((response) => this.assignedChecklistsObjects())
        .then((response) => {
          this.getProjectProgress()
        });
      // this.fetchChecklists();
    },

    async getTemplateProgress(template) {
      // return 1
      this.projectService
        .getProjectTemplateProgress(this.$route.params.id, template)
        .then(
          (response) => (this.progressTemplate[template] = response.percentage)
        );

      let progress = await this.$http
        .get(`/progress/project/${this.$route.params.id}/template/${template}`)
        .then((value) => {
          const values = value.data.data;
          let yes = values[0]['Ja'];
          let na = values[0]['Utgår'];
          let no = values[0]['Nei'];
          let percentage = (yes + na) / (yes + na + no);
          percentage = Math.floor(percentage * 100);
          // this.progressTemplate[template] = 2;
          // this.progressTemplate[template] = percentage;
        });
      // return percentage
      return progress;
      // return 89
    },
  },
  created() {
    this.projectService = new ProjectService();
    this.userService = new UserService()
    this.fetchProject();
    this.$loader(true)
    this.getUsers()
    // this.getTemplateProgress()
  },
  data() {
    return {
      userService: null,
      projectService: null,
      progressTemplate: {},
      checklists: null,
      checklistsObjects: null,
      project: null,
      projectService: null,
      template: null,
      users: null,
    };
  },
};
</script>

<style scoped>
.container {
  max-width: 160rem;
}

.proj-details-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
}

.card--proj-details {
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 10rem;
  width: 30rem;
  cursor: pointer;
}

.card--proj-details:hover {
  box-shadow: 0 0 3px 1px var(--border-color);
}

.proj-temp-name {
  font-size: 2rem;
  font-weight: 500;
}

.proj-card-btm {
  width: 100%;
  height: 3rem;
}

.prog-bar-container {
  height: 2.5rem;
}

.prog-bar-container span {
  top: 10%;
}

.card--proj-info {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-width: 50rem;
  padding: 2rem;
}

.proj-info-dates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.proj-teamlead {
  margin-top: 2rem;
}

.proj-info-list {
  display: flex;
  flex-direction: column;
  gap: .8rem;
}

@media (max-width: 1355px) {
  .proj-details-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 1040px) {
  .card--proj-details {
    width: 95%;
  }
}

@media (max-width: 900px) {
  .project-details {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .card--proj-details {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .proj-details-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .card--proj-details {
    width: 100%;
  }

  .card--proj-info {
    max-width: 100%;
  }
}

@media (max-width: 650px) {
  .proj-details-grid {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .card--proj-details {
    width: 100%;
  }
}
</style>
