<template>
    <!-- Kommentarfelt -->
    <div v-if="true" class="card">
        <div v-if="!parentComment" class="flex-col-1 input-comment">
            <label for="new-comment">Ny kommentar</label>
            <textarea maxlength="1000" ref="comment" required v-model="newComment" type="text" id="new-comment"></textarea>
            <div class="m-btm">
                <button class="btn btn--outline btn--icon" @click="createComment()">
                    <IconMail />Post
                </button>
            </div>
        </div>

        <div v-for="comment in comments">
            <div class="comment-box" v-if="comment.parent_id == null">
                <IconBin v-if="this.$canDelete(comment.user_id)" @click="deleteComment(comment.id)" class="big-bin" />
                <div class="comment">
                    <p class="parent-comment"> {{ comment.comment }}</p>
                    <p class="auth-comment">
                        <span class="log">{{ comment.user }}</span>
                        <span class="log">&nbsp;{{
                            $dayjs(comment.created_at).format('DD.MM.YY HH:mm')
                        }}</span>
                    </p>
                    <span class="reply-btn btn--icon " @click="parentComment = comment.id;">
                        <IconReply />Svar
                    </span>
                </div>

                <div v-if="parentComment == comment.id" class="flex-col-1 input-reply">
                    <textarea maxlength="1000" class="new-reply-area" autofocus ref="reply" required v-model="newComment"
                        type="text" id="new-comment" placeholder="Svar.."></textarea>
                    <div class="m-btm">
                        <button class="btn btn--outline btn--icon" @click="createComment">
                            <IconSend />
                            Post svar
                        </button>
                    </div>
                </div>
                <div class="comment-reply" v-if="checkParentComment(comment.id).length > 0">
                    <div class="sub-comment" v-for="subComment in checkParentComment(comment.id)">
                        <div v-if="this.$canDelete(subComment.user_id)">
                            <IconBin @click="deleteComment(subComment.id)" class="small-bin" />
                        </div>
                        <p> {{ subComment.comment }} </p>
                        <p>
                            <span class="log">{{ subComment.user }}</span>
                            <span class="log">&nbsp;{{ $dayjs(subComment.created_at).format('DD.MM.YY HH:mm') }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EntryService from '@/services/Entry'
export default {
    props: ['projectId'],
    created() {
        this.$loader(true)
        this.entryService = new EntryService
        this.getComments()
    },
    data() {
        return {
            entryService: null,
            newComment: null,
            comments: null,
            parentComment: null,
        }
    },
    methods: {
        deleteComment(postId) {
            if (confirm('Er du sikker?')) {
                this.$loader(true)
                this.entryService
                    .deleteComment(postId)
                    .then((res) => {
                        this.$toast(res.status, res.message)
                        this.getComments()
                        this.$loader(false)
                    })
            }
        },
        checkParentComment(id) {
            return this.comments.filter((obj) => {
                return obj.parent_id == id;
            });
        },
        createComment() {
            if (this.newComment == "" || !this.newComment) {
                return
            }
            const comment = this.newComment;
            if (!comment) {
                // return
            }
            if (comment.length > 999) {
                this.$toast('error', 'For lang kommentar, maks 1000 tegn')
                return
            }
            this.$loader(true)
            const entryId = this.$route.params.entry;
            const templateId = this.$route.params.template;
            const projectId = this.projectId
            // return
            const parentComment = this.parentComment;
            const commentObj = { comment: comment, parentComment: parentComment };
            this.entryService
                .postComment(projectId, templateId, entryId, commentObj)
                .then((res) => {
                    if (res.status == 'success') {
                        this.newComment = null;
                        this.parentComment = null;
                        this.getComments()
                        this.$loader(false)
                        this.$toast(res.status, res.message)
                    } else {
                        this.$loader(false)
                        this.$toast(res.status, res.message)
                    }

                })
            // this.checkTicket();
        },
        getComments() {
            const entryId = this.$route.params.entry;
            const templateId = this.$route.params.template;
            const projectId = this.projectId
            this.entryService
                .getComments(projectId, templateId, entryId)
                .then((res) => {
                    this.comments = res
                    this.$loader(false)
                })
        }
    }
}
</script>

<style scoped>
.icon-send {
    height: 2rem;
}

.icon-reply {
    height: 1.6rem;
}
</style>
