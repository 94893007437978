<template>
    <div v-show="loaded" class="container">
      <Header :title="checkTitle()" />
      
      <Files @loaded="(loaded=true)" type="projectDocuments"/>

    </div>
  </template>
  
  <script>
  import Files from '../Template/Tabs/Files'
  import UserService from '@/services/User.js';
  import ProjectService from '@/services/Project.js';
  
  export default {
    components:{Files},

    created() {
      this.userService = new UserService();
      this.projectService = new ProjectService();
  
    },
    data() {
      return {
        loaded:false
      };
    },
    computed: {
     
    },
    methods:{
      checkTitle(){
      if (localStorage.getItem('projectInfo')){
        let project = JSON.parse(localStorage.getItem('projectInfo'))
        if(project.id==this.$route.params.project_id){
          return `${project.name} / Dokumenter`
        }
      }
      return 'Dokumenter'
    },
    }
  };
  </script>
  
  <style scoped>
  
  </style>
  