import axios from 'axios'
// const axios = this.$http

export default class MaterialService{
    getMaterials(){
        return axios.get('/api/v1/materials').then(res=>res.data)
    }
    uploadMaterialsExcel(data,file){
        const formData = new FormData()
		formData.append("file", file)
		formData.append("info",JSON.stringify(data))
        return axios.post(`/api/v1/materials/excel/project/${data.project_id}`,formData).then(res=>res.data)
    }
    getMaterialSummaries(projectId){
        return axios.get(`/api/v1/material-summary/project/${projectId}`).then(res=>res.data)
    }
    exportMaterialUsage(project_id){
        // return axios.get(`/api/v1/export/materialuse/project/${project_id}`).then(res=>res.data)
        return axios({
            url:`/api/v1/export/materialuse/project/${project_id}`,
            method:"GET",
            responseType:"blob"
        })
    }

    getMaterialLogs(projectId){
        return axios.get(`/api/v1/materials/logs/project/${projectId}`).then(res=>res.data)
    }
    getMaterialLogsToEntry(project_id,template_id,entry_id){
        return axios.get(`/api/v1/materials/logs/project/${project_id}/template/${template_id}/entry/${entry_id}`).then(res=>res.data)
    }
    updateMaterialUsage(project_id,template_id,entry_id,data){
        return axios.patch(`/api/v1/materials/update/project/${project_id}/template/${template_id}/entry/${entry_id}`,data).then(res=>res.data)
    }
}