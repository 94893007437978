<template>
  <h2>Logg</h2>
  <div class=" card">
    <div v-for="log in log" :key="log.if" class="log-box">
      <p>
        <span>{{ log.user }}</span>: <span>{{ dataName(log.field, log.action, "field") }}</span> -> <span>{{
          dataName(log.field, log.action, "action")
        }}</span>
      </p>
      <p>{{ formatDate(log.created_at) }}</p>
    </div>
  </div>

  <h2>Materiellogg</h2>
  <div class="card">
    <div v-for="log in materialLog" :key="log.if" class="log-box">
      <p>
        <span>{{ log.name }}</span>&nbsp;{{ formatAction(log.quantity) }}<span>&nbsp;{{
          (Math.abs(log.quantity))
        }}</span>&nbsp;<span>{{ log.material_name }}</span>
        <span>{{ dataName(log.field, log.action, "action") }}</span>
      </p>
      <p>{{ formatDate(log.created_at) }}</p>
    </div>
  </div>
</template>

<script>
import MaterialService from "@/services/Material";
export default {
  mounted() {
    this.materialService = new MaterialService();
    this.getMaterialLogs();
  },
  data() {
    return {
      materialService: null,
      materialLog: null
    };
  },
  methods: {
    formatAction(quantity) {
      if (quantity > 0) {
        return '-> '

      }
      if (quantity < 0) {
        return '<-'
      }
    },
    getMaterialLogs() {
      return new Promise((resolve) => {
        this.materialService
          .getMaterialLogsToEntry(
            this.entry.project,
            this.$route.params.template,
            this.entry.id
          )
          // .then((res) => (this.materialLog = res))
          .then((res) => {
            this.materialLog = res;
            // resolve("resolved");
          });
      });
    },
    formatDate(log) {
      return this.$dayjs(log).format("DD.MMMYY, HH:mm");
    },
    dataName(name, rawAction, value) {
      let fields = this.fields;
      let search = fields.find((thing) => thing.dataName == name);
      let label = "";
      let action = "";
      if (search && search.label) {
        label = search.label;
      } else {
        switch (name) {
          case "prioritert":
            label = "Prioritert";
            action = rawAction == 1 ? "Ja" : "Nei";
            break;
          case "utgaar":
            label = "Utgår";
            action = rawAction == 1 ? "Ja" : "Nei";
            break;

          default:
            label = name;
            break;
        }
      }
      //Sjekker hvis Felttype er static/dynamic/annet (Rå data, skal ikke konverteres)
      if (search && ["TextField", "TextBoxField", "DropdownField"].includes(search.component)) {
        action = rawAction;
      } else if (search && ["CheckboxField", "SimpleCheckboxField"].includes(search.component)) {
        // action='fuckwit'
        const choices = ["Nei", "Ja", "Utgår"];
        action = choices[parseInt(rawAction)];
      }
      if (value == "action") {
        return action;
      } else {
        return label;
      }
    },
  },

  props: ["log", "fields", "entry"],
};
</script>

<style scoped>
.card {
  gap: 1rem;
  max-width: 100rem;
  margin: 3rem 0;
}

.log-box {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: 1px solid var(--border-color);
  padding: 1rem;
  border-radius: 9px;
}

span {
  font-weight: 500;
}
</style>