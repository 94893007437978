// plugins/i18n.js
import { EventBus } from '@/assets/event-bus.js'
export default {
    install: (app, options) => {
        // inject a globally available $translate() method
        app.config.globalProperties.$role = (state) => {
            // console.log(state,test)
            // this.
            // if(state)
            let requiredRole =state
            if(requiredRole=='admin'){
                requiredRole=0
            }
            if(requiredRole=='projectLeader'){
                requiredRole=1
            }
            if(requiredRole=='user'){
                requiredRole=2
            }
            
            const currentRole = parseInt(app._context.provides.store.getters.userRole)
            
            
            if(currentRole<=requiredRole){
                return true
            }
            return false
        }
        app.config.globalProperties.$isOwner=(state) => {
            // console.log(state)
        }
        app.config.globalProperties.$canDelete=(state)=>{
            const currentRole = parseInt(app._context.provides.store.getters.userRole)
            const userId = parseInt((app._context.provides.store.getters.userId))
            if(state == userId) { return true}
            if(currentRole == 0){
                return true
            }
            return false
        }
        app.config.globalProperties.$isOwnerA = (state) => {
            const currentRole = parseInt(app._context.provides.store.getters.userRole)
            const currentId = parseInt(app._context.provides.store.getters.userRole)
            if(state == currentId) { return true}
            if(currentRole ==0){
                return true
            }
            return false
            if(state){
                if(isNaN(state)){
                    return false
                } else {
                    return true
                }
                if(state.length>0){
                    return true
                }
                if(state.length==0){
                    return false
                }
            } else {
                return false
            }
        }


        
}


}


// if (contactForm.name == "") {
//     invalid.push('Fyll ut navn')
// }
// if (contactForm.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/).length == 0) {
//     invalid.push('Fyll ut epost')
// }
// if (contactForm.telefon == "") {
//     invalid.push('Fyll ut telefon')
// }

// let invalid =  this.$validate({
//       type:mail,
//       value:contactForm.name,
//       label:'Fyll ut navn'
//   },
//   {
//     value:contactForm.email,
//     label:'Fyll ut epost'
//   }
//   )

// if(invalid.length>0){
//     return
// }



//   if(this.$validate.isEmpty(contactForm.name)){
//     this.invalid.push('Fyll ut epost?')
//   }


//   if(invalid)

