export default {
    state() {
        return {
            
          
        }
    },
    // mutations,
    // actions,
    getters: {
        templateFieldsaaa(state, id) {
            return state.templates
        },
        findChecklistFields: (state) => (id) => {
            return state.checklists.find(thing => thing.id === id)
        },
        findChecklistObjectFields: (state) => (id) => {
            return state.checklistObjects.find(thing => thing.id === id)
        },
        findTemplateColumdsdns: (state) => (id) => {
            return state.tables.find(thing => thing.id === id)
        }

    }
};