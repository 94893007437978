<template>
  <div class="blurred-bg">
    <div :class="modalClass('modal')" class="modal">
      <div class="modal-content ">
        <h3> {{ title }}</h3>
        <br>
        <p>{{ message }}</p>
      </div>
      <div class="modal-btn">
        <button @click="$emit('confirm', 'confirm')" :class="modalClass('button')" class="btn btn--icon">

          <IconCheck v-if="type == 'check'" />
          <IconBin v-if="!type" />
          {{ confirmLabel }}
        </button>
        <button @click="$emit('confirm', 'cancel')" class="btn btn--outline btn--icon">
          <IconClose />
          {{ cancelLabel }}
        </button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  mounted() {
    document.documentElement.classList.add('stop-scrolling')
  },

  beforeUnmount() {

    document.documentElement.classList.remove('stop-scrolling')

  },

  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    modalClass(el) {
      if (el == 'modal') {
        if (this.type == 'check') {
          return 'modal-check'
        } else {
          return 'modal-delete'
        }
      }
      if (el == 'button') {
        if (this.type == 'check') {
          return 'btn--green'
        } else {
          return 'btn--red'
        }
      }
    },
    confirm() {
      this.$emit('click', 'ok')
    },
    cancle() {
      this.$emit('click', 'ok')

    },
    handleInput(e) {
      this.$emit('input', this.content);
    },
  },
  computed: {
  },

  props: ['title', 'message', 'confirmLabel', 'cancelLabel', 'type'],
};

</script>

<style scoped>
html {
  height: 100%;
  overflow: hidden;
}

.blurred-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  animation-name: FadeIn;
  animation-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  min-width: 35rem;
  top: 3%;
  left: 50%;
  translate: -50% 0;
  padding: 2em;
  border-radius: 9px;
  background: var(--bg-light-color);
}

.modal-delete {
  box-shadow: 0 0 0 3px var(--red-color);
}

.modal-check {
  box-shadow: 0 0 0 3px var(--green-color);
}

.modal-content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  margin-bottom: 2rem;
}

.modal-btn {
  display: flex;
  gap: 1rem;
}
</style>