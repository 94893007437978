<template>
  <div v-if="tickets">
    <Header title="Oppgaver" :button="headerButton" />
    <TicketsTable @click="selectTicket" :existingTickets="tickets" :id="entry.id" :mode="'entry'" />
  </div>
</template>

<script>
import TicketService from '@/services/Ticket';
import TicketsTable from '@/components/Tickets/TicketsTable';
import Ticket from '@/components/Tickets/Ticket';
export default {
  components: { TicketsTable, Ticket },
  props: ['entry', 'showAll'],
  data() {
    return {
      ticketService: null,
      tickets: null,

    };
  },
  unmounted() {
    // this.tickets=null
  },
  mounted: async function () {
    this.$loader(true)
    this.ticketService = new TicketService();
    this.fetchData();
  },
  computed: {
    headerButton() {
      let entryName = null
      switch (this.$route.params.template) {
        case '1':
          if (!this.entry.punkt_navn) {
            entryName = ''
          } else {
            entryName = this.entry.punkt_navn
          }
          break;
        case '2':
          if (!this.entry.punkt_fra) {
            entryName = ''
          } else {
            entryName = this.entry.punkt_fra + "->" + this.entry.punkt_til
          }
          break;
        case '8':
          if (!this.entry.navn) {
            entryName = ''
          } else {
            entryName = this.entry.navn
          }
          break;
        default:
          entryName = 'oki'
          break;
      }
      return {
        label: 'Ny oppgave',
        icon: 'IconPlus',
        link: `/ticket/new/${this.entry.project}/${this.$route.params.template}/${this.$route.params.entry}?entry_name=${entryName}&project_name=${this.entry.project_name}`
      };
    }
  },
  methods: {
    headerButtonsss() {
      return {
        label: 'Ny oppgave',
        icon: 'IconPlus',
        link: `/ticket/new/${this.entry.project}/${this.$route.params.template}/${this.$route.params.entry}`

      }
    },
    selectTicket(ticket) {
      this.mode = 'edit';
      this.choosenTicket = ticket;
      this.$router.push('/ticket/' + ticket.id)
    },
    async fetchData() {
      await this.ticketService
        .getTicketsFiltered(
          `project=${this.entry.project}&template=${this.$route.params.template}&entry=${this.entry.id}`
        )
        .then((message) => {
          this.$loader(false)
          this.tickets = message;
        });
    },
  },
};
</script>

<style scoped></style>
