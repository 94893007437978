<template>
  <div v-if="loading" class="loader"></div>
</template>

<script>
import { EventBus } from '@/assets/event-bus.js';
export default {
  data() {
    return {
      loading: false
    }
  },
  mounted() {
    this.bindEvents();
  },
  methods: {
    bindEvents() {
      EventBus.on('loader', function (data) {
        this.handleLoad(data)
      }.bind(this));
    },
    handleLoad(data) {
      if (data.state == true) {
        this.loading = true
      }
      if (data.state == false) {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  border: 1.6rem solid var(--text-color);
  border-top: 1.6rem solid var(--accent-color);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>