export default{
    state() {
        return {
            customerStatus:[
                {id:0,name:'Solgt'},
                {id:1,name:'Ikke bestilt'},
                {id:2,name:'Ikke solgt'},
                {id:3,name:'Skjøtet'},
                {id:4,name:'Grunnrett'},
                {id:5,name:'Hengt'},
            ],
            templates:[
                {id:1,name:'Koblingspunkt'},
                {id:2,name:'Linjearbeid'},
                {id:3,name:'Grunnrettsavtale'},
                // {id:4,name:'Kundekabler'},
                // {id:5,name:'Graving'},
                {id:8,name:'Kunde'},
            ],
            checklists:[
                {id:1,name:'Søknader'},
                {id:2,name:'Dokumentasjon'},
            ],
  
        }
    },
    // mutations,
    // actions,
    getters:{
        // getStatuses(state){
        //     return state.statues
        // }
        customerStatus(state){
            return state.customerStatus
        },
        templates(state){
            return state.templates
        },
        checklists(state){
            return state.checklists
        },
        checklistsObjects(state){
            return state.checklistObjects
        }
    }
};