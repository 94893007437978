import { createStore } from 'vuex'

import authModule from './modules/auth/index.js'
import projectModule from './modules/project/index.js'
import entriesObjectModule from './modules/entriesObject/index.js'
import checklistModule from './modules/checklist/index.js'
import baseDataModule from './modules/baseData/index.js'
import uiPreferencesModule from './modules/uiPreferences/index.js'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: authModule,
    project: projectModule,
    entriesObject : entriesObjectModule,
    checklist: checklistModule,
    baseData: baseDataModule,
    uiPreferences: uiPreferencesModule
  }
})
