<template>
  <div class="input">
    <label :for="label">{{ label }}</label>
    <select :id="label" @input="$emit('update:modelValue', $event.target.value)" :value="modelValue">
      <option v-show="false" value="">Velg status</option>
      <option :value="option.id" v-for="option in getStatutes">
        {{ option.name }}
      </option>
    </select>
    <div v-if="log" class="log">
      {{ log.name }}
      {{ log.date }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: this.value,
      matr: ['ab'],
    };
  },
  methods: {
  },
  computed: {
    getStatutes() {
      if (this.options == "customer") {
        return this.$store.getters.customerStatus
      }
      if (this.options) {
        return this.options
      } else {
      }
    },
    isEnabled() {
      if (this.type == 'dyamic') {
        return false;
      }
      if (this.type == 'static' && this.edit == false) {
        return true;
      } else {
        return false;
      }
    },
  },
  emits: ['update:modelValue'],
  props: [
    'modelValue',
    'options',
    'value',
    'label',
    'data',
    'log',
    'type',
    'edit',
  ],
};
</script>

<style scoped></style>
