<template>
  <div v-show="show">
    <div @click="this.clearNotification()" class="toast-container">
      <div v-for="notification in toasts" class="toast" :class="classType(notification.type)">
        <component :is="iconType(notification.type)" />
        <div class="toast-content">
          <h2 class="toast-title"> {{ notification.title }}</h2>
          <p class="toast-message">{{ notification.message }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/assets/event-bus.js';

export default {
  data() {
    return {
      toasts: [],
      show: false,
    }
  },
  mounted() {
    this.bindEvents();
  },

  methods: {
    classType(type) {
      if (type == 'success') {
        return 'toast-success'
      }
      if (type == 'error') {
        return 'toast-error'
      }
      if (type == 'warning') {
        return 'toast-warning'
      }
    },
    iconType(type) {
      if (type == 'success') {
        return 'IconCheck'
      }
      if (type == 'error') {
        return 'IconClose'
      }
      if (type == 'warning') {
        return 'IconExMark'
      }
    },
    bindEvents() {
      EventBus.on('notify', function (data) {
        this.handleNotification(data);
      }.bind(this));
    },

    handleNotification(data) {
      this.toasts.push(data)
      this.show = true;

      setTimeout(function () {
        this.clearNotification();
      }.bind(this), 5000);
    },

    runAction() {
      switch (this.action) {
        case 'close':
          this.clearNotification();
          break;
      }
    },

    clearNotification() {
      if (this.toasts.length == 1) {
        this.show = false;
      }
      this.toasts.shift()
      setTimeout(function () {

      }.bind(this), 1000);
    }
  }
}
</script>

<style scoped>
.toast-container {
  position: fixed;
  z-index: 999;
  top: 5rem;
  right: 0;
  max-width: 90vw;
}

.toast {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  border-radius: 9px;
  animation: fade 300ms ease-in-out;
  cursor: pointer;
}

.toast-content {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

.toast-title {
  color: var(--text-color);
  font-size: 1.6rem;
}

.toast-message {
  color: var(--text-color);
  font-size: 1.2rem;
}

.toast-success {
  background: rgb(215, 255, 237);
}

.toast-warning {
  background: rgb(253, 236, 215);
}

.toast-error {
  background: rgb(255, 222, 219);
}

.toast-delete {
  background: var(--modal-red-bg);
}


@keyframes fade {
  0% {
    opacity: 0;
    translate: 100% 0;
  }

  100% {
    opacity: 1;
    translate: 0 0;
  }
}

@media (prefers-color-scheme: dark) {

  .toast-success {
    background: rgb(86, 188, 73);
  }

  .toast-warning {
    background: rgb(255, 175, 78);
  }

  .toast-error {
    background: rgb(252, 127, 116);
  }
}
</style>