export default {
    userId(state) {
        return state.userId
    },
    userInfo(state) {

        return {
            firstName:state.firstName,
            lastName:state.lastName,
            email:state.email,
            role:state.role,
            id:state.userId,
            companyName:state.companyName
        }
    },
    token(state) {
        return state.token
    },
    allstate(state) {
        return state
    },
    isAuthenticated(state) {
        return !!state.token
    },
    userRole(state) {
        return state.role
    },
    didAutoLogout(state) {
        return state.didAutoLogout
    },
    countdownLogout(state) {
        return state.tokenExpiration
    }
}