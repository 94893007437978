export default {
    // templateColumns: (state) => (id) => {
    //     id=Number(id)
    //     let columns = state.templates.find(thing => thing.id == id).columns
    //     return columns
    //     return state.templates.find(thing => thing.id === id).columns

    // },
    // templateFields: (state) => (id) => {
    //     return state.templates.find(thing => thing.id === id).fields
    // },
    // customSort: (state) => {
    //     return state.customSort
    // },
    showAll: (state)=>{
        return state.uiPreferences
    },
    showDateCard:(state)=>(date)=>{
        let preferences = state.uiPreferencesProjectView
        if(preferences && preferences.projectCard){
            if(preferences.projectCard[date]){
                return preferences.projectCard[date]
            }

        }
        else return false
    },
    showProjectTableView:(state)=>{
        return state.uiPreferencesProjectView.projectTable
    },
    showProjectCardView:(state)=>{
        return state.uiPreferencesProjectView.projectCard
    },
    showDateTable:(state)=>(date)=>{
        let preferences = state.uiPreferencesProjectView
        if(preferences && preferences.projectTable){
            if(state.uiPreferences.projectTable[date]){
                return state.uiPreferences.projectTable[date]
            }

        }
        else return false
    }

}