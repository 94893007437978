<template>
  <div class="header">
    <h1>{{ title }}</h1>
    <button v-if="button && checkRole()" @click="clickButton" class="btn btn--text-btn btn--icon">
      <component :is="button.icon" />
      {{ button.label }}
    </button>
  </div>
</template>
<script>
export default {
  props: ['title', 'button'],
  methods: {
    checkRole() {
      if (this.button.role === 0) {
        return this.$role(this.button.role)
      }
      if (!this.button.role) {
        return true
      } else {
        return this.$role(this.button.role)
      }
      return true
    },
    clickButton() {
      if (this.button.link) {
        this.$router.push(this.button.link);
      }
    },
  },
  computed: {
    icons() {
      return 'pi ' + this.button.icon;
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 2rem; */
}

.btn {
  white-space: nowrap;
}

@media (max-width: 700px) {
  h1 {
    font-size: 2.4rem;
  }
}

@media (max-width: 500px) {
  .btn {
    scale: 0.95;
  }
}

@media (max-width: 380px) {
  .btn {
    scale: 0.85;
  }
}
</style>
